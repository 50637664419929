import "./index.css";
import './i18n';
import App from "./App";
import React from "react";
import ReactDOM from 'react-dom'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
