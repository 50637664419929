import ToDoAnimation from '../../../AnimationViews/ToDoAnimation'
import { StateValue } from '../../../Context';
import { renderToStaticMarkup } from 'react-dom/server';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

type MitekCheckBackExplanationProps = {
  themeColor: string
  isIOSApp: boolean
}

export default function MitekCheckBackExplanation(props: MitekCheckBackExplanationProps) {
  const { t } = useTranslation();
  const state = useContext(StateValue);
  const background = encodeURIComponent(renderToStaticMarkup(<ToDoAnimation color={state.org.theme.themeColor} />));
  return (
    <div style={{ padding: "1em", marginBottom: "5em", width: "100%", height: "100%", background: `url("data:image/svg+xml,${background}")`, backgroundSize: "clamp(20%, 60%, 400px", backgroundPosition: "right bottom", backgroundRepeat: "no-repeat" }}>
      <h2>{t("General_UI.Great")}</h2>
      <h3>{t(`Mitek.Help.Back`)}</h3>
      <h4>{t(`Mitek.Help.Hold_Phone${props.isIOSApp ? "_Manual" : ""}`)}</h4>
      <p>{t(`Mitek.Help.Hold_Phone_Extra${props.isIOSApp ? "_Manual" : ""}`)}</p>
    </div>
  )
}
