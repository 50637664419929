export enum ECriiptoType {
  NONE = 0,
  DKMITID = 10,
  /**
   * @deprecated Is being phased out, should not be implemented. Will be completely phased out by 2023-06-30
   */
  DKNEMID = 11,
  // Sweden
  SEBANKID = 20,
  // Norway
  NOBANKID_OIDC = 30,
  NOVIPPSLOGIN = 31,
  // Finland
  FITUPAS = 40,
  // Belgium
  BEEID = 50,
}

export interface ICriiptoTypeEnum {
  value: ECriiptoType;
  name: string;
}

const item = (value: ECriiptoType, name: string): ICriiptoTypeEnum => ({ value, name });

export const CriiptoTypeEnums = (): ICriiptoTypeEnum[] => {
  let list: ICriiptoTypeEnum[] = [];
  list.push(item(ECriiptoType.DKMITID, ECriiptoType[ECriiptoType.DKMITID]));
  list.push(item(ECriiptoType.SEBANKID, ECriiptoType[ECriiptoType.SEBANKID]));
  list.push(item(ECriiptoType.NOBANKID_OIDC, ECriiptoType[ECriiptoType.NOBANKID_OIDC]));
  list.push(item(ECriiptoType.NOVIPPSLOGIN, ECriiptoType[ECriiptoType.NOVIPPSLOGIN]));
  list.push(item(ECriiptoType.FITUPAS, ECriiptoType[ECriiptoType.FITUPAS]));
  list.push(item(ECriiptoType.BEEID, ECriiptoType[ECriiptoType.BEEID]));

  return list;
}