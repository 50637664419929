import "./MitekCheck.css";
import "./View.css";
import MitekAgentHandoff from "./Components/MitekAgentHandoff";
import MitekAlreadyFinished from "./Components/MitekAlreadyFInish";
import MitekBack from "./Components/MitekBack";
import MitekCheckAgentAssist from "./Components/MitekCheckAgentAssist";
import MitekCheckDesktop from "./Components/MitekCheckDesktop";
import MitekCountryError from "./Components/MitekCountryError";
import MitekDocumentExpired from "./Components/MitekDocumentExpired";
import MitekDocumentSelection from "./Components/MitekDocumentSelection";
import MitekErrors from "./Components/MitekErrors";
import MitekFront from "./Components/MitekFront";
import MitekHeader from "./Components/MitekHeader";
import MitekHelpBack from "./Components/MitekHelpBack";
import MitekHelpFront from "./Components/MitekHelpFront";
import MitekHelpSelfie from "./Components/MitekHelpSelfie";
import MitekLoading from "./Components/MitekLoading";
import MitekLoadingDesktop from "./Components/MitekLoadingDesktop";
import MitekManualCaptureBack from "./Components/MitekManualCaptureBack";
import MitekManualCaptureFront from "./Components/MitekManualCaptureFront";
import MitekSelfie from "./Components/MitekSelfie";
import MitekSelfieManual from "./Components/MitekSelfieManual";
import MitekSubmit from "./Components/MitekSubmit";
import MitekSuccess from "./Components/MitekSuccess";
import { Button, Header, Segment, Icon, Modal, Loader } from "semantic-ui-react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { EDocumentType } from "../../../Enums/EDocumentType";
import { ELogType } from "../../../Enums/ELogType";
import { EMitekCheckStatus } from "../../../Enums/EMitekCheckStatus";
import { EMitekErrorType } from "../../../Enums/EMitekErrorType";
import { MitekCheckAgentHandoff } from "./Components/MitekCheckAgentHandoff";
import { MitekCheckAppendMissingProperties } from "./Components/MitekCheckAppendMissingProperties";
import { MitekRequestBuilder, MitekResponse } from "../../../Types";
import { StateDispatch, StateValue } from "../../Context";
import { autoCaptureHints } from "./Components/MitekCheckHints";
import { deleteCookie } from "../../../Services/ReadWriteCookie";
import { getIsAgentHandoff, postMitekCheck, getAllowedDocuments, supportedDocumentsDto, postUserProgress } from "../../../Services/Backend";
import { isDesktop, isIOS, isIPad13, isTablet } from "react-device-detect";
import { useContext, useEffect, useRef, useState } from "react";
import { useDebounce } from "../../../Hooks/useDebounce";
import { useTranslation } from "react-i18next";
const mitekScienceSDK = require("../../../mitek/mitek-science-sdk");

export type MitekCheckProps = {
  props: any;
  isLandscape?: boolean,
  isPortrait?: boolean
};

type res = {
  validated: boolean,
  failed: boolean
}

export const MitekCheckComponent = (props: MitekCheckProps) => {
  const { t } = useTranslation();

  const dispatch = useContext(StateDispatch);
  const state = useContext(StateValue);

  const [hint, setHint] = useState("");
  const [res, setRes] = useState<res | undefined>(undefined);
  const { isLandscape, isPortrait } = props;
  const [documents, setDocuments] = useState<supportedDocumentsDto[]>([])

  useEffect(() => {
    postUserProgress({ ctxId: state.ctxId, type: ELogType.EnteredMitekComponent }).then(res => {
      sessionStorage.setItem("LAST_LOG_TYPE", res.data.type);
      sessionStorage.setItem("LAST_LOG_DATE", res.data.created);
    });

    return () => {
      mitekScienceSDK.cmd("SDK_STOP");
    };
  }, []);

  useEffect(() => {
    getAllowedDocuments(state.ctxId).then(res => {
      setDocuments(res.data);
    }).catch(_ => {
      console.log("Failed to get allowed documents, using defaults (passport).");
      setDocuments([{ documentType: EDocumentType.Passport, HasAutoCoverage: true, HasAgentCoverage: true }]);
    });
  }, [state.ctxId])

  useEffect(() => {
    // If an agent handoff has been initiated, force current page
    if (state.isAgentHandoff) setCurrentPage(20)
  }, [state.isAgentHandoff])

  const inputFile = useRef<any>(null)
  const [type, setType] = useState<"PASSPORT" | "ID" | "DRIVERSLICENSE">();
  useEffect(() => {
    localStorage.setItem("DOCUMENT_TYPE", type ?? "");
  }, [type])

  const getCurrentPage = () => {
    if (sessionStorage.getItem("MitekCheckFinished") === "true") {
      return 21
    } else {
      return 0
    }
  }

  const [currentPage, setCurrentPage] = useState(getCurrentPage());
  const [loadingCamera, setLoadingCamera] = useState(false);
  const [request, setRequest] = useState<MitekRequestBuilder | any>({ type: "PASSPORT", front: undefined });
  const [response, setResponse] = useState<MitekResponse | undefined>(undefined);
  const [countDown, setCountDown] = useState(0);
  const [wasFileUpload, setWasFileUpload] = useState(false);

  // const isIOSApp = useMemo(() => (isIOS || isIOS13 || isIPad13) && state.targetUrl.includes("concordiumwallet"), [])
  // Disable ios auto-scan when not on staging
  // const isIOSApp = useMemo(() => (!window.location.href.includes("staging") && (isIOS || isIOS13 || isIPad13) && state.targetUrl.includes("concordiumwallet")), [])
  const isIOSApp = false;

  const appendToRequest = (img: any) => {

    if (request.front === undefined) {
      postUserProgress({ ctxId: state.ctxId, type: ELogType.TookFirstPicture }).then(res => {
        sessionStorage.setItem("LAST_LOG_TYPE", res.data.type);
        sessionStorage.setItem("LAST_LOG_DATE", res.data.created);
      });
    } else if (request.back === undefined && type !== "PASSPORT") {
      postUserProgress({ ctxId: state.ctxId, type: ELogType.TookSecondPicture }).then(res => {
        sessionStorage.setItem("LAST_LOG_TYPE", res.data.type);
        sessionStorage.setItem("LAST_LOG_DATE", res.data.created);
      });
    } else {
      postUserProgress({ ctxId: state.ctxId, type: ELogType.TookSelfie }).then(res => {
        sessionStorage.setItem("LAST_LOG_TYPE", res.data.type);
        sessionStorage.setItem("LAST_LOG_DATE", res.data.created);
      });
    }

    switch (type) {
      case "ID":
        if (request.front === undefined) {
          setRequest({ ...request, front: img });
          setCurrentPage(5);
        } else if (request.back === undefined) {
          setRequest({ ...request, back: img });
          setCurrentPage(17);
        } else {
          setRequest({ ...request, selfie: img });
          setCurrentPage(3);
        }
        return;
      case "DRIVERSLICENSE":
        if (request.front === undefined) {
          setRequest({ ...request, front: img });
          setCurrentPage(5);
        } else if (request.back === undefined) {
          setRequest({ ...request, back: img });
          setCurrentPage(17);
        } else {
          setRequest({ ...request, selfie: img });
          setCurrentPage(3);
        }
        return;
      case "PASSPORT":
        if (request.front === undefined) {
          setRequest({ ...request, front: img });
          setCurrentPage(17);
        } else {
          setRequest({ ...request, selfie: img });
          setCurrentPage(3);
        }

        return;
      default:
        break;
    }
  };

  const startManualCapture = (selfie?: boolean) => {
    mitekScienceSDK.cmd("CAPTURE_AND_PROCESS_FRAME", {
      mode: "MANUAL_CAPTURE",
      documentType: selfie ? "SELFIE" : (type === "PASSPORT" ? "PASSPORT" : "DL_FRONT"),
      mitekSDKPath: "../../../mitek/mitekSDK/",
      options: {
        qualityPercent: 80,
      },
    });

    mitekScienceSDK.on("FRAME_PROCESSING_FEEDBACK", function (result: any) {
      setLoadingCamera(false);
      console.log("FRAME_PROCESSING_FEEDBACK", result);
      // setLoadingCamera(false);
      // setHint((autoCaptureHints as any)[result.key]);
    });

    mitekScienceSDK.on("IMAGE_CAPTURED", function (e: any) {
      setLoadingCamera(true);
    });

    mitekScienceSDK.on("FRAME_PROCESSING_STARTED", function (e: any) {
    });

    mitekScienceSDK.on("FRAME_CAPTURE_RESULT", function (result: any) {
      console.log(result);
      // setLoadingCamera(true);
      if (result.response.status !== "failure") {
        appendToRequest(result);
      } else {
        if (result.response.warnings.key === "MITEK_ERROR_GLARE") {
          appendToRequest(result);
        } else {
          setHint((autoCaptureHints as any)[result?.response?.warnings?.key] ?? "Something went wrong, could you try again?");
        }
      }
    });
    mitekScienceSDK.on("SDK_ERROR", function (err: any) {
      setLoadingCamera(false);
      setHint(JSON.stringify(err));
    });

  };

  const startAutoCapture = (selfie?: boolean) => {
    var recentHint = null;

    (document.querySelector('div video[autoplay="true"]') as HTMLVideoElement).play();
    mitekScienceSDK.on("CAMERA_DISPLAY_STARTED", function (result: any) {
      setLoadingCamera(false);
    });

    mitekScienceSDK.on("FRAME_PROCESSING_FEEDBACK", function (result: any) {
      if (result.key !== hint) {
        setHint((autoCaptureHints as any)[result.key]);
      }
    });

    mitekScienceSDK.on("FRAME_PROCESSING_STARTED", function (result: any) {
    });

    mitekScienceSDK.on("FRAME_CAPTURE_RESULT", function (result: any) {
      appendToRequest(result);
    });

    mitekScienceSDK.on("SDK_ERROR", function (err: any) {
      console.error(err);
    });

    mitekScienceSDK.cmd("CAPTURE_AND_PROCESS_FRAME", {
      mode: "AUTO_CAPTURE",
      documentType: selfie ? "SELFIE" : (type === "PASSPORT" ? "PASSPORT" : "DL_FRONT"),
      mitekSDKPath: "../../../mitek/mitekSDK/",
      options: {
        guidePaddingLevel: 1,
        qualityPercent: 90, // value between 50 to 100 recommended
        hintFrequencyMS: 1200, // how long the hint message displays (in MS) / note: 2400 recommended for screen readers
        hintAriaLive: 2, //screen reader setting for hint updates value (0=off / 1=polite / 2=assertive)
        hintMessageSize: 2, // set the size of the hint message (1=small / 2=medium / 3=large)
        faceDetectionLevel: 1, // determines how strict face detection is (1=lenient, 2=moderate, 3=strict)
        disableSmileDetection: false, // disable smile detection
        videoContainerId: "mitekContainer",
        videoRecordingEnabled: false, // (optionally) record the video session / returned as a dataURL
        audioRecordingEnabled: false, // (optionally) also record audio / only applies if videoRecordingEnabled = true)
        videoQuality: 70, // (optionally) value between 30 to 100 recommended (normalized bitrate of video / only applies if videoRecordingEnabled = true)
        videoRecordingMessage: "Recording", // (optionally) override the default "Recording" message / Note: set to "" to disable icon and message
      },
    });
  };

  const [visible, setIsVisible] = useState(false);
  const debouncedIsVisible: boolean = useDebounce<boolean>(visible, 500);
  const openFileBrowser = () => {
    // `current` points to the mounted file input element
    inputFile?.current?.click();
  };

  const handleFileSelect = async (e: any) => {
    setWasFileUpload(true);
    setLoadingCamera(true);
    var files = e.target.files as FileList;
    if (files[0].size > 5242880) {
      setHint(t("Mitek.File_Too_Big"));
      setLoadingCamera(false);
    } else {
      const promise = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
      setLoadingCamera(false);
      appendToRequest({ response: { imageData: promise } });
    }
  }

  useEffect(() => {
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 4000);

  }, [hint])

  useEffect(() => {
    if (countDown !== 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    }
  }, [countDown])

  useEffect(() => {
    mitekScienceSDK.cmd("SDK_STOP");
    setCountDown(0);
    if (currentPage === 1 || currentPage === 2) {
      setLoadingCamera(true);
      setTimeout(startAutoCapture, 1000);
      setCountDown(8);
    }

    if (currentPage === 19) {
      setLoadingCamera(true);
      setTimeout(() => startAutoCapture(true), 1000);
      setCountDown(8);
    }

    if (currentPage === 10 || currentPage === 11 || currentPage === 18) {
      setLoadingCamera(false);
      setCountDown(5);
    }
    setHint("")
  }, [currentPage]);

  const onSuccess = () => {
    dispatch({ type: "setView", data: "form" });
    setTimeout(() => dispatch({ type: "setCurrent", data: state.current + 1 }), 700);
  }

  const [redirectUrl, setRedirectUrl] = useState("");

  const agentCallback = () => {
    if (sessionStorage.getItem("handoff") == null) {
      if (isIOS) {
        getIsAgentHandoff(state.ctxId, isIOS).then(res => window.location.href = res.data.redirectUrl);
      } else {
        // Returns a 302 for android
        const getUrl = async () => {
          const { data } = await getIsAgentHandoff(state.ctxId, true); // Temp faking iOS.
          return data.redirectUrl as string;
        }
        getUrl().then(url => {
          setRedirectUrl(url);
        });
      }
    }
  }

  const handleAgentHandoffCallback = (status: EMitekCheckStatus) => { // Currently not in use, as the 'MitekCheckAgentHandoff' component isn't used
    if (status === EMitekCheckStatus.PROCESSING) {
      setCurrentPage(22); // Ensure the page is still set to #22
    } else if (status === EMitekCheckStatus.COMPLETED) {
      setCurrentPage(13); // Currently directly to completed, since some validation logic is perfomed on the backend before completed can be returned
    } else if (status === EMitekCheckStatus.ERROR) {
      // sessionStorage.setItem("AGENT-ERROR", "true");
      setCurrentPage(23);
    } else {
      // sessionStorage.setItem("AGENT-ERROR", "true");
      setCurrentPage(23);
    }
  }

  const handleBackendCallback = (res: any) => {
    if (res.status === 200) {
      if (res.data.isAgentReviewRequest) {
        setCurrentPage(20);
      } else if (res.data.success === true) {
        setResponse(res.data);
        setCurrentPage(9);
      } else {
        if (res.data.reason === EMitekErrorType.Country) {
          setCurrentPage(16);
        } else if (res.data.reason === EMitekErrorType.Expired) {
          setCurrentPage(15);
        } else if (res.data.reason == EMitekErrorType.MissingData) {
          setResponse(res.data);
          setCurrentPage(9);
        } else if (res.data.reason == EMitekErrorType.AgentAssist) {
          setCurrentPage(20);
        }
        else {
          setResponse(res.data);
          setCurrentPage(8);
        }
      }
    } else {
      setResponse(res.data);
      setCurrentPage(8);
    }
  }

  const postBackend = () => {
    if (type === "PASSPORT") {
      const front = request.front.response.imageData.substring(request.front.response.imageData.indexOf(",") + 1);
      const selfie = request.selfie.response.imageData.substring(request.selfie.response.imageData.indexOf(",") + 1);
      postMitekCheck({ IdDocuments: [front], selfie: selfie, type: "PASSPORT", ctxId: state.ctxId, wasFile: wasFileUpload }).then(res => {
        handleBackendCallback(res);
      }).catch(err => {
        setCurrentPage(8);
      });
    } else if (type === "ID") {
      const front = request.front.response.imageData.substring(request.front.response.imageData.indexOf(",") + 1);
      const back = request.back.response.imageData.substring(request.back.response.imageData.indexOf(",") + 1);
      const selfie = request.selfie.response.imageData.substring(request.selfie.response.imageData.indexOf(",") + 1);
      postMitekCheck({ IdDocuments: [front, back], selfie: selfie, type: "ID", ctxId: state.ctxId, wasFile: wasFileUpload }).then(res => {
        handleBackendCallback(res);
      }).catch(err => {
        setCurrentPage(8);
      });
    } else if (type === "DRIVERSLICENSE") {
      const front = request.front.response.imageData.substring(request.front.response.imageData.indexOf(",") + 1);
      const back = request.back.response.imageData.substring(request.back.response.imageData.indexOf(",") + 1);
      const selfie = request.selfie.response.imageData.substring(request.selfie.response.imageData.indexOf(",") + 1);
      postMitekCheck({ IdDocuments: [front, back], selfie: selfie, type: "DRIVINGLICENSE", ctxId: state.ctxId, wasFile: wasFileUpload }).then(res => {
        handleBackendCallback(res);
      }).catch(err => {
        setCurrentPage(8);
      });
    }
    if (props.props.match.params.handoff !== undefined) {
      localStorage.clear();
      deleteCookie("ctxId");
      deleteCookie("invitationCode");
      deleteCookie("X-Api-Key");
    }
  }

  const renderPage = () => { // TODO volgorde van cases fixen & beschrijvingen toevoegen
    switch (currentPage) {
      // Keuze menu
      case 0:
        return <MitekDocumentSelection setCurrentPage={(page) => setCurrentPage(page)} setType={(type) => setType(type)} documents={documents} />;
      case 1: // Voorkant
        return <MitekFront ctxId={state.ctxId} mitekScienceSDK={mitekScienceSDK} setCurrentPage={(page) => setCurrentPage(page)} debouncedIsVisible={debouncedIsVisible} countDown={countDown} hint={hint} loadingCamera={loadingCamera} state={state} />;
      case 2: // Achterkant
        return <MitekBack mitekScienceSDK={mitekScienceSDK} setCurrentPage={(page) => setCurrentPage(page)} debouncedIsVisible={debouncedIsVisible} countDown={countDown} hint={hint} loadingCamera={loadingCamera} state={state} />
      case 3: // Submit
        return <MitekSubmit postBackend={postBackend} props={props.props} request={request} setCurrentPage={(page) => setCurrentPage(page)} type={type} state={state} />
      case 4: // Hulp voorkant
        return <MitekHelpFront isIOSApp={isIOSApp} setCurrentPage={(page) => setCurrentPage(page)} state={state} />
      case 5: // Hulp achterkant
        return <MitekHelpBack isIOSApp={isIOSApp} setCurrentPage={(page) => setCurrentPage(page)} state={state} />
      case 6:
        return <MitekLoading onSuccess={onSuccess} res={res} state={state} />
      case 7:
        return <MitekLoadingDesktop state={state} />
      case 8:
        return <MitekErrors response={response} />
      case 9: // MISSING_DATA screen
        if (response === undefined) return <Loader active />
        return <MitekCheckAppendMissingProperties
          missingProperties={response.missingProperties} data={{
            documentNumber: response?.extractedData?.documentNumber ?? "",
            givenNames: response?.extractedData?.name?.givenNames ?? "",
            surname: response?.extractedData?.name?.surname ?? "",
            issuingAuthority: response?.extractedData?.dynamicProperties?.issuingAuthority ?? "",
            personalGovID: response?.extractedData?.dynamicProperties?.personalGovID ?? "",
            dateOfIssue: response?.extractedData.dateOfIssue ?? "",
            dateOfExpiry: response?.extractedData.dateOfExpiry ?? "",
          }}
          callback={() => setCurrentPage(13)}
        />
      case 13: // Success screen
        return <MitekSuccess onSuccess={onSuccess} state={state} props={props.props} />
      case 10: // Manual Capture voorkant
        return <MitekManualCaptureFront handleFileSelect={(e) => handleFileSelect(e)} hint={hint} inputFile={inputFile} loadingCamera={loadingCamera} openFileBrowser={openFileBrowser} startManualCapture={startManualCapture} />
      case 11: // Manual capture achterkant
        return <MitekManualCaptureBack handleFileSelect={(e) => handleFileSelect(e)} hint={hint} inputFile={inputFile} loadingCamera={loadingCamera} openFileBrowser={openFileBrowser} startManualCapture={startManualCapture} />
      case 12: // Not in use
        return <MitekCheckAgentAssist callback={() => setCurrentPage(13)} />
      // case 13: // Not in use
      // 	return <MitekCheckAgentAssistEnd />
      case 15: // Document has expired
        return <MitekDocumentExpired />
      case 16: // Country which isn't allowed
        return <MitekCountryError state={state} />
      case 17: // Hulp selfie
        return <MitekHelpSelfie isIOSApp={isIOSApp} setCurrentPage={setCurrentPage} state={state} />
      case 18: // Selfie manual
        return <MitekSelfieManual hint={hint} loadingCamera={loadingCamera} setCurrentPage={(page) => setCurrentPage(page)} startManualCapture={(e) => startManualCapture(true)} />
      case 19: // Selfie automatic
        return <MitekSelfie mitekScienceSDK={mitekScienceSDK} countDown={countDown} hint={hint} loadingCamera={loadingCamera} setCurrentPage={(page) => setCurrentPage(page)} state={state} />
      case 20: // Agent handoff with redirect to cc wallet
        return <MitekAgentHandoff redirectUrl={redirectUrl} agentCallback={agentCallback} state={state} />
      case 21: // Already finished
        return <MitekAlreadyFinished state={state} />
      case 22: // Agent handoff without redirect to CC wallet // NOT IN USE
        return (
          <>
            <MitekCheckAgentHandoff ctxId={state.ctxId} callback={(status) => { handleAgentHandoffCallback(status) }} />
          </>
        );
      case 23: // Agent handoff failed // NOT IN USE
        return (
          <>
            <MitekHeader />
            <Segment className="mitek" basic style={{ height: "90%", width: "100%", margin: 0 }}>
              <h3>{t("Mitek.Error.Something_Went_Wrong_Checking_Documents")}
              </h3>
              <br />
              <h4>
                {t("Mitek.Error.Please_Close_The_App")}
              </h4>
            </Segment>
            <Segment className="mitek mt-0" style={{ height: "10%", margin: 0, width: "100vw", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              <Button loading color="green" floated="right" disabled>
                {t("General_UI.Next")}
              </Button>
            </Segment>
          </>
        )
    }
  };

  return (isDesktop && !isIPad13) ? (
    <MitekCheckDesktop res={res} ctxId={state.ctxId} lang={props.props.match.params.lang} logo={state.org.theme.logo} />
  ) : (
    <Segment.Group className="containersegment" horizontal={false}>
      <Modal basic open={((!isTablet || !isIPad13) && !isPortrait)} size='small'>
        <Header icon>
          <Icon name='redo' flipped="horizontally" />
          {
            (isTablet || isIPad13) ? t("Mitek.Tablet_Warning") : t("Mitek.Orientation_Warning")
          }

        </Header>
      </Modal>

      <Segment.Group className="mitek" style={{ height: "100%", width: "100%", margin: 0 }} horizontal={false}>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={`${currentPage}-trans`}
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            classNames="fade"
          >
            <div className="transition-container" style={{ height: "100%", flexDirection: "column" }}>
              {renderPage()}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </Segment.Group>
    </Segment.Group>
  );
};
