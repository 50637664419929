import { RootStore } from "./RootStore";
import { makeAutoObservable } from 'mobx'

interface IModal {
  open: boolean,
  body: null,
  size: "small" | "mini" | "tiny" | "large" | "fullscreen" | undefined,
  onClose?: () => void
}

interface OpenModalParameters {
  content: any;
  size?: "small" | "mini" | "tiny" | "large" | "fullscreen",
  onCloseModal?: () => void,
}

export default class ModalStore {
  rootStore: RootStore;
  modal: IModal;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.modal = {
      open: false,
      body: null,
      size: 'mini'
    }
    makeAutoObservable(this);
  }

  openModal = ({ content, size = "large", onCloseModal = this.closeModal }: OpenModalParameters) => {
    this.modal.open = true;
    this.modal.body = content;
    this.modal.size = size;
    this.modal.onClose = onCloseModal
  }

  closeModal = () => {
    this.modal.open = false;
    this.modal.body = null;
  }
}