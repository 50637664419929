import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { CancelButton } from "../../../Common/Button/CancelButton";
import { CheckBox } from "../../../Common/form/CheckBox";
import { DatePicker } from "../../../Common/form/DatePicker";
import { DocumentEnums, IDocumentEnum } from "../../../../Enums/EDocumentType";
import { DropDown } from "../../../Common/form/DropDown";
import { Field, Formik } from "formik";
import { IAutoCoveredPagesEnum, PagesEnums } from "../../../../Enums/EAutoCoveredPages";
import { IMitekSupportedDocument, MitekSupportedDocumentFormValues } from "../../../../models/admin/IMitekSupportedDocument";
import { MitekDocumentFormValidation } from "./MitekDocumentForm.Validation";
import { RootStoreContext } from "../../../../stores/RootStore";
import { Spinner } from "../../../Common/spinner/Spinner";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
const { getData } = require('country-list');

interface IProps {
  id?: string;
}

export const MitekDocumentForm: React.FC<IProps> = observer(({ id }) => {
  const { t } = useTranslation();
  const rootStore = useContext(RootStoreContext);
  const { getMitekSupportedDocument, loadingDocuments, submitting, addMitekSupportedDocument, editMitekSupportedDocument } = rootStore.adminStore;
  const [formValues, setFormValues] = useState(new MitekSupportedDocumentFormValues());
  const [documentOptions, setDocumentOptions] = useState<IDocumentEnum[]>([]);
  const [pagesOptions, setPagesOptions] = useState<IAutoCoveredPagesEnum[]>([]);

  const countryData = useMemo(() => getData(), []);
  const countryOptions = useMemo(() => countryData.sort((country: { code: any }) => country.code).map(
    (country: { code: any; name: any; }) => ({
      key: country.code,
      value: country.code,
      text: country.name,
      flag: country.code.toLowerCase()
    })
  ), [countryData]);

  useEffect(() => {
    const fetchDocument = async (id: string) => {
      const document: IMitekSupportedDocument | undefined = await getMitekSupportedDocument(id);
      setFormValues(new MitekSupportedDocumentFormValues(document));
    }
    if (id) {
      fetchDocument(id);
    }
    setDocumentOptions(DocumentEnums());
    setPagesOptions(PagesEnums());
  }, [])

  const handleSubmit = async (values: MitekSupportedDocumentFormValues) => {
    if (id) {
      await editMitekSupportedDocument(id, values);
    } else {
      await addMitekSupportedDocument(values)
    }
  }

  if (loadingDocuments) return <Spinner />

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={8}>
          <Formik
            enableReinitialize
            initialValues={formValues}
            onSubmit={handleSubmit}
            validationSchema={MitekDocumentFormValidation}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Segment padded className="bg-lightest-grey">
                  <Field
                    component={DropDown}
                    required={!id}
                    name="countryAlpha2"
                    label={t("Mitek.Document.Country")}
                    value={formik.values.countryAlpha2}
                    disabled={id !== undefined}
                    placeholder={t("Enum.Make_A_Choice")}
                    options={countryOptions}
                  />
                  <Field
                    component={DropDown}
                    required={!id}
                    name="documentType"
                    label={t("Mitek.Document.Type")}
                    value={formik.values.documentType}
                    disabled={id !== undefined}
                    placeholder={t("Enum.Make_A_Choice")}
                    options={documentOptions.map((document) => {
                      return {
                        key: document.value,
                        value: document.value,
                        text: t(`Enum.DocumentType.${document.name}`)
                      };
                    })}
                  />

                  <Field
                    component={DatePicker}
                    disabled={id !== undefined} /* TODO */
                    type={"date"}
                    name="year"
                    label={t("Mitek.Document.Year")}
                    tooltip={{ content: t('Mitek.Document.Year_Tooltip') }}
                  />

                  <Field
                    component={DropDown}
                    required={!id}
                    name="autoCoveredPages"
                    label={t("Mitek.Document.AutoCoveredPages")}
                    value={formik.values.autoCoveredPages}
                    placeholder={t("Enum.Make_A_Choice")}
                    options={pagesOptions.map((page) => {
                      return {
                        key: page.value,
                        value: page.value,
                        text: t(`Enum.AutoCoveredPage.${page.name}`)
                      };
                    })}
                  />
                  <Field
                    component={CheckBox}
                    id="hasAutoCoverage"
                    name="hasAutoCoverage"
                    label={t("Mitek.Document.HasAutoCoverage")}
                    type="checkbox"
                    checked={formik.values.hasAutoCoverage}
                  />
                  <Field
                    component={CheckBox}
                    id="hasAgentCoverage"
                    name="hasAgentCoverage"
                    label={t("Mitek.Document.HasAgentCoverage")}
                    type="checkbox"
                    checked={formik.values.hasAgentCoverage}
                  />
                </Segment>
                <Button disabled={submitting} floated='right' loading={submitting} primary type="submit">
                  {t("Button.Save")}
                </Button>
                <CancelButton floated="left" />
              </Form>
            )}
          </Formik>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
})