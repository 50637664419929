import { AnalysisBreakdown } from "../../../Components/secure/statistics/AnalysisBreakdown"
import { Divider, Header } from "semantic-ui-react"

export const Statistics = () => {
  return (
    <>
      <Header as="h1">Statistics</Header>
      <Divider />
      <AnalysisBreakdown />
    </>
  )
}