import { ConcordiumManagerForm } from "../../../../../Components/secure/concordiumManager/ConcordiumManagerForm";
import { Divider, Header } from "semantic-ui-react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface RouteParams {
  id: string;
}

export const EditConcordiumManager: React.FC<RouteComponentProps<RouteParams>> = () => {
  const { id } = useParams<RouteParams>();
  const { t } = useTranslation();

  return (
    <>
      <Header as="h1">{t("Concordium_Manager.Edit")}</Header>
      <Divider />
      <ConcordiumManagerForm id={id} />
    </>
  )
}