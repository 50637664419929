import { useTranslation } from "react-i18next";
import { Icon, Step } from "semantic-ui-react";
import { SidebarProps } from "../../../Types";

export const SidebarCriipto = (props: SidebarProps) => {
  const { t } = useTranslation();

  const clientClass = props.clientClass ? `-${props.clientClass}` : "";

  return (
    <Step
      className={`${props.current > props.order ? `sidebar-container-completed${clientClass}` : `sidebar-container${clientClass}`}`}
      completed={props.current > props.order}
      active={props.current === props.order}
    >
      {!props.mediumScreen && <Icon name="user" className={`${props.current > props.order ? "sidebar-icon-completed" : "sidebar-idon"}`} />}
      <Step.Content className="sidebar-header-cont">
        <Step.Title className="sidebar-header">{t("Criipto.Identity_Check")}</Step.Title>
        <Step.Description className="sidebar-subheader">{t("Criipto.Identity_Check_Sub")}</Step.Description>
      </Step.Content>
    </Step>
  )
}