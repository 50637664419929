import { Button, Dropdown, Form, FormFieldProps, Header, Icon, Label, Modal } from 'semantic-ui-react';
import { FieldProps, getIn } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps extends FieldProps<string, HTMLInputElement>, FormFieldProps { }

export const SelectDropdown = (props: IProps) => {
  const {
    disabled,
    field,
    form: { touched, errors, values, setFieldValue },
    label,
    options,
    placeholder = '',
    readonly,
    required,
    type,
    search,
    multiple,
    clearable,
    inline = false
  } = props;

  const { t } = useTranslation();
  const { value }: { value: string } = field
  const [open, setOpen] = useState(false);
  const handleChange = (e: any, { value }: { value: string }) => {
    setFieldValue(field.name, value)
  }

  return (
    <Form.Field required={required}>
      <label>
        {props.label}
        {props.tooltip && (<>
          <Modal
            basic
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size='small'
            trigger={<Button style={{ border: "none", boxShadow: "none", paddingRight: 0, paddingBottom: "0.25em" }} compact size="small" basic type="button" icon={<Icon style={{ marginBottom: "1em" }} name="info circle" fitted />} />}
          >
            <Header icon>
              <Icon name='info circle' />
              {t("General_UI.Information")}
            </Header>
            <Modal.Content>
              <p>
                {props.tooltip?.content}
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button color='green' inverted onClick={() => setOpen(false)}>
                {t("General_UI.Close")}
              </Button>
            </Modal.Actions>
          </Modal>
        </>)}
      </label>
      <Form.Field
        control={Dropdown}
        name={field.name}
        value={value}
        onChange={handleChange}
        fluid
        required={required}
        selection
        options={options}
        multiple={multiple}
        search={search}
        clearable={clearable}
        placeholder={placeholder}
        disabled={disabled}
        error={inline && (!!(getIn(touched, field.name) && getIn(errors, field.name)))}
      />

      {!inline && getIn(touched, field.name) && getIn(errors, field.name) && (
        <Label pointing color="red">
          {getIn(errors, field.name)}
        </Label>
      )}
    </Form.Field>
  )
}

