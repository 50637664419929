import { Segment } from 'semantic-ui-react';
import { deleteCookie } from '../../../../Services/ReadWriteCookie';
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';

type MitekHandoffProps = {
  match: any
}

function MitekHandoff(props: MitekHandoffProps) {
  const [key, setKey] = useState<any>("");
  const history = useHistory();

  useEffect(() => {
    localStorage.clear();
    deleteCookie("ctxId");
    deleteCookie("invitationCode");
    deleteCookie("X-Api-Key");

    const url = new URLSearchParams(window.location.search);
    const lang = url.get("lang");
    const ctxId = url.get("ctxId")

    document.cookie = `ctxId=${ctxId};path=/;SameSite=Lax;`
    sessionStorage.setItem("handoff", "true")

    history.push(`/${ctxId}/${lang}/true`);
  }, [])

  return (
    <Segment.Group className="containersegment" style={{ height: "100%", display: "flex" }}>
      <Segment loading style={{ flex: 1 }} />
    </Segment.Group>
  )
}

export default MitekHandoff

