export const CriiptoMitIDSvg = (props: any) => (
  // Default color = #0060e6
  // Alternative color = #ffffff
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 61 16",
    }}
    viewBox="0 0 61 16"
    {...props}
  >
    <defs>
      <path
        id="a"
        d="M19.2 0c1 0 1.8.8 1.8 1.8s-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8.8-1.8 1.8-1.8zm21.2 0c2.1 0 3.8 1.7 3.8 3.8 0 2.1-1.7 3.8-3.8 3.8-2.1 0-3.8-1.7-3.8-3.8 0-2.1 1.7-3.8 3.8-3.8zM20.7 4.9v10.9h-3V4.9h3zM2.9.8l4.6 7.1L12 .8h2.9v15h-3.1V6.1l-4.3 6.4h-.1L3.1 6.1v9.7H0V.8h2.9zM40.4 9c3.6 0 6.5 2 6.9 6.8H33.6C34 11 36.9 9 40.4 9zM51.9.2C58.3.2 61 3.7 61 8s-2.7 7.8-9.1 7.8h-2.6V.2h2.6zM27.1 1.9v3h2.4v2.4h-2.4v4.8c0 .9.5 1.2 1.3 1.2.5 0 1-.1 1.3-.4v2.7c-.4.2-1.2.3-2 .3-2.2 0-3.6-1.1-3.6-3.5V7.3h-1.7V4.9h1.7v-3h3z"
      />
    </defs>
    <clipPath id="b">
      <use
        xlinkHref="#a"
        style={{
          overflow: "visible",
        }}
      />
    </clipPath>
    <path
      d="M0-.6h62v18H0z"
      style={{
        clipPath: "url(#b)",
        fill: props.fill,
      }}
    />
  </svg>
)