import { SidebarProps } from '../../../Types'
import { Step, Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';

const SidebarCountryEmail = (props: SidebarProps) => {
  const { t } = useTranslation();
  return (
    <Step className={`${props.current > props.order ? `sidebar-container-completed${props.clientClass ? "-" + props.clientClass : ""}` : `sidebar-container${props.clientClass ? "-" + props.clientClass : ""}`}`} completed={props.current > props.order} active={props.current === props.order}>
      {!props.mediumScreen && <Icon name="mail outline" className={`${props.current > props.order ? "sidebar-icon-completed" : "sidebar-icon"}`} />}
      <Step.Content className="sidebar-header-cont">
        <Step.Title className="sidebar-header">{t("CountryEmail.Title")}</Step.Title>
        <Step.Description className="sidebar-subheader">{t("CountryEmail.Subtitle")}</Step.Description>
      </Step.Content>
    </Step>
  )
}

export default SidebarCountryEmail
