import Roles from "../../Enums/Roles";
import { ErrorStatisticsPage } from "../../pages/secure/errorStatistics/ErrorStatisticsPage";
import { IRoute } from "../../models/routes/IRoute";
import { Statistics } from "../../pages/secure/statistics/Statistics";

export const StatisticsRoutes: IRoute[] = [
  {
    component: Statistics,
    path: "/stats",
    title: "Navbar.Statistics",
    exact: true,
    showInMenu: true,
    permission: [Roles.ADMIN, Roles.DTSMANAGER, Roles.CONCORDIUMMANAGER],
    dropdown: false,
    childs: [],
  },
  {
    component: ErrorStatisticsPage,
    path: "/error-stats",
    title: "Navbar.Error_Statistics",
    exact: true,
    showInMenu: true,
    permission: [Roles.ADMIN, Roles.DTSMANAGER],
    dropdown: false,
    childs: [],
  },
]