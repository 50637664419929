import { frontendReferrer } from "../Services/BaseApiService";
import { isLocalhost } from "./General";

export const baseSubmit = async (type: string, url: string, token: string, data: any, jsonBody: boolean) => {
  const body = jsonBody ? JSON.stringify(data) : data;
  url = `${process.env.REACT_APP_API_URL}/${url}`;

  return await fetch(url, {
    method: type,
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Accept": "application/json",
      ...(jsonBody && { "Content-Type": "application/json" }),
      "Authorization": `Bearer ${token}`
    },
    redirect: "follow",
    referrer: frontendReferrer,
    body: body, // body data type must match "Content-Type" header
  }).catch((error) => {
    if (isLocalhost()) console.log(`ERROR: ${type} ${url}`, error);
  });
};

export const baseFetch = async (type: string, url: string, token: string) => {
  url = `${process.env.REACT_APP_API_URL}/${url}`;

  return await fetch(url, {
    method: type,
    headers: {
      "Accept": "application/json",
      "Authorization": `Bearer ${token}`
    },
    redirect: "follow",
    referrer: frontendReferrer
  }).catch((error) => {
    if (isLocalhost()) console.log(`ERROR: ${type} ${url}`, error);
  });
}