import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Divider, Grid, Header, Icon } from "semantic-ui-react";
import { ICriiptoAcr } from "../../../models/admin/ICriiptoAcr";
import { RootStoreContext } from "../../../stores/RootStore";
import { DataView, DataViewSearch, IDataViewColumn } from "../../Common/dataview/Dataview";
import { Spinner } from "../../Common/spinner/Spinner";

export const CriiptoAcrList = observer(() => {
  const { t } = useTranslation();
  const rootStore = useContext(RootStoreContext);
  const { openConfirmModal } = rootStore.confirmModalStore;
  const { loading, submitting, getCriiptoAcrValues, criiptoAcrValues, deleteCriiptoAcrValue } = rootStore.criiptoStore;
  const [searchInput, setSearchInput] = useState("");

  const loadData = async () => {
    await getCriiptoAcrValues();
  }

  useEffect(() => {
    loadData();
  }, [])

  const getColumns = (): IDataViewColumn[] => {
    return [
      { headerName: t("Criipto.Acr.Country_Alpha_2"), field: "countryAlpha2", sortable: true, flex: 1, filter: false },
      { headerName: t("Criipto.Acr.Name"), field: "eidName", tooltipField: "eidName", sortable: false, flex: 1, filter: false },
      { headerName: t("Criipto.Acr.Acr_Value"), field: "acrValue", tooltipField: "acrValue", sortable: false, flex: 1 },
      { headerName: t("Criipto.Acr.Comment"), field: "comment", tooltipField: "comment", sortable: false, flex: 1 },
      { headerName: t("Criipto.Acr.Enabled"), field: "enabled", sortable: false, flex: 1, cellRenderer: "checkmark" },
      { headerName: t("Dataview.Actions"), cellRenderer: "actions", sortable: false, flex: 1 },
    ]
  }

  const renderCellAction = (tableData: any) => {
    const criiptoAcr: ICriiptoAcr = toJS(tableData.data);
    return (
      <div>
        <Button as={Link} className="icon" compact primary to={`criipto-acr/edit/${criiptoAcr.id}`}>
          <Icon name="edit outline" />
        </Button>
        <Button className="icon" color="red" compact
          onClick={() => openConfirmModal(t("Button.Cancel"), t("Button.Delete"), t("Criipto.Acr.Delete"), () => deleteCriiptoAcrValue(criiptoAcr.id))}>
          <Icon name="trash alternate outline" />
        </Button>
      </div>
    )
  }

  return (
    <>
      <Header as="h1">{"Criipto ACR"}</Header>
      <Divider />
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <DataViewSearch onFilterChange={(input: any) => setSearchInput(input.target.value)} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Button as={Link} floated='right' color="green" to={`criipto-acr/new`}>
              {"New criipto acr entry"}
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {loading ? <Spinner /> :
              <DataView
                actionsRenderer={(data: any) => renderCellAction(data)}
                columns={getColumns()}
                data={criiptoAcrValues}
                quickFilter={searchInput}
              />
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
})