import ToDoAnimation from '../../../AnimationViews/ToDoAnimation'
import { StateValue } from '../../../Context';
import { renderToStaticMarkup } from 'react-dom/server';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

type MitekCheckSelfieExplanationProps = {
  themeColor: string
  isIOSApp: boolean
}

export default function MitekCheckSelfieExplanation(props: MitekCheckSelfieExplanationProps) {
  const { t } = useTranslation();
  const state = useContext(StateValue);
  const background = encodeURIComponent(renderToStaticMarkup(<ToDoAnimation color={state.org.theme.themeColor} />));
  return (
    <div style={{ padding: "1em", marginBottom: "5em", width: "100%", height: "100%", background: `url("data:image/svg+xml,${background}")`, backgroundSize: "clamp(20%, 60%, 400px", backgroundPosition: "right bottom", backgroundRepeat: "no-repeat" }}>
      <h2>{t("Mitek.Help.Almost_Done")}</h2>
      <h3>{t("Mitek.Help.Selfie")}</h3>
      <h4>{t(`Mitek.Help.Hold_Phone_Selfie${props.isIOSApp ? "_Manual" : ""}`)}</h4>
      <p>{t(`Mitek.Help.Hold_Phone_Extra_Selfie${props.isIOSApp ? "_Manual" : ""}`)}</p>
    </div>
  )
}
