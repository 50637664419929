import MitekHeader from './MitekHeader';
import { AnimationCheck } from '../../../AnimationViews/AnimationCheck';
import { ReactComponent as CheckSVG } from "../../../../Assets/SVG/Check.svg";
import { Segment, Button } from 'semantic-ui-react';
import { state } from '../../../Context';
import { useTranslation } from 'react-i18next';

type IProps = {
  state: state | undefined;
}

const MitekLoadingDesktop = (props: IProps) => {

  const { t } = useTranslation();

  return (
    <>
      <MitekHeader />
      <Segment className="mitek" basic style={{ height: "calc(100% - 5em - 4em)", width: "100%", margin: 0, paddingBottom: 0 }}>
        <AnimationCheck
          header={t("Mitek.Loading.Automatic_Refresh_Header_Desktop")}
          text={t("Mitek.Loading.Automatic_Refresh_Text")}
          svgComponent={<CheckSVG fill={props.state?.org.theme.themeColor} />}
        />
      </Segment>
      <Segment className="service-item-button-container mitek mt-0" style={{ height: "10%", margin: 0, width: "100vw", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
        <Button loading color="green" floated="right" disabled>
          {t("General_UI.Next")}
        </Button>
      </Segment>
    </>
  )
};

export default MitekLoadingDesktop;
