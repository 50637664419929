import styled from "styled-components";
import { Menu } from "semantic-ui-react";

export const StyledDashboardNavbar = styled(Menu)`
  &&&& {
    border-bottom: none;
    border-radius: 0;
    background-color: #2e3094;
    height: 60px;
    margin-bottom: 0;
    margin-top: 0;

    .navbar-title {
      color: #ffffff;
      font-size: 28px;
    }

    .ui.container {
      margin: auto;

      .item {
        color: #ffffff;
        padding: 0 0.6rem;
      }
    }
  }
`;

export const StyledDashboardNavbarSecondary = styled(Menu)`
  &&&& {
    border-bottom: none;
    border-radius: 0;
    background-color: #333333;
    height: 60px;
    margin-top: 0;
    margin-bottom: 30px;

    .sec-navbar {
      .nav-dashboard {
        img {
          filter: invert(0%) sepia(0%) saturate(7489%) hue-rotate(297deg) brightness(96%) contrast(104%) !important;
        }

        &.active, &:hover, &:focus {
          img {
            filter: invert(100%) sepia(0%) saturate(7483%) hue-rotate(193deg) brightness(111%) contrast(103%) !important;
          }
        }
      }

      .nav-desktop {
        display: flex;
      }

      .border:not(:last-child) {
        border-right: 0.5px solid #555;
      }
      
      .nav-dropdown {
        &.active, &:hover {
          background-color: #FFFFFF;
          
          .item span {
            color: #333333 !important;
            text-decoration: none;
          }
        }
      }

      .item {
        color: #FFFFFF;
        padding: 0 0.6rem;

        img {
          filter: invert(100%) sepia(0%) saturate(7483%) hue-rotate(193deg) brightness(111%) contrast(103%);
        }

        i.dropdown.icon {
          margin: 0;
        }

        &.active, &:hover, &:focus {
          background-color: #FFFFFF;
          color: #333333;
          text-decoration: none;

          img {
            filter: invert(0%) sepia(0%) saturate(7489%) hue-rotate(297deg) brightness(96%) contrast(104%)
          }
        }
      }
    }
  }
`;