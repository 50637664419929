import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { ChangePasswordProfileForm } from "./ChangePasswordProfileForm";
import { Field, Formik } from "formik";
import { IProfile, ProfileFormValues } from "../../models/user/IProfile";
import { RootStoreContext } from "../../stores/RootStore";
import { TextInput } from "../Common/TextInput";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const ProfileForm = observer(() => {
  const { t } = useTranslation();
  const rootStore = useContext(RootStoreContext);
  const { editProfile, getProfile, submitting } = rootStore.profileStore;
  const [formValues, setFormValues] = useState(new ProfileFormValues());
  const [lastActivity, setLastActivity] = useState<Date>();

  useEffect(() => {
    const fetchProfile = async () => {
      const profile: IProfile | undefined = await getProfile();
      setFormValues(new ProfileFormValues(profile));
      setLastActivity(profile?.lastActivity);
    }
    fetchProfile();
  }, [])

  const handleSubmit = async (values: ProfileFormValues) => {
    await editProfile(values);
  }

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={3}>
          <span><strong>Last logged in</strong></span>
        </Grid.Column>
        <Grid.Column width="5">
          <span>{lastActivity !== undefined ? t("I18n.Formatted.DateTime", { date: new Date(lastActivity) }) : "-"}</span>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Formik
            enableReinitialize
            initialValues={formValues}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Segment padded className="bg-lightest-grey">
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Field
                          component={TextInput}
                          disabled
                          label="Email"
                          name="email"
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Field
                          component={TextInput}
                          label={t("Label.Name.First")}
                          name="firstName"
                          required
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Field
                          component={TextInput}
                          label={t("Label.Name.Last_Prefix")}
                          name="lastNamePrefix"
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Field
                          component={TextInput}
                          label={t("Label.Name.Last")}
                          name="lastName"
                          required
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Button floated="right" disabled={submitting} loading={submitting} primary type="submit">
                          {t("Button.Save")}
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Form>
            )}
          </Formik>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <ChangePasswordProfileForm />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
})