import Roles from "../../Enums/Roles";
import { AdminList } from "../../Components/secure/admin/AdminList";
import { AnonimityRevocation } from "../../pages/secure/admin/AnonimityRevocation";
import { CreateAdmin } from "../../pages/secure/admin/users/Admin/CreateAdmin";
import { CreateMitekDocument } from "../../pages/secure/admin/MitekDocuments/CreateMitekDocument";
import { EditAdmin } from "../../pages/secure/admin/users/Admin/EditAdmin";
import { EditMitekDocument } from "../../pages/secure/admin/MitekDocuments/EditMitekDocument";
import { IRoute } from "../../models/routes/IRoute";
import { IssuanceReference } from "../../pages/secure/dtsManager/IssuanceReferences";
import { MitekDocuments } from "../../pages/secure/admin/MitekDocuments/MitekDocuments";
import { CriiptoAcr } from "../../pages/secure/admin/CriiptoAcr/CriiptoAcr";
import { CreateCriiptoAcr } from "../../pages/secure/admin/CriiptoAcr/CreateCriiptoAcr";
import { EditCriiptoAcr } from "../../pages/secure/admin/CriiptoAcr/EditCriiptoAcr";

export const AdminRoutes: IRoute[] = [
  {
    component: AnonimityRevocation,
    path: "/anonimity-revocation",
    title: "Navbar.Anonimity_Revocation",
    exact: true,
    showInMenu: true,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: [],
  },
  {
    component: IssuanceReference,
    path: "/issuance-references",
    title: "Navbar.Issuance_Reference",
    exact: true,
    showInMenu: true,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: [],
  },
  {
    component: CriiptoAcr,
    path: "/criipto-acr",
    title: "Navbar.Criipto_Acr",
    exact: true,
    showInMenu: true,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: [],
  },
  {
    component: CreateCriiptoAcr,
    path: "/criipto-acr/new",
    title: "Navbar.Criipto_Acr",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: [],
  },
  {
    component: EditCriiptoAcr,
    path: "/criipto-acr/edit/:id",
    title: "Navbar.Criipto_Acr",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: [],
  },
  {
    component: MitekDocuments,
    path: "/mitek-supported-documents",
    title: "Navbar.Mitek_Supported_Documents",
    exact: true,
    showInMenu: true,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: [],
  },
  {
    component: CreateMitekDocument,
    path: "/mitek-supported-document/new",
    title: "Navbar.Mitek_Supported_Documents",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: [],
  },
  {
    component: EditMitekDocument,
    path: "/mitek-supported-document/edit/:id",
    title: "Navbar.Mitek_Supported_Documents",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: [],
  },
  {
    component: AdminList,
    path: "/admins",
    title: "Navbar.Admins",
    exact: true,
    showInMenu: true,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: [],
  },
  {
    component: EditAdmin,
    path: "/admin/edit/:id",
    title: "Navbar.Admins",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: [],
  },
  {
    component: CreateAdmin,
    path: "/admin/new",
    title: "Navbar.Admins",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: [],
  },
]