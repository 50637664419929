import MitekHeader from './MitekHeader';
import { Segment, Button } from 'semantic-ui-react';
import { TimerButton } from '../../../Common/Button/TimerButton';
import { isIOS } from 'react-device-detect';
import { state } from '../../../Context';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type IProps = {
  state: state | undefined;
  agentCallback: () => void;
  redirectUrl: string;
}

const MitekAgentHandoff = (props: IProps) => {

  const { t } = useTranslation();

  useEffect(() => {
    if (!isIOS && sessionStorage.getItem("handoff") === null) {
      props.agentCallback();
    }
  }, []);


  return (
    <>
      <MitekHeader />
      <Segment className="mitek" basic style={{ height: "calc(100% - 5em - 4em)", width: "100%", margin: 0 }}>
        <h1>{t(`Mitek.Agent_Assist.Header${!isIOS ? "_Android" : ""}${sessionStorage.getItem("handoff") !== null ? "_Handoff" : ""}`)}</h1>
        <h2>{t("Mitek.Agent_Assist.SubHeader")}</h2>
      </Segment>
      <Segment basic className={`service-item-button-container${props.state?.org.theme.clientClass ? "-" + props.state?.org.theme.clientClass : ""} mt-0`}>
        {!isIOS && (sessionStorage.getItem("handoff") === null ? <a className='ui green right floated button' href={props.redirectUrl} > Open Wallet </a> : <Button color="green" floated="right" disabled>{t("Next")}</Button>)}
        {isIOS && (sessionStorage.getItem("handoff") === null ? <TimerButton callback={props.agentCallback} timeOut={10} /> : <Button color="green" floated="right" disabled>{t("Next")}</Button>)}
      </Segment>
    </>
  )
};

export default MitekAgentHandoff;
