import { DtsmanagerFormValues, IDtsManager } from "../models/user/IDtsManager";
import { EToastType } from "../Enums/EToastType";
import { IGetIssuanceReference, IIsuanceReference } from "../models/issuance/IIssuanceReference";
import { RootStore } from "./RootStore";
import { history } from "../ApplicationRouter";
import { makeAutoObservable, runInAction } from "mobx";

export default class DtsManagerStore {
  rootStore: RootStore;
  dtsManagers: IDtsManager[] = [];
  loadingDtsManagers: boolean = false;
  loadingIssuance: boolean = false;
  submitting: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getErrorForIssuance = async (issuanceReference: IGetIssuanceReference) => {
    runInAction(() => {
      this.loadingIssuance = true;
    });

    const { success, data }: { success: boolean, data: IIsuanceReference } = await this.rootStore.baseApiService.get(`dts-manager/issuance?issuanceReference=${issuanceReference.issuanceReference}`);
    if (success) {
      runInAction(() => {
        this.loadingIssuance = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loadingIssuance = false;
      });
    }
  }

  getDtsManagers = async () => {
    runInAction(() => {
      this.loadingDtsManagers = true;
    });

    const { success, data }: { success: boolean, data: IDtsManager[] } = await this.rootStore.baseApiService.get('dts-manager');
    if (success) {
      runInAction(() => {
        this.dtsManagers = data;
        this.loadingDtsManagers = false;
      });
    } else {
      runInAction(() => {
        this.loadingDtsManagers = false;
      });
    }
  }

  getDtsManager = async (id: string) => {
    runInAction(() => {
      this.loadingDtsManagers = true;
    });
    const { success, data }: { success: boolean, data: IDtsManager } = await this.rootStore.baseApiService.get(`dts-manager/${id}`);
    if (success) {
      runInAction(() => {
        this.loadingDtsManagers = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loadingDtsManagers = false;
      });
    }
  }

  addDtsManager = async (values: DtsmanagerFormValues) => {
    this.submitting = true;

    const { success } = await this.rootStore.baseApiService.post('dts-manager', values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        history.push('/dashboard/dts-managers');
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
        this.getDtsManagers();
      })
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      })
    }
  }

  editDtsManager = async (values: DtsmanagerFormValues) => {
    this.submitting = true;

    const success = await this.rootStore.baseApiService.put(`dts-manager/${values.id}`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        history.push('/dashboard/dts-managers');
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
        this.getDtsManagers();
      })
    }
    else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      })
    }
  }

  deleteDtsManager = async (id: string) => {
    this.submitting = true;

    const success = await this.rootStore.baseApiService.delete(`dts-manager/${id}`);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.confirmModalStore.closeConfirmModal();
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Delete_Success");
        this.getDtsManagers();
      })
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      })
    }
  }
}