import { FieldProps, getIn } from "formik";
import { Form, FormFieldProps, Checkbox, Label, Popup, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

interface IProps extends FieldProps<string, HTMLInputElement>, FormFieldProps {
  onChange?: (e: any) => void;
}

export const CheckBox = (props: IProps) => {
  const {
    field,
    id,
    label,
    form: { touched, errors, setFieldValue },
    tooltip,
    required
  } = props;

  const { t } = useTranslation();

  const labelName = required ? label + " *" : label;

  const handleCheckbox = (e: any) => {
    setFieldValue(field.name, e.target.checked);
  }

  return (
    <Form.Field>
      <Checkbox
        checked={field.checked}
        id={id}
        label={labelName}
        name={field.name}
        onChange={(e) => props.onChange ? props.onChange(e) : handleCheckbox(e)}
        value={id}
      />

      {tooltip && (
        <Popup
          content={tooltip}
          trigger={<Button type="button" className="tooltip" icon="info circle" />}
        />
      )}

      {getIn(touched, field.name) && getIn(errors, field.name) && (
        <Label
          color="red"
          content={t(getIn(errors, field.name))}
          icon="times circle"
          size="large"
          style={{ width: "fit-content", display: "block" }}
        />
      )}
    </Form.Field>
  );
};
