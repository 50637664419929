import Roles from "../../Enums/Roles";
import { ConcordiumManagers } from "../../pages/secure/admin/users/ConcordiumManager/ConcordiumManagers";
import { CreateConcordiumManager } from "../../pages/secure/admin/users/ConcordiumManager/CreateConcordiumManager";
import { EditConcordiumManager } from "../../pages/secure/admin/users/ConcordiumManager/EditConcordiumManager";
import { IRoute } from "../../models/routes/IRoute";

export const ConcordiumManagerRoutes: IRoute[] = [
  {
    component: ConcordiumManagers,
    path: "/concordium-managers",
    title: "Navbar.Concordium_Managers",
    exact: true,
    showInMenu: true,
    permission: [Roles.ADMIN, Roles.DTSMANAGER],
    dropdown: false,
    childs: [],
  },
  {
    component: CreateConcordiumManager,
    path: "/concordium-manager/new",
    title: "Navbar.Concordium_Managers",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN, Roles.DTSMANAGER],
    dropdown: false,
    childs: [],
  },
  {
    component: EditConcordiumManager,
    path: "/concordium-manager/edit/:id",
    title: "Navbar.Concordium_Managers",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN, Roles.DTSMANAGER],
    dropdown: false,
    childs: [],
  },
]