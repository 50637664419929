import Roles from "../../Enums/Roles";
import { CreateDtsManager } from "../../pages/secure/admin/users/DtsManager/CreateDtsManager";
import { DtsManagers } from "../../pages/secure/admin/users/DtsManager/DtsManagers";
import { EditDtsManager } from "../../pages/secure/admin/users/DtsManager/EditDtsManager";
import { IRoute } from "../../models/routes/IRoute";
import { IssuanceReference } from "../../pages/secure/dtsManager/IssuanceReferences";

export const DtsManagerRoutes: IRoute[] = [
  {
    component: IssuanceReference,
    path: "/issuance-references",
    title: "Navbar.Issuance_Reference",
    exact: true,
    showInMenu: true,
    permission: [Roles.DTSMANAGER],
    dropdown: false,
    childs: [],
  },
  {
    component: DtsManagers,
    path: "/dts-managers",
    title: "Navbar.Dts_Managers",
    exact: true,
    showInMenu: true,
    permission: [Roles.ADMIN, Roles.DTSMANAGER],
    dropdown: false,
    childs: [],
  },
  {
    component: CreateDtsManager,
    path: "/dts-manager/new",
    title: "Navbar.Dts_Managers",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN, Roles.DTSMANAGER],
    dropdown: false,
    childs: [],
  },
  {
    component: EditDtsManager,
    path: "/dts-manager/edit/:id",
    title: "Navbar.Dts_Managers",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN, Roles.DTSMANAGER],
    dropdown: false,
    childs: [],
  },
]