import { observer } from "mobx-react-lite";

interface IProps {
  children: any;
}

export const MainLayout: React.FC<IProps> = observer(({ children }) => {
  return (
    <>
      {children}
    </>
  )
})