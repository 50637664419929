import { ECriiptoType } from "../../Enums/ECriiptoType";
import { EMakeAChoice } from "../../Enums/EMakeAChoice";

export interface ICriiptoAcr {
  id: number;
  countryAlpha2: string;
  eidName: string;
  acrValue: string;
  enabled: boolean;
  comment: string;
  criiptoType: ECriiptoType;
  forced: boolean;
}

export class CriiptoAcrFormValues {
  countryAlpha2: string = "";
  eidName: string = "";
  acrValue: string = "";
  enabled: boolean = true;
  comment: string = "";
  forced: boolean = false;
  criiptoType: number = EMakeAChoice.Value;

  constructor(init?: ICriiptoAcr) {
    Object.assign(this, init);
  }
}