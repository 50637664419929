import { Divider, Header } from "semantic-ui-react";
import { DtsManagerForm } from "../../../../../Components/secure/dtsManager/DtsManagerForm";
import { RouteComponentProps, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface RouteParams {
  id: string;
}

export const EditDtsManager: React.FC<RouteComponentProps<RouteParams>> = () => {
  const { id } = useParams<RouteParams>();
  const { t } = useTranslation();
  return (
    <>
      <Header as="h1">{t("Dts_Manager.Edit")}</Header>
      <Divider />
      <DtsManagerForm id={id} />
    </>
  )
}