import "./MobileLayout.css";
import { Segment, Header, Image } from 'semantic-ui-react';
import { StateValue } from '../Components/Context';
import { StyledProgressBar } from '../Components/StyledComponents/StyledProgressbar';
import { useContext } from 'react'

interface IProps {
  children: any,
  useLayout?: boolean,
  title: string,
  clientClass?: string,
}

const MobileLayout = (props: IProps) => {
  const state = useContext(StateValue);
  if (props.useLayout || props.useLayout === undefined) {
    return (
      <div className={`mobile-content-container${props.clientClass ? "-" + props.clientClass : ""}`}>
        <Segment className={`mobile-content-header-container${props.clientClass ? "-" + props.clientClass : ""}`}>
          <StyledProgressBar percent={(state.current / (state.flow.length)) * 100} attached="top" color={state.org.theme.themeColor} className="" />
          <Header as="h3" className="mt-0">
            <Image src={state.org.theme.logo} style={{ width: "auto", maxHeight: "1.28571429em" }} />
            <span title={`Version: ${process.env.REACT_APP_VERSION}`} />
            {props.title}
          </Header>
        </Segment>
        <div style={{ flex: 1 }} className={`mobile-service-item-container${props.clientClass ? "-" + props.clientClass : ""}`}>
          {props.children}
        </div>
      </div>
    )
  } else {
    return (
      <div style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", justifyContent: "space-between", alignItems: "stretch", height: "100%", width: "100vw" }} className="mobile-content-container">
        {props.children}
      </div>
    )
  }

}

export default MobileLayout
