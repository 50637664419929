import { format, addDays } from 'date-fns'
import { ECriiptoType } from '../../Enums/ECriiptoType';

export interface IBreakdownStatistics {
  totalTransactions: number;
  completedCountry: number;
  scannedDocuments: number;
  totalMitekCalls: number;
  startedMitekCall: number;
  totalMitekAgentCalls: number;
  succesfullMitekChecks: number;
  accountsCreated: number;
  accountsNotAcceptedByChain: number;
  criiptoStats: ICriiptoStatistics;
}

export interface ICriiptoStatistics {
  success: number;
  failed: number;
  total: number;
  criiptoStats: ICriiptoTypeStatistics[];
}
export interface ICriiptoTypeStatistics {
  criiptoType: ECriiptoType;
  amount: number;
}

export interface IGetBreakdownStatistics {
  start: string;
  end: string;
}

export class GetBreakdownStatisticsFormValues {
  start: string | null = format(addDays(new Date(), -7), "yyyy-MM-dd'T'00:00");
  end: string | null = format(new Date(), "yyyy-MM-dd'T'00:00");

  constructor(init?: IGetBreakdownStatistics) {
    Object.assign(this, init);
    if (init) {

      this.start = init.start === null ? format(addDays(new Date(), -7), "yyyy-MM-dd'T'00:00") : format(new Date(init.start), "yyyy-MM-dd'T'00:00")
      this.end = init.end === null ? format(new Date(), "yyyy-MM-dd'T'00:00") : format(new Date(init.end), "yyyy-MM-dd'T'00:00")
    }
  }
}