import { ELogType } from '../../../../Enums/ELogType';
import { Transition, Segment, ButtonGroup, Button, Icon, Image } from 'semantic-ui-react';
import { postUserProgress } from '../../../../Services/Backend';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type IProps = {
  countDown: number;
  debouncedIsVisible: boolean;
  state: any;
  hint: string | undefined;
  setCurrentPage: (page: number) => void;
  loadingCamera: boolean;
  mitekScienceSDK: any;
  ctxId: string; // Only for logging purposes
}

const MitekFront = (props: IProps) => {

  useEffect(() => {
    postUserProgress({ ctxId: props.ctxId, type: ELogType.EnteredScanComponent }).then(res => {
      sessionStorage.setItem("LAST_LOG_TYPE", res.data.type);
      sessionStorage.setItem("LAST_LOG_DATE", res.data.created);
    });
  }, []);


  const { t } = useTranslation();
  return (
    <>
      <Image style={{ zIndex: 1001, position: "absolute", top: "1em", left: "1em" }} src={props.state.org.theme.logo} className="logoImg" fluid />
      <div style={{ position: "absolute", margin: "auto", zIndex: 1000, width: window.outerWidth, bottom: 0, left: 0, right: 0, top: 0, height: "1px" }}>

        {props.countDown < 5 && (
          <Transition visible={props.debouncedIsVisible} animation='fly right' duration={500}>
            <h2 className="hintMessage" style={{ marginTop: "-0.5em" }}>
              {props.hint}
            </h2>
          </Transition>
        )}
      </div>
      <Segment className="mitek" basic style={{ height: "100%", width: "100%", padding: 0, margin: 0 }} compact loading={props.loadingCamera}>
        <div id="mitekContainer" style={{ width: "100%", height: "100%", zIndex: 1 }}></div>
      </Segment>

      <Segment className="mitek" style={{ bottom: 0, zIndex: 1000, position: "absolute", height: "10%", margin: 0, width: "100vw", display: "flex", justifyContent: "center", alignItems: "center", opacity: ".85" }} size="small">
        <ButtonGroup basic widths="3">
          <Button disabled={props.countDown !== 0} onClick={() => { props.mitekScienceSDK.cmd("SDK_STOP"); props.setCurrentPage(10); }} >
            <Icon name="photo" /> {t("Mitek.Manual")} {`${props.countDown !== 0 ? "(" + props.countDown + ")" : ""}`}
          </Button>
          <Button onClick={() => { props.mitekScienceSDK.cmd("SDK_STOP"); window.location.reload(); }}>
            <Icon name="id card outline" /> {t("Mitek.Other")}
          </Button>
        </ButtonGroup>
      </Segment>
    </>
  )
};

export default MitekFront;
