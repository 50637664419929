import i18n from "../i18n";
import { EToastType } from "../Enums/EToastType";
import { RootStore } from "./RootStore";
import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";

export default class NotificationStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  showNotification = (type: EToastType, message: string) => {
    switch (type) {
      case "info":
        return toast.info(i18n.t(message))
      case "success":
        return toast.success(i18n.t(message))
      case "warning":
        return toast.warning(i18n.t(message))
      case "error":
        return toast.error(i18n.t(message))
      default:
        return toast(i18n.t(message))
    }
  }
}
