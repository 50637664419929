import { GetBreakdownErrorStatisticsFormValues, IBreakdownErrorStatistics } from "../models/errorStatistics/IBreakdownErrorStatistics";
import { IGeneralErrorStatistics } from "../models/errorStatistics/IGeneralErrorStatistics";
import { RootStore } from "./RootStore";
import { makeAutoObservable, runInAction } from "mobx";

export default class ErrorStatisticsStore {
  rootStore: RootStore;
  loadingGeneral: boolean = false;
  loadingBreakdownStats: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  GetGeneralErrorStatistics = async () => {
    runInAction(() => {
      this.loadingGeneral = true;
    });
    const { success, data }: { success: boolean; data: IGeneralErrorStatistics } = await this.rootStore.baseApiService.get('error-statistics/general');
    if (success) {
      runInAction(() => {
        this.loadingGeneral = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loadingGeneral = false;
      });
    }
  }

  GetBreakdownErrorStatistics = async (values: GetBreakdownErrorStatisticsFormValues) => {
    runInAction(() => {
      this.loadingBreakdownStats = true;
    });
    const { success, data }: { success: boolean; data: IBreakdownErrorStatistics } = await this.rootStore.baseApiService.get(`error-statistics/breakdown?start=${values.start}&end=${values.end}`);
    if (success) {
      runInAction(() => {
        this.loadingBreakdownStats = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loadingBreakdownStats = false;
      });
    }
  }
}