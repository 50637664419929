import PrivateRoutesConfig from "./PrivateRoutesConfig"
import MapAllowedRoutes from "./MapAllowedRoutes"
import { DashboardLayout } from "../Layouts/DashboardLayout"
import { GetAllowedRoutes } from "./RouteUtils"
import { IRoute } from "../models/routes/IRoute"
import { MainLayout } from "../Layouts/MainLayout"
import { NotFound } from "../pages/errorPages/NotFound"
import { RootStoreContext } from "../stores/RootStore"
import { useContext } from "react"

interface IProps {
  path: string
}

const PrivateRoutes: React.FC<IProps> = ({ path }) => {
  const rootStore = useContext(RootStoreContext)
  let allowedRoutes: IRoute[] = [];

  if (rootStore.authorizationStore.isAuthenticated()) {
    allowedRoutes = GetAllowedRoutes(PrivateRoutesConfig);
  } else {
    return (
      <MainLayout>
        <NotFound />
      </MainLayout>
    )
  }

  return (
    <DashboardLayout>
      <MapAllowedRoutes routeMatch={path} routes={allowedRoutes} />
    </DashboardLayout>
  )
}

export default PrivateRoutes;