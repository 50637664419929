import { Button, Divider, Grid, Header, Icon } from "semantic-ui-react";
import { DataView, DataViewSearch, IDataViewColumn } from "../../../Common/dataview/Dataview";
import { EDocumentType } from "../../../../Enums/EDocumentType";
import { IMitekSupportedDocument } from "../../../../models/admin/IMitekSupportedDocument";
import { Link } from "react-router-dom";
import { RootStoreContext } from "../../../../stores/RootStore";
import { Spinner } from "../../../Common/spinner/Spinner";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const MitekDocumentList = observer(() => {
  const { t } = useTranslation();
  const rootStore = useContext(RootStoreContext);
  const { openConfirmModal } = rootStore.confirmModalStore;
  const { loadingDocuments, mitekSupportedDocuments, getMitekSupportedDocuments, deleteMitekSupportedDocument } = rootStore.adminStore;
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    getMitekSupportedDocuments();
  }, [])

  const getColumns = (): IDataViewColumn[] => {
    return [
      { headerName: t("Dataview.Documents.CountryName"), field: "countryName", sortable: true, flex: 1, filter: false },
      { headerName: t("Dataview.Documents.CountryAlpha2"), field: "countryAlpha2", sortable: true, flex: 1, filter: false, sort: 'asc' },
      { headerName: t("Dataview.Documents.Type"), field: "documentType", cellRenderer: "enum", cellRendererParams: { type: EDocumentType, translationKey: "Enum.DocumentType" }, sortable: true, flex: 1, filter: false },
      { headerName: t("Dataview.Year"), field: "year", sortable: true, cellRenderer: "date", flex: 1, filter: false },
      { headerName: t("Dataview.Documents.AutoCoverage"), field: "hasAutoCoverage", cellRenderer: "checkmark", sortable: false, flex: 1, filter: false },
      { headerName: t("Dataview.Documents.AgentCoverage"), field: "hasAgentCoverage", cellRenderer: "checkmark", sortable: false, flex: 1, filter: false },
      { headerName: t("Dataview.Actions"), cellRenderer: "actions", sortable: false, flex: 1 },
    ]
  }

  const renderCellAction = (tableData: any) => {
    const mitekDocument: IMitekSupportedDocument = toJS(tableData.data);
    return (
      <div>
        <Button as={Link} className="icon" compact primary to={`mitek-supported-document/edit/${mitekDocument.id}`}>
          <Icon name="edit outline" />
        </Button>
        <Button className="icon" color="red" compact
          onClick={() => openConfirmModal(t("Button.Cancel"), t("Button.Delete"), t("Mitek.Document.Delete"), () => deleteMitekSupportedDocument(mitekDocument.id))}>
          <Icon name="trash alternate outline" />
        </Button>
      </div>
    )
  }

  return (
    <>
      <Header as="h1">{t("Mitek.Document.Supported_Documents")}</Header>
      <Divider />
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <DataViewSearch onFilterChange={(input: any) => setSearchInput(input.target.value)} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Button as={Link} floated='right' color="green" to={`mitek-supported-document/new`}>
              {t("Mitek.Document.New")}
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {loadingDocuments ? <Spinner /> :
              <DataView
                actionsRenderer={(data: any) => renderCellAction(data)}
                columns={getColumns()}
                data={mitekSupportedDocuments}
                quickFilter={searchInput}
              />
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
})