export enum EIssuanceReference {
  None = 0,
  InvalidDocument = 1,
  InvalidSelfie = 2,
  IsExpired = 3,
  MissingData = 4,
  NotAllowedCountry = 5,
  NotRetrievedFromAgent = 6,
  AgentHandoffPending = 7,
  MaxAddToChainAttemtps = 8,
  Other = 9,
  AgentResponseFaulty = 10,
  AgentTimeout = 11,
  AgentResponseInvalid = 12,
  CreateIdentityObject = 13,
  SubmissionToChain = 14,
  RetrieveSubmission = 15,
}

export interface IIssuanceReferenceEnum {
  value: EIssuanceReference;
  name: string;
}

const issuanceItem = (value: EIssuanceReference, name: string): IIssuanceReferenceEnum => ({ value, name });

export const IssuanceReferenceEnums = (): IIssuanceReferenceEnum[] => {
  let list: IIssuanceReferenceEnum[] = [];
  list.push(issuanceItem(EIssuanceReference.None, EIssuanceReference[EIssuanceReference.None]));
  list.push(issuanceItem(EIssuanceReference.InvalidDocument, EIssuanceReference[EIssuanceReference.InvalidDocument]));
  list.push(issuanceItem(EIssuanceReference.InvalidSelfie, EIssuanceReference[EIssuanceReference.InvalidSelfie]));
  list.push(issuanceItem(EIssuanceReference.IsExpired, EIssuanceReference[EIssuanceReference.IsExpired]));
  list.push(issuanceItem(EIssuanceReference.MissingData, EIssuanceReference[EIssuanceReference.MissingData]));
  list.push(issuanceItem(EIssuanceReference.NotAllowedCountry, EIssuanceReference[EIssuanceReference.NotAllowedCountry]));
  list.push(issuanceItem(EIssuanceReference.NotRetrievedFromAgent, EIssuanceReference[EIssuanceReference.NotRetrievedFromAgent]));
  list.push(issuanceItem(EIssuanceReference.AgentHandoffPending, EIssuanceReference[EIssuanceReference.AgentHandoffPending]));
  list.push(issuanceItem(EIssuanceReference.MaxAddToChainAttemtps, EIssuanceReference[EIssuanceReference.MaxAddToChainAttemtps]));
  list.push(issuanceItem(EIssuanceReference.Other, EIssuanceReference[EIssuanceReference.Other]));
  list.push(issuanceItem(EIssuanceReference.AgentResponseFaulty, EIssuanceReference[EIssuanceReference.AgentResponseFaulty]));
  list.push(issuanceItem(EIssuanceReference.AgentTimeout, EIssuanceReference[EIssuanceReference.AgentTimeout]));
  list.push(issuanceItem(EIssuanceReference.AgentResponseInvalid, EIssuanceReference[EIssuanceReference.AgentResponseInvalid]));
  list.push(issuanceItem(EIssuanceReference.CreateIdentityObject, EIssuanceReference[EIssuanceReference.CreateIdentityObject]));
  list.push(issuanceItem(EIssuanceReference.SubmissionToChain, EIssuanceReference[EIssuanceReference.SubmissionToChain]));
  list.push(issuanceItem(EIssuanceReference.RetrieveSubmission, EIssuanceReference[EIssuanceReference.RetrieveSubmission]));
  return list;
}