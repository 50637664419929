import { IPassword } from "./IPassword";

export interface IConcordiumManager {
  id: string;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  lastActivity: Date;
  password: IPassword;
}

export class ConcordiumManagerFormValues {
  id?: string | undefined;
  email: string = "";
  userName: string = "";
  firstName: string = "";
  lastName: string = "";

  constructor(init?: IConcordiumManager) {
    Object.assign(this, init);
  }
}