import MitekHeader from './MitekHeader';
import { EMitekErrorType } from '../../../../Enums/EMitekErrorType';
import { MitekResponse } from '../../../../Types';
import { Segment, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

type IProps = {
  response?: MitekResponse;

}

const MitekErrors = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      <MitekHeader />
      <Segment className="mitek" basic style={{ height: "calc(100% - 5em - 4em)", width: "100%", margin: 0 }}>
        <h3>{t("Mitek.Error.Something_Went_Wrong_Checking_Documents")}
        </h3>
        <br />
        {props.response !== undefined && props.response.reason !== undefined && props.response.reason !== EMitekErrorType.None ? (
          <>
            {props.response.reason === EMitekErrorType.Img && (
              <>
                <h4>
                  {t("Mitek.Error.Img")}
                </h4>
                <p>{t("Mitek.Error.Required_Properties")}</p>
              </>
            )}
            {props.response.reason === EMitekErrorType.Expired && (
              <h4>
                {t("Mitek.Error.Document_Expired")}
              </h4>
            )}
            {props.response.reason === EMitekErrorType.Country && (
              <h4>
                {t("Mitek.Error.Country")}
              </h4>
            )}
            {props.response.reason === EMitekErrorType.ImgSelfie && (
              <h4>
                {t("Mitek.Error.Selfie")}
              </h4>
            )}
            {props.response.reason === EMitekErrorType.HardFailure && (
              <h4>
                {t("Mitek.Error.Document_Fraudulent")}
              </h4>
            )}
            {props.response.reason === EMitekErrorType.Id && (
              <h4>
                {t("Mitek.Error.Document_Fraudulent")}
              </h4>
            )}
            {/* Other EMitekErrorTypes shouldn't make it to this point, below is just in case */}
            {props.response.reason !== EMitekErrorType.Img && props.response.reason !== EMitekErrorType.ImgSelfie && props.response.reason !== EMitekErrorType.HardFailure && props.response.reason !== EMitekErrorType.Id && (
              <h4>
                {t("Mitek.Error.Document_Fraudulent")}
              </h4>
            )}
          </>
        ) : (
          <h4>
            {t("Mitek.Error.Document_Fraudulent")}
          </h4>
        )}
        <Button color="red" basic floated='left' onClick={() => window.location.reload()}>{t("General_UI.Retry")}</Button>
      </Segment>
      <Segment className="service-item-button-container mitek mt-0" style={{ height: "10%", margin: 0, width: "100vw", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
        <Button loading color="green" floated="right" disabled>
          {t("General_UI.Next")}
        </Button>
      </Segment>
    </>
  )
};

export default MitekErrors;
