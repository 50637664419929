import MitekCheckFrontExplanation from './MitekCheckFrontExplanation';
import MitekHeader from './MitekHeader';
import { Segment, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

type IProps = {
  isIOSApp: boolean;
  state: any;
  setCurrentPage: (page: number) => void;
}

const MitekHelpFront = (props: IProps) => {

  const { t } = useTranslation();

  return (
    <>
      <MitekHeader />
      <MitekCheckFrontExplanation isIOSApp={props.isIOSApp} themeColor={props.state.org.theme.themeColor} />
      <Segment className="service-item-button-container mitek mt-0" style={{ height: "10%", margin: 0, width: "100vw", display: "flex", justifyContent: "flex-end", alignItems: "center", }} >
        <Button color="green" floated="right" onClick={() => props.isIOSApp ? props.setCurrentPage(10) : props.setCurrentPage(1)}>
          {t("General_UI.Next")}
        </Button>
      </Segment>
    </>
  )
};

export default MitekHelpFront;
