import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { CancelButton } from "../../Common/Button/CancelButton";
import { ConcordiumManagerFormValidation } from "./ConcordiumManagerForm.Validation";
import { ConcordiumManagerFormValues, IConcordiumManager } from "../../../models/user/IConcordiumManager";
import { Field, Formik } from "formik";
import { PasswordInput } from "../../Common/form/PasswordInput";
import { RootStoreContext } from "../../../stores/RootStore";
import { Spinner } from "../../Common/spinner/Spinner";
import { TextInput } from "../../Common/TextInput";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  id?: string;
}

export const ConcordiumManagerForm: React.FC<IProps> = observer(({ id }) => {
  const { t } = useTranslation();
  const rootStore = useContext(RootStoreContext);
  const { loadingConcordiumManagers, submitting, addConcordiumManager, editConcordiumManager, getConcordiumManager } = rootStore.concordiumManagerStore;
  const [formValues, setFormValues] = useState(new ConcordiumManagerFormValues());
  const [lastActivity, setLastActivity] = useState<Date>();

  useEffect(() => {
    const fetchConcordiumManager = async (id: string) => {
      const ccManager: IConcordiumManager | undefined = await getConcordiumManager(id);
      setFormValues(new ConcordiumManagerFormValues(ccManager));
      setLastActivity(ccManager?.lastActivity);
    }
    if (id) {
      fetchConcordiumManager(id);
    }
  }, [id])

  const handleSubmit = async (values: ConcordiumManagerFormValues) => {
    if (id) {
      await editConcordiumManager(values);
    } else {
      await addConcordiumManager(values);
    }
  };

  if (loadingConcordiumManagers) return <Spinner />

  return (
    <Grid stackable>
      {id && <Grid.Row>
        <Grid.Column width={3}>
          <span><strong>Last logged in</strong></span>
        </Grid.Column>
        <Grid.Column width={5}>
          <span>{lastActivity !== undefined ? t("I18n.Formatted.DateTime", { date: new Date(lastActivity) }) : "-"}</span>
        </Grid.Column>
      </Grid.Row>}
      <Grid.Row>
        <Grid.Column width={8}>
          <Formik
            enableReinitialize
            initialValues={formValues}
            onSubmit={handleSubmit}
            validationSchema={ConcordiumManagerFormValidation(id === undefined)}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Segment padded className='bg-lightest-grey'>
                  <Field
                    component={TextInput}
                    disabled={id !== undefined}
                    label={t("Label.Email")}
                    name="email"
                    required={!id}
                  />
                  <Field
                    component={TextInput}
                    label={t("Label.Name.First")}
                    name="firstName"
                    required
                  />
                  <Field
                    component={TextInput}
                    label={t("Label.Name.Last_Prefix")}
                    name="lastNamePrefix"
                  />
                  <Field
                    component={TextInput}
                    label={t("Label.Name.Last")}
                    name="lastName"
                    required
                  />
                </Segment>

                <Segment padded className='bg-lightest-grey'>
                  {id && <p style={{ color: "#ca005d" }}>{t("Password.Only_Fill_When_Change")}</p>}
                  <Field
                    component={PasswordInput}
                    label={t("Password.Password")}
                    name="password.password"
                    required={!id}
                  />
                  <Field
                    component={PasswordInput}
                    label={t("Password.Repeat")}
                    name="password.confirmPassword"
                    required={!id}
                  />
                </Segment>

                <Button disabled={submitting} floated='right' loading={submitting} primary type="submit">
                  {t("Button.Save")}
                </Button>
                <CancelButton floated='left' />
              </Form>
            )}
          </Formik>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
})