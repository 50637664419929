import React, { useContext } from "react";
import { FlowOrderSettings, ServiceComponentType } from "../Types";

export type state = {
  targetUrl: string,
  view: "form" | "checking" | "success" | "agenthandoff";
  current: number;
  lastFinishedAccordingToBackend?: number;
  flow: { serviceId: number; order: number, settings?: FlowOrderSettings, component: ServiceComponentType }[];
  flowDescription: string,
  org: { name: string; theme: { themeColor: string; logo: string, clientClass?: string } };
  loading: boolean;
  header: string;
  errors: any;
  ctxId: string;
  isAgentHandoff: boolean;
  agentHandoffCompleted: boolean;
};

export function reducer(state: state, action: action): state {
  switch (action.type) {
    case "setTargetUrl":
      return { ...state, targetUrl: action.data };
    case "setLastFinishedAccordingToBackend":
      return { ...state, lastFinishedAccordingToBackend: action.data };
    case "setView":
      return { ...state, view: action.data };
    case "setHeader":
      return { ...state, header: action.data };
    case "setErrors":
      return { ...state, errors: action.data };
    case "setOrg":
      return { ...state, org: action.data };
    case "setCurrent":
      return { ...state, current: action.data };
    case "setFlow":
      return { ...state, flow: action.data };
    case "setLoading":
      return { ...state, loading: action.data };
    case "setCtxId":
      return { ...state, ctxId: action.data };
    case "init":
      return action.data;
    default:
      throw new Error(JSON.stringify(action));
  }
}

export type action =
  | { type: "setTargetUrl"; data: string }
  | { type: "setView"; data: "form" | "checking" | "success" | "agenthandoff" }
  | { type: "setHeader"; data: string }
  | { type: "setErrors"; data: any }
  | { type: "setOrg"; data: { name: string; theme: { themeColor: string; logo: string } }; }
  | { type: "setFlow"; data: { serviceId: number; order: number, component: ServiceComponentType }[] }
  | { type: "setLoading"; data: boolean }
  | { type: "setCurrent"; data: number }
  | { type: "setLastFinishedAccordingToBackend"; data: number }
  | { type: "init"; data: state }
  | { type: "setCtxId"; data: string };

export const initState: state = {
  targetUrl: "",
  lastFinishedAccordingToBackend: -1,
  current: -1,
  flow: [],
  flowDescription: "",
  loading: true,
  org: { name: "", theme: { themeColor: "", logo: "", clientClass: undefined } },
  view: "form",
  errors: undefined,
  header: "",
  ctxId: "",
  isAgentHandoff: false,
  agentHandoffCompleted: false,
};

export const StateDispatch = React.createContext<React.Dispatch<action>>(
  () => null
);

export const StateValue = React.createContext<state>(initState);

export const useStateDispatch = () => useContext(StateDispatch);
