import * as Yup from "yup";
import { EMakeAChoice } from "../../../../Enums/EMakeAChoice";

export const MitekDocumentFormValidation = () => Yup.object().shape({
  countryAlpha2: Yup.string()
    .required()
    .max(2),
  year: Yup.string().nullable(),
  documentType: Yup.number()
    .required()
    .notOneOf([EMakeAChoice.Value], "Enum.Make_A_Choice"),
  autoCoveredPages: Yup.number()
    .required()
    .notOneOf([EMakeAChoice.Value], "Enum.Make_A_Choice"),
  hasAutoCoverage: Yup.boolean(),
  hasAgentCoverage: Yup.boolean(),
})