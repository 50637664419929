import SidebarCountryEmail from "./Components/Services/CountryEmail/SidebarCountryEmail";
import SidebarMitek from "./Components/Services/Mitek/Sidebar/SidebarMitek";
import { CountryEmailComponent } from "./Components/Services/CountryEmail/CountryEmailComponent";
import { MitekCheckComponent } from "./Components/Services/Mitek/MitekCheckComponent";
import { ServiceComponentType } from "./Types";
import { isDesktop, isIPad13 } from "react-device-detect";
import { MitekCriiptoCheckComponent } from "./Components/Services/MitekCriiptoBase/MitekCriiptoCheckComponent";
import { SidebarCriipto } from "./Components/Services/MitekCriiptoBase/SidebarCriipto";

export const IdentityServices: ServiceComponentType[] = [
  {
    Component: MitekCheckComponent,
    SideBarComponent: SidebarMitek,
    Title: "Mitek.Title",
    UseLayout: (isDesktop && !isIPad13),
    Id: 8,
  },
  {
    Component: CountryEmailComponent,
    SideBarComponent: SidebarCountryEmail,
    Title: "CountryEmail.Title",
    UseLayout: true,
    Id: 9,
  },
  {
    Component: MitekCriiptoCheckComponent,
    SideBarComponent: SidebarCriipto,
    Title: "Criipto.Title",
    UseLayout: (isDesktop && !isIPad13),
    Id: 10,
  },
];