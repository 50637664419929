import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import { Login } from "./pages/login/Login";
import { Route, Router, Switch } from "react-router-dom";
import { Signout } from "./pages/login/Signout";
import { createBrowserHistory } from "history";
import { observer } from "mobx-react-lite";

export const history = createBrowserHistory()

export const routePrefixDashboard = "/dashboard";

export const ApplicationRouter = observer(() => {
  return (
    <Router history={history}>
      <Switch >
        <Route path="/authentication/login" component={Login} />
        <Route path="/authentication/signout" component={Signout} />

        <Route path={routePrefixDashboard}>
          <PrivateRoutes path={routePrefixDashboard} />
        </Route>

        <Route path="">
          <PublicRoutes />
        </Route>

      </Switch>
    </Router>
  )
})