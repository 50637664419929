import { useEffect, useState } from "react";

export type windowSize = {
  width: undefined | number,
  height: undefined | number,
  smallScreen: boolean,
  mediumScreen: boolean,
  orientation: "portrait" | "landscape" | undefined
}
export function useScreenSize() {
  const [windowSize, setWindowSize] = useState<windowSize>({
    width: undefined,
    height: undefined,
    smallScreen: false,
    mediumScreen: false,
    orientation: undefined
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        smallScreen: window.innerWidth < 930,
        mediumScreen: window.innerWidth >= 930 && window.innerWidth < 1281,
        orientation: window.orientation !== 0 ? "landscape" : "portrait"
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}