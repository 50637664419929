import { ConcordiumManagerForm } from "../../../../../Components/secure/concordiumManager/ConcordiumManagerForm";
import { Divider, Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next"

export const CreateConcordiumManager = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header as="h1">{t("Concordium_Manager.New")}</Header>
      <Divider />
      <ConcordiumManagerForm />
    </>
  )
}