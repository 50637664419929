export enum EAutoCoveredPages {
  None = 0,
  All = 1,
  Front = 2,
  Back = 3,
}

export interface IAutoCoveredPagesEnum {
  value: EAutoCoveredPages;
  name: string;
}

const pagesItem = (value: EAutoCoveredPages, name: string): IAutoCoveredPagesEnum => ({
  value,
  name,
});

export const PagesEnums = (): IAutoCoveredPagesEnum[] => {
  let list: IAutoCoveredPagesEnum[] = [];
  list.push(pagesItem(EAutoCoveredPages.None, EAutoCoveredPages[EAutoCoveredPages.None]));
  list.push(pagesItem(EAutoCoveredPages.All, EAutoCoveredPages[EAutoCoveredPages.All]));
  list.push(pagesItem(EAutoCoveredPages.Front, EAutoCoveredPages[EAutoCoveredPages.Front]));
  list.push(pagesItem(EAutoCoveredPages.Back, EAutoCoveredPages[EAutoCoveredPages.Back]));

  return list;
}