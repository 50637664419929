import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Header, Image, Segment } from "semantic-ui-react";
import { StateValue } from "../../../../Context"

export const CriiptoHeader = () => {
  const state = useContext(StateValue);
  const { t } = useTranslation();

  return (
    <Segment className="vertCenter criipto" style={{ width: "100%", height: "4em", marginBottom: 0 }}>
      <Header as="h3" className="vertCenter">
        <Image src={state.org.theme.logo} className="logoImg" fluid />
        {t("Criipto.Title")}
      </Header>
    </Segment>
  )
}