import { Container, Dropdown, Menu } from "semantic-ui-react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { RootStoreContext } from "../../../stores/RootStore"
import { StyledDashboardNavbar } from "./DashboardNavbar.styles";
import { history, routePrefixDashboard } from "../../../ApplicationRouter";
import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next";

export const DashboardNavbar = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { role } = rootStore.authorizationStore;
  const { signOut } = rootStore.authenticationStore;
  const { getProfile, profile } = rootStore.profileStore;

  useEffect(() => {
    const fetchProfile = async () => {
      await getProfile();
    }
    if (role) fetchProfile();
  }, []);

  const handleSignOut = () => {
    signOut();
  }

  const match = useRouteMatch(routePrefixDashboard);

  if (!rootStore.authorizationStore.isAuthenticated()) {
    history.push("/authentication/login");
  }
  return (
    <StyledDashboardNavbar>
      <Container>
        <span className="navbar-title" title={`${t("ApplicationName")} ${process.env.REACT_APP_VERSION}`}>
          {t("ApplicationName")} {t("Navbar.Management_Environment").toLowerCase()}
        </span> {/* TODO: staan momenteel op verschillende regels, moeten op dezelfde regel staan */}
        {role ? (
          <>
            <StyledDashboardNavbar.Menu position="right">
              {/* <StyledDashboardNavbar.Item position="right" onClick={handleSignOut}>
                {t("Navbar.Signout")}
              </StyledDashboardNavbar.Item> */}
              <Dropdown
                item
                simple
                text={`${profile?.firstName} ${!profile?.lastNamePrefix ? "" : profile?.lastNamePrefix} ${profile?.lastName}`}
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    as={NavLink}
                    exact
                    to={`${match?.path}/profile`}
                  >
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleSignOut}>
                    {t("Navbar.Signout")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </StyledDashboardNavbar.Menu>
          </>
        ) : (
          <Menu.Item position="right" onClick={handleSignOut}>
            {t("Navbar.Signout")}
          </Menu.Item>
        )}
      </Container>
    </StyledDashboardNavbar>
  )
})