import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Confirm } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/RootStore";

export const ConfirmModalContainer = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const {
    confirmModal: { open, cancelText, confirmText, content, onConfirm },
    closeConfirmModal,
  } = rootStore.confirmModalStore;

  return (
    <Confirm
      open={open}
      content={content}
      cancelButton={cancelText}
      confirmButton={confirmText}
      onCancel={() => closeConfirmModal()}
      onConfirm={() => onConfirm()}
      size="tiny"
    />
  );
});
