import "./globalstyles/Global.styles.css";
import "react-toastify/dist/ReactToastify.css";
import { ApplicationRouter } from "./ApplicationRouter";
import { Translation } from "react-i18next";

const App = () => {
  return (
    <Translation>
      {() => (
        <>
          <ApplicationRouter />
        </>
      )}
    </Translation>
  );
}

export default App;