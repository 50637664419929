import i18n from "../i18n";

export const isLocalhost = function () {
  return window.location.hostname === "localhost";
}

export const localhostLogText = function (text: string) {
  if (isLocalhost()) {
    console.log(text);
  }
};

export const localhostLogFetch = function (type: any, url: string, body?: any) {
  if (isLocalhost()) {
    console.log(
      "fetch: " + type + " " + url + (body ? ", with body data: " + body : "")
    );
  }
};

export const localhostLogFetchResult = function (
  type: any,
  url: string,
  result: any
) {
  if (isLocalhost()) {
    console.log("fetch: " + type + " " + url + " -> RESULT:");
    console.dir(result);
  }
};

export const removeSurroundingQuotes = function (str: string) {
  return isSurroundedBy(str, '""') || isSurroundedBy(str, "''")
    ? str.slice(1, -1)
    : str;
}

export const isSurroundedBy = function (str: string, delimiters: string) {
  return (
    str && str.length >= 2 &&
    delimiters[0] === str[0] &&
    delimiters[1] === str[str.length - 1]
  );
}

export const formatDateTime = (date: Date | undefined, formatter: "I18n.Formatted.DateTime" | "I18n.Formatted.Date") => {
  return (!date || new Date(date).toUTCString() === new Date("0001-01-01T00:00:00").toUTCString())
    ? "-"
    : i18n.t(formatter, { date: new Date(date) });
}