import { Button, Form, Grid, Header, Message, Segment } from "semantic-ui-react";
import { Field, Formik } from "formik";
import { IUserFormLoginValues } from "../../models/user/IUser";
import { Redirect } from "react-router-dom";
import { RootStoreContext } from "../../stores/RootStore";
import { TextInput } from "../../Components/Common/TextInput";
import { history } from "../../ApplicationRouter";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next"
import { validationSchema } from "./Login.Validation";
import { observer } from "mobx-react-lite";

export const LoginForm = observer(() => {
  const { t } = useTranslation();
  const rootStore = useContext(RootStoreContext);
  const { login, redirectToRole } = rootStore.authenticationStore;
  const isAuthenticated = rootStore.authorizationStore.isAuthenticated();
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleLogin = async (values: IUserFormLoginValues) => {
    setSubmitting(true);

    const response = await login(values, "/dashboard");
    setSubmitting(false);

    if (response.success) {
      history.push(response.redirect);
    }
    if (response.message === `"Error_Account_Locked"`) {
      setMessage(t("Authentication.Error.Account_Locked"))
    } else {
      setMessage(t("Authentication.Error.Invalid_Credentials"))
    }
  }

  return (
    isAuthenticated
      ? <Redirect to={redirectToRole()} />
      : <>
        <Header as="h2">{t("Authentication.Login.Heading")}</Header>
        <Formik
          initialValues={{ password: "", userName: "" }}
          onSubmit={handleLogin}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Segment padded>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Field
                        component={TextInput}
                        id="username"
                        label={t("Authentication.Login.Email")}
                        name="username"
                        type="email"
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Field
                        component={TextInput}
                        id="password"
                        label={t("Authentication.Login.Password")}
                        name="password"
                        type="password"
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row textAlign="right">
                    <Grid.Column>
                      <Button
                        disabled={submitting}
                        loading={submitting}
                        primary
                        type="submit">
                        {t("Authentication.Login.Submit_Button")}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Form>
          )}
        </Formik>
        {message && <Message negative>{message}</Message>}
      </>
  )
})