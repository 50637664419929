import MitekHeader from './MitekHeader';
import { Segment, Button } from 'semantic-ui-react';
import { state } from '../../../Context';
import { useTranslation } from 'react-i18next';

type IProps = {
  state: state | undefined
}

const MitekAlreadyFinished = (props: IProps) => {

  const { t } = useTranslation();

  return (
    <>
      <MitekHeader />
      <Segment className="mitek" basic style={{ height: "calc(100% - 5em - 4em)", width: "100%", margin: 0 }}>
        <h1>{t("Mitek.DidFinish.Header")}</h1>
        {
          sessionStorage.getItem("handoff") == null ? (<h2>{t("Mitek.DidFinish.SubHeader")}</h2>) : (<h2>{t("Mitek.DidFinish.SubHeader_Handoff")}</h2>)
        }
      </Segment>
      <Segment basic className={`service-item-button-container${props.state?.org.theme.clientClass ? "-" + props.state?.org.theme.clientClass : ""} mt-0`} style={{ marginLeft: "1em" }}>
        <Button color="green" floated="right" disabled>{t("Next")}</Button>
      </Segment>
    </>)
};

export default MitekAlreadyFinished;
