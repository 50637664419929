import Roles from "../../Enums/Roles";
import { DashboardHome } from "../../pages/secure/dashboard/DashboardHome";
import { IRoute } from "../../models/routes/IRoute";
import { Profile } from "../../pages/secure/Profile";

export const GeneralRoutes: IRoute[] = [
  {
    component: DashboardHome,
    path: "",
    title: "Navbar.Dashboard",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN, Roles.DTSMANAGER, Roles.CONCORDIUMMANAGER],
    dropdown: false,
    childs: [],
  },
  {
    component: Profile,
    path: "/profile",
    title: "Navbar.Profile",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN, Roles.DTSMANAGER, Roles.CONCORDIUMMANAGER],
    dropdown: false,
    childs: [],
  },
]