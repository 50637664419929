import { AdminRoutes } from "./SubRoutes/AdminRoutes";
import { ConcordiumManagerRoutes } from "./SubRoutes/ConcordiumManagerRoutes";
import { DtsManagerRoutes } from "./SubRoutes/DtsManagerRoutes";
import { GeneralRoutes } from "./SubRoutes/GeneralRoutes";
import { StatisticsRoutes } from "./SubRoutes/StatisticsRoutes";

const privateRoutesConfig = [
  ...GeneralRoutes,
  ...StatisticsRoutes,
  ...AdminRoutes,
  ...DtsManagerRoutes,
  ...ConcordiumManagerRoutes
]

export default privateRoutesConfig;