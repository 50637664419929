import '../View.css';
import axios from 'axios';
import useInterval from '../../../../Services/useInterval'
import { AnimationSuccess } from '../../../AnimationViews/AnimationSuccess'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { ReactComponent as MitekCheckSvg } from '../../../../Assets/SVG/MitekCheck.svg'
import { Segment, Button } from 'semantic-ui-react'
import { StateDispatch, StateValue } from '../../../Context'
import { TimerButton } from '../../../Common/Button/TimerButton';
import { didFinishDto, getDidMitekFinish, getIsAgentHandoff } from '../../../../Services/Backend'
import { renderToStaticMarkup } from 'react-dom/server';
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QRCodeCanvas } from 'qrcode.react';

type MitekCheckDesktopProps = {
  ctxId: string,
  lang: string,
  logo: string,
  res: any
}

export default function MitekCheckDesktop(props: MitekCheckDesktopProps) {
  const { t } = useTranslation()
  const dispatch = useContext(StateDispatch);
  const state = useContext(StateValue);
  const [res, setRes] = useState<didFinishDto | undefined>(undefined);
  const [count, setCount] = useState(0);

  const onSuccess = () => {
    dispatch({ type: "setView", data: "form" });
    setTimeout(() => dispatch({ type: "setCurrent", data: state.current + 1 }), 700);
  }

  useInterval(() => {
    setCount(count + 1);
    if (count < 120) {
      getMitekFinish();
    }
  }, 15000);

  const getMitekFinish = () => {
    getDidMitekFinish(props.ctxId).then(res => {
      setRes(res.data);
      if (res.data.agentHandoff === true) {
        dispatch({ type: "setView", data: "agenthandoff" });
      } else if (res.data.validated === true && res.data.failed === false) {
        dispatch({ type: "setView", data: "success" });
      } else if (res.data.validated === true && res.data.failed === true) {
        dispatch({ type: "setView", data: "form" });
      }
    })
  };

  const background = encodeURIComponent(renderToStaticMarkup(<MitekCheckSvg fill={state.org.theme.themeColor} />));

  const errorCallback = () => {
    axios.get("/api/concordium/error/" + state.ctxId).then(res => {
      window.location.href = res.data.redirectUrl;
    });
    // TODO: Error handling
  }

  const agentCallback = () => {
    setCount(200); // TODO hacky workaround om de refresh te stoppen, hoe dit anders te doen?
    getIsAgentHandoff(props.ctxId, false).then(res => {
      window.location.href = state.targetUrl;
    });
  };

  const renderView = () => {
    switch (state.view) {
      case "form":
        return (
          <>
            {res?.failed !== true && (
              <>
                <span>
                  <h1 className={`service-item-header${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""}`}>{t("Mitek.Desktop.Header")}</h1>
                  <h2 className={`service-item-subheader${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""}`}>{t("Mitek.Desktop.SubHeader")}</h2>
                  <h3 className={`service-item-explanation${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""}`}>
                    {t("Mitek.Desktop.Explanation_1")}
                    <br />
                    {t("Mitek.Desktop.Explanation_2")}.
                  </h3>
                </span>

                <span className="mb-5 mt-4">
                  <QRCodeCanvas value={`${window.location.origin}/handoff?ctxId=${props.ctxId}&lang=${props.lang}`} /><br />{" "}
                </span>
              </>
            )}
            {res?.failed === true && res?.error === undefined && (
              <>
                <span>
                  <h1 className={`service-item-header${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""}`}>{`${t("Mitek.Desktop.Status_Changed")}`}</h1>
                  <h2 className={`service-item-subheader${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""}`}>
                    {t("Mitek.Desktop.Status_Changed_2")}
                  </h2>
                  <h3 className={`service-item-explanation${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""}`}>
                    {/* {t("Mitek.Error.Refresh_1")} <br /> */}
                    {t("Mitek.Desktop.Status_Changed_3")}
                  </h3>
                </span>
                <span className="mb-6 mt-4">
                  <QRCodeCanvas value={`${window.location.origin}/handoff?ctxId=${props.ctxId}&lang=${props.lang}`} /><br />{" "}
                </span>
              </>
            )}
            {res?.failed === true && res?.error !== undefined && (
              <>
                <span>
                  <h1 className={`service-item-header${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""}`}>{`${t("Mitek.Error.Generic_Error")}`}</h1>

                  <h3 className={`service-item-explanation${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""}`}>
                    {/* {t("Mitek.Error.Refresh_1")} <br /> */}
                    {t("Mitek.Error.Redirect")}
                  </h3>
                </span>
              </>
            )}
          </>
        )
      case "success":
        return <AnimationSuccess header={t("General_UI.Great")} text={t("General_UI.Correct")} key={`${state.view}-success`} />
      case "agenthandoff":
        return (
          <>
            <span>
              <h1 className={`service-item-header${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""}`}>{t("Mitek.Agent_Assist.Header_Desktop")}</h1>
              <h2 className={`service-item-subheader${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""}`}>{t("Mitek.Agent_Assist.SubHeader")}</h2>
            </span>
          </>
        )
    }
  }

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${state.view}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="transition-container space-between" style={{ background: state.view !== "success" ? `url("data:image/svg+xml,${background}")` : "", backgroundSize: "clamp(20%, 60%, 400px", backgroundPosition: "right bottom", backgroundRepeat: "no-repeat", marginBottom: "4em" }}>
            {renderView()}
          </div>
        </CSSTransition>
      </SwitchTransition>

      <Segment basic className={`service-item-button-container${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""} mt-0`}>
        {
          res?.validated !== true
            ? (<Button color="green" basic floated="right" loading> Loading...</Button>)
            : (res.error === undefined ? (<>
              {
                res?.agentHandoff === true
                  ? (
                    // <TimerButton callback={agentCallback} timeOut={10} />
                    <Button color="green" basic floated='right' onClick={() => { agentCallback() }}>Open Wallet</Button>
                  )
                  : (
                    <Button color="green" basic floated="right"
                      loading={!(res?.validated === true)}
                      onClick={() => { if (res?.validated === true && res?.failed === false) { onSuccess() } }}
                      disabled={res?.failed === true}>{t("General_UI.Next")}
                    </Button>
                  )
              }
            </>) : <TimerButton callback={errorCallback} timeOut={6} />)
        }
      </Segment>
    </>
  )
}
