import { Button, Grid, Modal, Popup } from "semantic-ui-react";
import { EDocumentType } from "../../../Enums/EDocumentType";
import { EGender } from "../../../Enums/EGender";
import { IAnonimityRevocation } from "../../../models/admin/IAnonimityRevocation";
import { useTranslation } from "react-i18next";

interface IProps {
  anonimity: IAnonimityRevocation
}

export const AnonimityRevocationDetailModal = ({ anonimity }: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal.Header>Anonimity details</Modal.Header>
      <Modal.Content scrolling>
        <Grid>
          <Grid.Row columns={4}>
            <Grid.Column>
              <p>Firstname: {anonimity.firstName}</p>
            </Grid.Column>
            <Grid.Column>
              <p>Lastname: {anonimity.lastName}</p>
            </Grid.Column>
            <Grid.Column>
              <p>Sex: {EGender[anonimity.Sex]}</p>
            </Grid.Column>
            <Grid.Column>
              <p>Date of birth: {anonimity.dateOfBirth !== null ? t("I18n.Formatted.Date", { date: new Date(anonimity.dateOfBirth) }) : "-"}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4}>
            <Grid.Column>
              <p>Document type: {EDocumentType[anonimity.documentType]}</p>
            </Grid.Column>
            <Grid.Column>
              <p>Document number: {anonimity.documentNumber}</p>
            </Grid.Column>
            <Grid.Column>
              <p>Document issuer: {anonimity.documentIssuer}</p>
            </Grid.Column>
            <Grid.Column>
              <p>National id number: {anonimity.nationalIdNumber}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <p>Account address: {anonimity.accountAddress}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <p>IdCredPub: {anonimity.idCredPub}</p>
            </Grid.Column>
          </Grid.Row>
          {/* TODO: een editor toevoegen om de json data fatsoenlijk mee weer te geven */}
          <Grid.Row columns={4}>
            <Grid.Column>
              <Popup
                content={anonimity.identityObject}
                on='click'
                pinned
                trigger={<Button secondary content="Identity object" />}
              />
            </Grid.Column>
            <Grid.Column>
              <Popup
                content={anonimity.revocationRecord}
                on='click'
                pinned
                trigger={<Button secondary content="Revocation record" />}
              />
            </Grid.Column>
            <Grid.Column>
              <Popup
                content={anonimity.originalExtractedData}
                on='click'
                pinned
                flowing
                trigger={<Button secondary content="Original data" />}
              />
            </Grid.Column>
            <Grid.Column>
              <Popup
                content={anonimity.userEditedExtractedData}
                on='click'
                pinned
                trigger={<Button secondary content="User edited data" />}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </>
  )
}