import { FunctionComponent } from "react";
import { Spinner } from "../../../../Common/spinner/Spinner";
import { CriiptoHeader } from "./CriiptoHeader";

export const CriiptoLoadingPage: FunctionComponent = () => {
  return (
    <>
      <CriiptoHeader />
      <Spinner />
    </>
  )
}