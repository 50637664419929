import MitekHeader from "./MitekHeader";
import useInterval from "../../../../Services/useInterval";
import { Button, Segment } from "semantic-ui-react";
import { EMitekCheckStatus } from "../../../../Enums/EMitekCheckStatus";
import { StateValue } from "../../../Context";
import { getAgentHandoffStatus } from "../../../../Services/Backend";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  ctxId: string;
  callback: (status: EMitekCheckStatus) => void;
}

export const MitekCheckAgentHandoff: React.FC<IProps> = (({ ctxId, callback }) => {
  const { t } = useTranslation();
  const state = useContext(StateValue);

  const [count, setCount] = useState(0);

  useInterval(() => {
    setCount(count + 1);
    if (count < 120) {
      getAgentHandoffStatus(ctxId).then(res => {
        callback(res.data.status)
      })
    }
  }, 15000);
  return (
    <>
      <MitekHeader />
      <Segment className="mitek" basic style={{ height: "90%", width: "100%", margin: 0 }}>
        {sessionStorage.getItem("handoff") === null ? (
          <>
            <h1>{t("Mitek.Agent_Assist.Header")}</h1>
            <h1>{t("Mitek.Agent_Assist.SubHeader")}</h1>
            <h1>{t("Mitek.Agent_Assist.Explanation")}</h1>
          </>
        ) : (
          <>
            <h1>{t("Mitek.Agent_Assist.Header_Handoff")}</h1>
            <h2>{t("Mitek.Agent_Assist.SubHeader")}</h2>
            <h3>{t("Mitek.Agent_Assist.Explanation_Handoff")}</h3>
          </>
        )}
      </Segment>
      <Segment basic className={`service-item-button-container${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""} mt-0`}>
        <Button color="green" floated="right" disabled loading>{t("Next")}</Button>
      </Segment>
    </>
  )
})