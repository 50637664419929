import { AnonimityRevocationDetailModal } from "../../../Components/secure/anonimityRevocation/AnonimityRevocationDetailModal";
import { AnonimityRevocationValidation } from "./AnonimityRevocation.Validation";
import { Button, Container, Divider, Form, Grid, Header, Icon, Segment } from "semantic-ui-react";
import { DataView, IDataViewColumn } from "../../../Components/Common/dataview/Dataview";
import { Field, Formik } from "formik";
import { GetAnonimityRevocationFormValues, IAnonimityRevocation } from "../../../models/admin/IAnonimityRevocation";
import { RootStoreContext } from "../../../stores/RootStore"
import { Spinner } from "../../../Components/Common/spinner/Spinner";
import { TextInput } from "../../../Components/Common/TextInput";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next";

export const AnonimityRevocation = observer(() => {
  const { t } = useTranslation();
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  const { loadingRevocations, anonimityRevocations, GetAnonimityRevocation } = rootStore.adminStore;
  const [formValues, setFormValues] = useState(new GetAnonimityRevocationFormValues());

  const handleSubmit = async (values: GetAnonimityRevocationFormValues) => {
    setFormValues(values);
    await GetAnonimityRevocation(values);
  }

  const getColumns = (): IDataViewColumn[] => {
    return [
      { headerName: t("Dataview.Firstname"), field: "firstName", sortable: false, flex: 1, filter: false },
      { headerName: t("Dataview.Lastname"), field: "lastName", sortable: false, flex: 1, filter: false },
      { headerName: t("Dataview.DocumentNumber"), field: "documentNumber", sortable: false, flex: 1, filter: false },
      { headerName: t("Dataview.AccountAddress"), field: "accountAddress", sortable: false, flex: 1, filter: false },
      { headerName: t("Dataview.Actions"), cellRenderer: "actions", sortable: false, flex: 1 },
    ]
  }

  const renderCellAction = (tableData: any) => {
    const anonimity: IAnonimityRevocation = toJS(tableData.data);
    return (
      <div>
        <Button className="icon" compact primary
          onClick={() => openModal({ content: <AnonimityRevocationDetailModal anonimity={anonimity} /> })} >
          <Icon name="info" />
        </Button>
      </div>
    )
  }

  return (
    <>
      <Header as="h1">Anonimity revocation</Header>
      <Divider />
      <Container>
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={AnonimityRevocationValidation}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Segment padded className='bg-lightest-grey'>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h2">
                        When multiple fields are filled in, the results shown match all filled-in fields.
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  {!!formik.errors && formik.errors.atLeastOne && (
                    <Grid.Row textAlign="center">
                      <Grid.Column>
                        <>
                          <Header as='h3' color="red">
                            At least one field must be filled in!
                          </Header>
                        </>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <Field
                        component={TextInput}
                        name="firstName"
                        label="First name"
                      />
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <Field
                        component={TextInput}
                        name="lastName"
                        label="Last name"
                      />
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <Field
                        component={TextInput}
                        name="documentNumber"
                        label="Document number"
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Field
                        component={TextInput}
                        name="accountAddress"
                        label="Account address"
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Field
                        component={TextInput}
                        name="idCredPub"
                        label="idCredPub"
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row textAlign="right">
                    <Grid.Column>
                      <Button type="submit" primary loading={loadingRevocations} disabled={loadingRevocations || !!formik.errors.atLeastOne}>
                        {t("Button.Submit")}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Form>
          )}
        </Formik>

        <Divider />

        <Grid>
          {loadingRevocations ? <Spinner /> :
            <Grid.Row>
              {/* TODO: Excel export? */}
              <Grid.Column>
                <DataView
                  actionsRenderer={(data: any) => renderCellAction(data)}
                  columns={getColumns()}
                  data={anonimityRevocations}
                />
              </Grid.Column>
            </Grid.Row>
          }
        </Grid>
      </Container>
    </>
  )
})