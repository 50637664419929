import { Redirect } from "react-router-dom";
import { RootStoreContext } from "../../stores/RootStore"
import { useContext } from "react"

export const Signout = () => {
  const rootStore = useContext(RootStoreContext);
  const { authorizationStore } = rootStore;
  authorizationStore.removeAuthToken();

  return <Redirect to="/authentication/login" />
}