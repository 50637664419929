import { FunctionComponent, useContext } from "react";
import { ReactComponent as SuccessSvg } from "../../Assets/SVG/Start.svg";
import { StateValue } from "../Context";
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from "react-i18next";

type IProps = {
  header: string;
  text: string;
}

export const AnimationSuccess: FunctionComponent<IProps> = (props: IProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation();
  const background = encodeURIComponent(renderToStaticMarkup(<SuccessSvg fill={state.org.theme.themeColor} />));
  return (
    <div style={{ width: "100%", height: "100%", background: `url("data:image/svg+xml,${background}")`, backgroundSize: "clamp(20%, 60%, 400px", backgroundPosition: "right bottom", backgroundRepeat: "no-repeat" }}>
      <h1 className={`service-item-header${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""}`}>{t(props.header)}</h1>
      <h2 className={`service-item-subheader${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""}`}>{t(props.text)}</h2>
    </div>
  )
}