import { FunctionComponent } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useTranslation } from "react-i18next";

interface IProps {
  text: any;
  header: string;
  svgComponent?: any;
}

export const AnimationCheck: FunctionComponent<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const background = encodeURIComponent(renderToStaticMarkup(props.svgComponent));

  return (
    <>
      <div style={{ width: "100%", height: "100%", background: `url("data:image/svg+xml,${background}")`, backgroundSize: "clamp(20%, 60%, 400px", backgroundPosition: "right bottom", backgroundRepeat: "no-repeat" }}>
        <h1 className="service-item-header">{t(props.header)}</h1>
        <h2 className="service-item-subheader">{t(props.text)}</h2>
      </div>
    </>
  )
};
