import "./DesktopLayout.css";
import "./Helper.css";
import { Header, Segment, Image, Message } from 'semantic-ui-react'
import { StateValue } from '../Components/Context';
import { useContext } from 'react'
import { useTranslation } from "react-i18next";

interface IProps {
  children: any,
  sidebar: any,
  title?: string,
  smallScreen?: boolean,
  useLayout?: boolean,
  clientClass?: string,
}

const DesktopLayout = (props: IProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation();
  if (!props.useLayout) {
    return (props.children)
  } else {
    return (
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", alignItems: "center", alignContent: "center", justifyContent: "center", width: "100vw", height: "100vh" }}>
        <div className="main">
          {props.smallScreen ? null : (<div style={{ flex: 0.25, alignItems: "stretch", display: "flex" }} className={`sidebar-container${props.clientClass ? "-" + props.clientClass : ""}`}> {props.sidebar} </div>)}
          <div style={{ flex: props.smallScreen ? 1 : 0.7 }} className={`ui fluid container segments content-container${props.clientClass ? "-" + props.clientClass : ""} mt-0 mb-0`}>
            <Segment>
              <Header as="h3" className={`content-header-container${props.clientClass ? "-" + props.clientClass : ""}`}>
                <Image size="medium" src={state.org.theme.logo} style={{ width: "auto", maxHeight: "1.28571429em" }} />
                <span title={`Version: ${process.env.REACT_APP_VERSION}`} />
                {props.title}
              </Header>
            </Segment>

            <Segment className={`service-item-container${props.clientClass ? "-" + props.clientClass : ""}`}>
              {props.children}
            </Segment>
            {
              state.current === -1 && (
                <Message positive attached="bottom">
                  <Message.Header>
                    {t("Start.Please_Note")}
                  </Message.Header>
                  <p>
                    {t("Start.By_Continuing")} <a href="https://3092d62c-0e21-420a-af63-f4de54b0e502.filesusr.com/ugd/7ac32a_474e580432944b7f89dfd1a6c420b19d.pdf" target="_blank">{t("Start.Terms")}</a> {t("Start.And_The")} <a href="https://3092d62c-0e21-420a-af63-f4de54b0e502.filesusr.com/ugd/7ac32a_2ed44c436a354647a30107c8b8a326ae.pdf" target="_blank">{t("Start.Privacy")}</a> {t("Start.Policy")}
                  </p>
                </Message>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

export default DesktopLayout
