import { FieldProps, getIn } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, FormFieldProps, Icon, Label, Popup, } from "semantic-ui-react";

interface IProps extends FieldProps<string, HTMLInputElement>, FormFieldProps { }

export const PasswordInput = (props: IProps) => {
  const { t } = useTranslation();
  const {
    field,
    form: { touched, errors, handleChange },
    label,
    placeholder,
    required,
    tooltip,
    errorMarginTop,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleToggle = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Form.Field type={showPassword ? "text" : "password"}>
      <label>
        {label}
        {required && <> *</>}
        {tooltip && (
          <Popup
            content={tooltip}
            trigger={
              <Button type="button" className="tooltip" icon="info circle" />
            }
          />
        )}
      </label>
      <Form.Input
        {...field}
        placeholder={placeholder}
        type={showPassword ? "text" : "password"}
        onBlur={field.onBlur}
        onChange={handleChange}
        icon={
          <Icon
            name={showPassword ? "eye slash" : "eye"}
            link
            onClick={handleToggle}
          />
        }
      />

      {getIn(touched, field.name) && getIn(errors, field.name) && (
        <Label
          size="large"
          color="red"
          style={{ marginTop: errorMarginTop }}
          icon="times circle"
          content={t(getIn(errors, field.name))}
        />
      )}
    </Form.Field>
  );
};
