import { Segment, Button } from 'semantic-ui-react'
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type MitekCheckSubmitExplanationProps = {
  props: any,
  themeColor: string,
  frontImage: string,
  backImage?: string,
  postButton: any
}

export default function MitekCheckSubmitExplanation(props: MitekCheckSubmitExplanationProps) {

  const [currentPage, setCurrentPage] = useState(0);

  function isPortrait(img: any) {
    var w = img?.naturalWidth || img?.width,
      h = img?.naturalHeight || img?.height;
    return (h > w);
  }

  const { t } = useTranslation();

  const renderView = () => {
    switch (currentPage) {
      case 0:
        return (
          <Segment className="mitek" basic style={{ display: "flex", margin: 0, paddingTop: 0, flexDirection: "column", alignContent: "center", height: "100%" }}>
            <div>
              <h2 style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>Does this look right?</h2>
              <h3 style={{ marginTop: 0 }}>{t("Mitek.Submit.Submit_Documents")}</h3>
            </div>
            <Segment placeholder style={{ display: "flex", flexDirection: "column", alignItems: "center", alignContent: "center", flex: 1, padding: 0 }}>
              <div style={{ paddingTop: "1em", display: "flex", flexDirection: "column", alignItems: "center", alignContent: "center", flexGrow: 1, flexShrink: 1, flexBasis: "100%", maxHeight: "100%" }}>
                <div style={{ flex: 1, maxHeight: "calc(100% - 3rem)", alignSelf: "center" }}>
                  <img src={`data:image/png;base64,${props.frontImage}`} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "3rem" }}>
                  <h2>FRONT</h2>
                </div>
              </div>
            </Segment>
          </Segment>
        );

      case 1:
        return (
          <Segment className="mitek" basic style={{ display: "flex", margin: 0, paddingTop: 0, flexDirection: "column", alignContent: "center", height: "100%" }}>
            <div>
              <h2 style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>Does this look right?</h2>
              <h3 style={{ marginTop: 0 }}>{t("Mitek.Submit.Submit_Documents")}</h3>
            </div>
            <Segment placeholder style={{ display: "flex", flexDirection: "column", alignItems: "center", alignContent: "center", flex: 1, padding: 0 }}>
              <div style={{ paddingTop: "1em", display: "flex", flexDirection: "column", alignItems: "center", alignContent: "center", flexGrow: 1, flexShrink: 1, flexBasis: "100%", maxHeight: "100%" }}>
                <div style={{ flex: 1, maxHeight: "calc(100% - 3rem)", alignSelf: "center" }}>
                  <img src={`data:image/png;base64,${props.backImage}`} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "3rem" }}>
                  <h2>BACK</h2>
                </div>
              </div>
            </Segment>
          </Segment>
        )
    }
  }

  const getButtons = () => {
    switch (currentPage) {
      case 0:
        if (props.backImage) {
          return (
            <>
              <span>
                <Button style={{ marginRight: "1em" }} basic onClick={() => window.location.reload()}>Retry</Button>
              </span>
              <span>
                <Button basic color="green" onClick={() => setCurrentPage(1)}>Next</Button>
              </span>
            </>
          )
        }
        return (
          <>
            <span>
              <Button style={{ marginRight: "1em" }} basic onClick={() => window.location.reload()}>Retry</Button>
            </span>
            <span>
              {props.postButton}
            </span>
          </>
        );
      case 1:
        return (
          <>
            <span>
              <Button basic style={{ marginRight: "1em" }} onClick={() => window.location.reload()}>Retry</Button>
            </span>
            <span>
              <Button basic onClick={() => setCurrentPage(0)}>Back</Button>
              {props.postButton}
            </span>
          </>
        );
    }
  }

  return (<>
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={`${currentPage}-trans`}
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
        classNames="fade"
      >
        <div className="transition-container">
          {renderView()}
        </div>
      </CSSTransition>
    </SwitchTransition>

    <Segment className="mitek mt-0" style={{ height: "10%", margin: 0, width: "100vw", display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
      {getButtons()}
    </Segment>
  </>
  )
}
