import * as Yup from 'yup';
import axios from 'axios';
import { AppendErrors, MitekMissingPropertiesDto } from '../../../../Types';
import { Button, Form as SemanticForm, Header, Segment, Image } from 'semantic-ui-react';
import { Field, Formik } from 'formik';
import { StateValue } from '../../../Context';
import { StyledProgressBar } from '../../../StyledComponents/StyledProgressbar';
import { TextInput } from '../../../Common/TextInput';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type IProps = {
  data: MitekMissingPropertiesDto;
  callback: () => void;
  missingProperties: string[];
};

export const MitekCheckAppendMissingProperties = (props: IProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const expiredRequired = useMemo(() => localStorage.getItem("DOCUMENT_TYPE") !== "DRIVERSLICENSE", []);

  const setErrorsState = (res: AppendErrors[]) => {
    const tempE: any = {};
    res.forEach((error: AppendErrors) => {
      tempE[error.fieldName as any] = error.fieldName + t("Mitek.Append_Missing.Error_1") + error.allowedEdits + t("Mitek.Append_Missing.Error_2") + error.actualEdits + ".";
    });
    return (tempE);
  }

  var validationSchema = Yup.object().shape({
    expiredRequired: Yup.bool().default(expiredRequired),
    personalGovID: Yup.string()
      .label('Mitek.Append_Missing.personalGovID'),
    issuingAuthority: Yup.string()
      .label('Mitek.Append_Missing.IssuingAuthority'),
    documentNumber: Yup.string()
      .label('Mitek.Append_Missing.DocumentNumber'),
    givenNames: Yup.string()
      .label('Mitek.Append_Missing.GivenNames')
      .required(`${t('Mitek.Append_Missing.GivenNames')} ${t('General_UI.Is_Required')}`),
    surname: Yup.string()
      .label('Mitek.Append_Missing.Surname')
      .required(`${t('Mitek.Append_Missing.Surname')} ${t('General_UI.Is_Required')}`),
    dateOfIssue: Yup.date()
      .label('Mitek.Append_Missing.DateOfIssue'),
    dateOfExpiry: Yup.date()
      .label('Mitek.Append_Missing.DateOfExpiry'),
  });

  const handleSubmit = (data: any, formProps: any) => {
    setIsSubmitting(true);
    axios.post(
      '/api/mitekCheck/appendmissingproperties',
      { // Currently only the 'givenNames' & 'surname' are used in the backend. All others are discarded
        ctxId: state.ctxId,
        documentNumber: data.documentNumber,
        personalGovID: data.personalGovID ?? "",
        givenNames: data.givenNames,
        surname: data.surname,
        issuingAuthority: data.issuingAuthority,
        dateOfIssue: data.dateOfIssue != "" ? new Date(data.dateOfIssue).toISOString() : "",
        dateOfExpiry: data.dateOfExpiry != "" ? new Date(data.dateOfExpiry).toISOString() : "",
      }
    ).then(res => {
      if (res.status !== 200) {
        setIsError(true);
        setIsSubmitting(false);
      } else {
        if (res.data.errors != null && res.data.errors != undefined && res.data.errors.length > 0) {
          formProps.setErrors(res.data.errors.length > 0 ? setErrorsState(res.data.errors) : undefined);
          setIsError(true);
        } else {
          setIsSubmitting(false);
          sessionStorage.setItem("MitekCheckFinished", "true");
          props.callback();
        }
      }
      setIsSubmitting(false);
    }).catch(err => {
      setIsError(true);
      setIsSubmitting(false);
    })
  };

  const centerField = (id: string) => {
    const element = document.getElementById(id);
    setTimeout(() => {
      element!.scrollIntoView({ behavior: 'smooth', inline: "center", block: "start" });
    }, 200);
  }

  return (
    <div className={`mobile-content-container`}>
      <Segment className={`mobile-content-header-container`} style={{ marginBottom: 0 }}>
        <StyledProgressBar percent={(state.current / (state.flow.length)) * 100} attached="top" color={state.org.theme.themeColor} className="" />
        <Header as="h3" className="mt-0">
          <Image src={state.org.theme.logo} style={{ width: "100px" }} /> {t("Mitek.Title")}
        </Header>
      </Segment>
      <div style={{ flex: 1 }} className={`mobile-service-item-container`}>
        <span className='mb-2'>
          <h1 className={`service-item-header${state.org.theme.clientClass ? '-' + state.org.theme.clientClass : ''} mb-1 mt-2`}>
            {t('Mitek.Append_Missing.Look_Right')}
          </h1>
          <h3 className={`service-item-subheader${state.org.theme.clientClass ? '-' + state.org.theme.clientClass : ''}`} style={isError ? { color: "red" } : {}}>
            {isError ? t("Mitek.Append_Missing.Only_Small_Edits") : t('Mitek.Append_Missing.Append_Edit')}
          </h3>
        </span>

        <Formik
          initialValues={{
            personalGovID: props.data.personalGovID ?? '',
            issuingAuthority: props.data.issuingAuthority ?? '',
            documentNumber: props.data.documentNumber ?? '',
            givenNames: props.data.givenNames ?? '',
            surname: props.data.surname ?? '',
            dateOfIssue: props.data.dateOfIssue ?? '',
            dateOfExpiry: props.data.dateOfExpiry ?? '',
          }}
          onSubmit={(data: any, formProps: any) => handleSubmit(data, formProps)}
          validationSchema={validationSchema}
          style={{ flex: 1 }}
        >
          {(formProps) => {
            return (
              <SemanticForm
                onSubmit={formProps.handleSubmit}
                style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: "space-between" }}
              >
                <span style={{ flex: 1, overflowY: "auto", overflowX: "hidden" }}>
                  <SemanticForm.Group onClick={() => centerField("group1")} id="group1" unstackable={formProps.values.givenNames.length < 20} widths="2" className='mb-4'>
                    <Field
                      component={TextInput}
                      id="givenNames"
                      label={t('Mitek.Append_Missing.GivenNames')}
                      name="givenNames"
                      type="text"
                      placeholder={t('Mitek.Append_Missing.GivenNames')}
                      required={props.missingProperties.includes('GivenNames')}
                      formInput={{ fluid: true }}
                    />

                    <Field
                      component={TextInput}
                      id="surname"
                      label={t('Mitek.Append_Missing.Surname')}
                      name="surname"
                      type="text"
                      placeholder={t('Mitek.Append_Missing.Surname')}
                      required={props.missingProperties.includes('Surname')}
                      formInput={{ fluid: true }}
                    />
                  </SemanticForm.Group>

                  <div onClick={() => centerField("personalGovID")} className='mb-4'>
                    <Field
                      readOnly
                      component={TextInput}
                      id="personalGovID"
                      label={t('Mitek.Append_Missing.PersonalGovID')}
                      name="personalGovID"
                      type="text"
                      placeholder={t('Mitek.Append_Missing.PersonalGovID')}
                    />
                  </div>

                  <div onClick={() => centerField("documentNumber")} className='mb-4'>
                    <Field
                      readOnly
                      component={TextInput}
                      id="documentNumber"
                      label={t('Mitek.Append_Missing.DocumentNumber')}
                      name="documentNumber"
                      type="text"
                      placeholder={t('Mitek.Append_Missing.DocumentNumber')}
                    />
                  </div>

                  <div onClick={() => centerField("issuingAuthority")} className='mb-4'>
                    <Field
                      readOnly
                      component={TextInput}
                      id="issuingAuthority"
                      label={t('Mitek.Append_Missing.IssuingAuthority')}
                      name="issuingAuthority"
                      type="text"
                      placeholder={t('Mitek.Append_Missing.IssuingAuthority')}
                    />
                  </div>

                  <SemanticForm.Group id="group2" onClick={() => centerField("group2")} unstackable widths={expiredRequired ? "2" : "equal"} className='mb-4'>
                    {
                      props.data.dateOfIssue !== undefined && (
                        <Field
                          readOnly
                          component={TextInput}
                          id="dateOfIssue"
                          label={t('Mitek.Append_Missing.DateOfIssue')}
                          name="dateOfIssue"
                          type="date"
                          placeholder={t('Mitek.Append_Missing.DateOfIssue')}
                          formInput={{ fluid: true }}
                        />
                      )
                    }

                    {
                      props.data.dateOfExpiry !== undefined && (
                        <Field
                          readOnly
                          component={TextInput}
                          id="dateOfExpiry"
                          label={t('Mitek.Append_Missing.DateOfExpiry')}
                          name="dateOfExpiry"
                          type="date"
                          placeholder={t('Mitek.Append_Missing.DateOfExpiry')}
                          formInput={{ fluid: true }}
                        />
                      )
                    }
                  </SemanticForm.Group>

                </span>

                <Segment basic className={`service-item-button-container${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""} mt-0`} style={{ width: "100%" }}>
                  <Button
                    floated="right"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    primary
                    color={isError ? 'red' : 'green'}
                    type="submit"
                  >
                    {isError
                      ? t('General_UI.Try_Again')
                      : t('General_UI.Submit')}
                  </Button>
                </Segment>

              </SemanticForm>
            )
          }}
        </Formik>
      </div>
    </div>
  );
};
