import { Button, Form, FormFieldProps, FormInputProps, Header, Icon, Label, LabelProps, Modal, PopupProps, StrictFormFieldProps } from 'semantic-ui-react'
import { Field, FieldProps, getIn } from "formik"
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface TextInputProps {
  warning?: LabelProps,
  tooltip?: PopupProps,
  formInput?: FormInputProps,
  formField?: FormFieldProps,
}

export interface IFormikFieldConfig extends Partial<FieldProps<string, HTMLInputElement>>, TextInputProps, Pick<StrictFormFieldProps, "error" | "disabled" | "required"> {
  component: any;
  [key: string]: any;
}

export const CustomField = (props: IFormikFieldConfig) => {
  return (
    <Field {...props} />
  )
}


{/*

  <CustomField
    component={TextInputNew}

    warning={{any semantic LabelProps or anything you'd want to apply to the Error/Warning Label}}
    formInput={{any semantic FormInputProps or anything you'd want to apply to the Form.Input}}
    tooltip={{any semantic PopupProps or anything you'd want to apply to the input field}}
    formField={{any semantic FormFieldProps or anything you'd want to apply to the Form.Field}}

    id="password"
    label={t("Login_Password")} // Shorthand to change label text
    name="password"
    type="password"
  />

*/}

export const TextInput = (props: IFormikFieldConfig) => {

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);


  return (
    <Form.Field error={props.error} required={props.required} {...props.formField}>
      <label>
        {props.label}
        {props.tooltip && (
          <Modal
            basic
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size='small'
            trigger={<Button style={{ border: "none", boxShadow: "none", paddingRight: 0, paddingBottom: "0.25em" }} compact size="small" basic type="button" icon={<Icon style={{ marginBottom: "1em" }} name="info circle" fitted />} />}
          >
            <Header icon>
              <Icon name='info circle' />
              {t("General_UI.Information")}
            </Header>
            <Modal.Content>
              <p>
                {props.tooltip?.content?.toString()}
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button color='green' inverted onClick={() => setOpen(false)}>
                {t("General_UI.Close")}
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </label>
      <Form.Input disabled={props.disabled} readOnly={props.readOnly} className="mt-1" id={props.id} {...props.formInput} {...props.inputProps} type={props.type} {...props.field} />
      {getIn(props.form!.touched, props.field!.name) && getIn(props.form!.errors, props.field!.name) && (
        <Label
          pointing="above"
          style={{ width: "100%", marginLeft: 0 }}
          size="large"
          color="red"
          icon="times circle"
          content={t(getIn(props.form!.errors, props.field!.name))}
          {...props.warning}
        />
      )}
    </Form.Field>
  )
}

