import { Button, Container, Divider, Form, Grid, Header, Segment, Table } from "semantic-ui-react";
import { DatePicker } from "../../Common/form/DatePicker";
import { EIssuanceReference, IIssuanceReferenceEnum, IssuanceReferenceEnums } from "../../../Enums/EIssuanceReference";
import { Field, Formik } from "formik";
import { GetBreakdownErrorStatisticsFormValues, IBreakdownErrorStatistics, IIssuanceReferenceColor, IssuanceReferenceColor } from "../../../models/errorStatistics/IBreakdownErrorStatistics";
import { RootStoreContext } from "../../../stores/RootStore";
import { Spinner } from "../../Common/spinner/Spinner";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IData {
  key: string;
  name: number;
  themeColor: string;
  amount: IDataCall[];
}

interface IDataCall {
  x: number;
  y: number;
}

export const ErrorStatisticsBreakdown = observer(() => {
  const { t } = useTranslation();
  const rootStore = useContext(RootStoreContext);
  const { loadingBreakdownStats, GetBreakdownErrorStatistics } = rootStore.errorStatisticsStore;
  const [stats, setStats] = useState<IBreakdownErrorStatistics>();
  const [formValues, setFormValues] = useState(new GetBreakdownErrorStatisticsFormValues());
  const [dataParsed, setDataParsed] = useState<IData[]>([]);
  const [issanceRefColor, setIssuanceRefColor] = useState<IIssuanceReferenceColor[]>([])
  const [issuanceReferenceEnums, setIssuanceReferenceEnums] = useState<IIssuanceReferenceEnum[]>([]);

  useEffect(() => {
    setIssuanceRefColor(IssuanceReferenceColor())
    setIssuanceReferenceEnums(IssuanceReferenceEnums());
  }, []);

  useEffect(() => {
    if (issanceRefColor.length > 0) loadErrorStats(formValues);
  }, [issanceRefColor])

  const loadErrorStats = async (values: GetBreakdownErrorStatisticsFormValues) => {
    var res = await GetBreakdownErrorStatistics(values);
    if (res) {
      setStats(res);

      var parsed: IData[] = res.errorBreakdowns.map((x) => ({
        key: `${x.issuanceReference}`,
        name: x.issuanceReference,
        themeColor: issanceRefColor[x.issuanceReference]?.color ?? "#ff0000",
        amount: x.issuanceErrors.map((i) => ({
          x: i.weekNumber,
          y: i.errorCount
        }))
      }));

      setDataParsed(parsed);
    }
  };

  const handleSubmit = (values: GetBreakdownErrorStatisticsFormValues) => {
    loadErrorStats(values)
  }

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Segment padded className="bg-lightest-grey">
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Header as="h3">The retrieved data will include the full selected startdate and enddate</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Field
                      component={DatePicker}
                      required
                      name="start"
                      label="StartDate"
                      activationDate={formik.values.start}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Field
                      component={DatePicker}
                      required
                      name="end"
                      label="EndDate"
                      activationDate={formik.values.end}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row textAlign="right">
                  <Grid.Column>
                    <Button type="submit" primary>
                      {t("Button.Submit")}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Form>
        )}
      </Formik>
      <Divider />
      {loadingBreakdownStats ? <Spinner /> : (
        <Grid>
          <Grid.Row>
            {/* <Grid.Column width={13}>
              <div className="datavis">
                <FlexibleWidthXYPlot xType="ordinal" height={500} stackBy="y">
                  <VerticalGridLines tickTotal={dataParsed?.length ?? 0} />
                  <HorizontalGridLines tickTotal={dataParsed?.length ?? 0} />
                  <XAxis
                    tickSize={3}
                    tickTotal={16}
                    title="Week nr."
                  />
                  <YAxis tickSize={3} title="Errors per week" />
                  {dataParsed?.map((x, index) => (
                    <VerticalBarSeries
                      key={`${x.name}-${index}`}
                      width={1}
                      barWidth={1}
                      data={x.amount}
                      color={x.themeColor}
                    />
                  ))}
                </FlexibleWidthXYPlot>
              </div>
            </Grid.Column>
            <Grid.Column width={3} style={{ textAlign: "left", display: "flex", justifyContent: "center", alignItem: "center", flexFlow: "column" }}>
              <Header as="h3">Error type</Header>
              <DiscreteColorLegend
                height={300}
                width={150}
                items={dataParsed?.filter((x, i, a) => a.indexOf(x) == i).map((x, index) => ({ title: `${x.name}`, color: x.themeColor, key: `${x.name}-${x.themeColor}=${index}` })) ?? ([] as any)} />
            </Grid.Column> */}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={10}>
              <Segment>
                <Table collapsing celled compact>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Error type</Table.HeaderCell>
                      <Table.HeaderCell>{t("Table.Description")}</Table.HeaderCell>
                      <Table.HeaderCell>Color</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {issuanceReferenceEnums.filter((issuanceRef) => issuanceRef.value != EIssuanceReference.None).map((issuanceRef) => {
                      return (
                        <Table.Row>
                          <Table.Cell>{issuanceRef.value}</Table.Cell>
                          <Table.Cell>{t(`Issuance_Errors.Code.${issuanceRef.name}`)}</Table.Cell>
                          <Table.Cell>
                            <div style={{ minWidth: "10px", backgroundColor: issanceRefColor[issuanceRef.value]?.color ?? "#ff0000" }}>&nbsp;</div>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Container>
  )
})