import * as Yup from "yup";
import axios from "axios";
import { AnimationCheck } from "../../AnimationViews/AnimationCheck";
import { AnimationSuccess } from "../../AnimationViews/AnimationSuccess";
import { Button, Segment, Form as SemanticForm } from "semantic-ui-react";
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Field, Formik } from "formik";
import { FunctionComponent, useContext, useMemo, useState } from "react";
import { ReactComponent as CheckSvg } from "../../../Assets/SVG/Check.svg";
import { SelectDropdown } from "../../Common/SelectDropdown";
import { StateDispatch, StateValue } from "../../Context";
import { TextInput } from "../../Common/TextInput";
import { TimerButton } from "../../Common/Button/TimerButton";
import { isDesktop } from "react-device-detect";
import { readCookie } from "../../../Services/ReadWriteCookie";
import { useTranslation } from "react-i18next";
const { getData } = require('country-list');

export const CountryEmailComponent: FunctionComponent = () => {
  const { t } = useTranslation();
  const state = useContext(StateValue);
  const dispatch = useContext(StateDispatch);
  const [view, setView] = useState<"loading" | "success" | "error" | "form">(sessionStorage.getItem("COUNTRY_FAIL") === "true" ? "error" : "form");

  const countryData = useMemo(() => getData(), []);
  const countryOptions = useMemo(() => countryData.map((country: { code: any; name: any; }) => ({ key: country.code, value: country.code, text: country.name, flag: country.code.toLowerCase() })), [countryData]);

  const [error, setError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    // Email is no longer required
    // email: Yup.string()
    //   .email(t("CountryEmail.Email_Error"))
    //   .label('CountryEmail.Email_Address')
    //   .required(`${t('CountryEmail.Email_Address')} ${t('General_UI.Is_Required')}`),
    country: Yup.string()
      .label('CountryEmail.Country')
      .required(`${t('CountryEmail.Country')} ${t('General_UI.Is_Required')}`),
  });

  const postBackend = (data: any) => {
    setView("loading");
    setError(false);
    if (readCookie("ctxId") !== undefined) {
      axios.post("/api/frontend/startservice/countryemail", { countryCode: data.country, serviceId: 9, ctxId: state.ctxId }).then(res => {
        if (res.status === 200) {
          if (res.data.success) {
            // setView("success"); // We are skipping the success page and going directly to the next step
            localStorage.setItem("EMAIL", data.email);
            dispatch({ type: "setCurrent", data: state.current + 1 });
          } else {
            setView("error");
            sessionStorage.setItem("COUNTRY_FAIL", "true");
          }
        } else {
          setView("form");
          setError(true);
        }
      }).catch(err => {
        setError(true);
        setView("form");
      });
    }
  }

  const onSuccess = () => {
    setView("form");
    setTimeout(() => dispatch({ type: "setCurrent", data: state.current + 1 }), 700);
  }

  const renderView = () => {
    switch (view) {
      case "form":
        return (
          <>
            <Formik
              initialValues={{
                country: "",
                email: "",
              }}
              onSubmit={postBackend}
              validationSchema={validationSchema}
              style={{ flex: 1 }}
            >
              {(formProps) => (
                <SemanticForm
                  onSubmit={formProps.handleSubmit}
                  style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: "space-between" }}
                >
                  <span style={{ flex: 1, overflowY: isDesktop ? "hidden" : "auto" }}>
                    {error && <h1 className="service-item-header">{t('General_UI.Something_Went_Wrong')}</h1>}
                    {/* <Field
                      component={TextInput}
                      id="email"
                      label={t('CountryEmail.Email_Address')}
                      name="email"
                      type="email"
                      tooltip={{ content: t('CountryEmail.Email_Info'), position: 'top' }}
                      placeholder={t('CountryEmail.Email_Address')}
                      required
                    /> */}

                    <Field
                      component={SelectDropdown}
                      options={countryOptions}
                      id="country"
                      search
                      tooltip={{ content: t('CountryEmail.Country_Info'), position: 'top' }}
                      label={t('CountryEmail.Country')}
                      name="country"
                      placeholder={t('CountryEmail.Country')}
                      required
                    />
                  </span>
                  <Segment basic className={`service-item-button-container mt-0`} style={{ width: "100%" }}>
                    <Button
                      floated="right"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      color={error ? 'red' : 'green'}
                      type="submit"
                    >
                      {error
                        ? t('General_UI.Try_Again')
                        : t('General_UI.Submit')}
                    </Button>
                  </Segment>

                </SemanticForm>
              )}
            </Formik>
          </>

        )
      case "loading":
        return (
          <>
            <span style={{ height: "calc(100% - 5em)" }}>
              <AnimationCheck header="General_UI.Verifying" text="General_UI.One_Moment" key={`${view}-check`} svgComponent={<CheckSvg fill={state.org.theme.themeColor} />} />
            </span>
            <Segment basic className={`service-item-button-container${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""} mt-0 `} >
              <Button color="green" floated="right" type="submit" loading disabled>
                {t("General_UI.Next")}
              </Button>
            </Segment>
          </>)
      case "success":
        return (<>
          <span style={{ height: "calc(100% - 5em)" }}>
            <AnimationSuccess header="General_UI.Great" text="General_UI.Correct" key={`${view}-success`} />
          </span>
          <Segment basic className={`service-item-button-container${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""} mt-0`}>
            {view === "success" ?
              <TimerButton callback={onSuccess} /> :
              <Button color="green" floated="right" type="submit" onClick={() => onSuccess()} loading={view === "checking"} disabled={error || view === "loading"}>
                {t("General_UI.Next")}
              </Button>
            }
          </Segment>
        </>)
      case "error":
        return (
          <>
            <span style={{ height: "calc(100% - 5em)" }}>
              <h1 className={`service-item-header`}>{t("CountryEmail.Error_Header")}</h1>
              <h2 className={`service-item-subheader`}>{t("CountryEmail.Error_SubHeader")}</h2>
              <br />
              <h3 className={`service-item-explanation`}>{t("CountryEmail.Error_Explanation")}</h3>
            </span>
            <Segment basic className={`service-item-button-container${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""} mt-0`} >
              <Button color="green" floated="right" type="submit" disabled>
                {t("General_UI.Next")}
              </Button>
            </Segment>
          </>
        )
    }
  }

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${view}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="transition-container">
            {renderView()}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </>
  );
};
