import { EIssuanceReference, IIssuanceReferenceEnum, IssuanceReferenceEnums } from "../../../Enums/EIssuanceReference";
import { Grid, Header, Table } from "semantic-ui-react";
import { IGeneralErrorStatistics } from "../../../models/errorStatistics/IGeneralErrorStatistics";
import { RootStoreContext } from "../../../stores/RootStore";
import { Spinner } from "../../Common/spinner/Spinner";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const GeneralErrorStatistics = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { loadingGeneral, GetGeneralErrorStatistics } = rootStore.errorStatisticsStore;
  const [issuanceReferenceEnums, setIssuanceReferenceEnums] = useState<IIssuanceReferenceEnum[]>([]);
  const [generalErrors, setGeneralErrors] = useState<IGeneralErrorStatistics>();

  useEffect(() => {
    const fetchGeneralStatistics = async () => {
      setGeneralErrors(await GetGeneralErrorStatistics());
    }
    setIssuanceReferenceEnums(IssuanceReferenceEnums());
    fetchGeneralStatistics();
  }, []);

  return (
    <>
      <Grid>
        <Grid.Row>
          {loadingGeneral ? <Spinner /> : (
            <Grid.Column width={16}>
              <Header as="h3">{t("Statistics.Cumulative_Error_Last_2000")}</Header>
              <Table collapsing celled padded compact>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Error type</Table.HeaderCell>
                    <Table.HeaderCell>{t("Table.Description")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("Table.Amount")}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {issuanceReferenceEnums.filter((issuance) => issuance.value != EIssuanceReference.None).map((issuance, index) => (
                    <Table.Row key={`${index}-${issuance.value}`}>
                      <Table.Cell>{issuance.value}</Table.Cell>
                      <Table.Cell>{t(`Issuance_Errors.Code.${issuance.name}`)}</Table.Cell>
                      <Table.Cell textAlign="center">{generalErrors?.issuanceErrorCounts[issuance.value] ?? "0"}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </>
  )
})