import Backend from 'i18next-http-backend';
import i18n from 'i18next';
import { enGB } from "date-fns/locale";
import { format as formatDate, isDate } from "date-fns";
import { initReactI18next } from 'react-i18next';
import { setLocale } from 'yup';

const locales: any = { enGB }; // Used to look up the required locale for date formatting

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(Backend)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    react: {
      useSuspense: false,
    },
    lng: 'en-GB',
    fallbackLng: 'en-GB',
    supportedLngs: ['en-GB'],
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value: any, format: any, lng: any) => {
        if (isDate(value)) {
          const locale = locales[lng];

          if (format === "date") return formatDate(value, "P", { locale })
          if (format === "dateTime") return formatDate(value, "Pp", { locale })

          return formatDate(value, format, { locale })
        } else {
          return value;
        }
      }
    }
  });


export const yupVal = {
  array: {
    max: (item: any) => i18n.t("Yup.Array.Max", item),
    min: (item: any) => i18n.t("Yup.Array.Min", item),
  },
  date: {
    max: (item: any) => i18n.t("Yup.Date.Max", item),
    min: (item: any) => i18n.t("Yup.Date.Min", item),
  },
  mixed: {
    default: (item: any) => i18n.t("Yup.Mixed.Default", item),
    defined: (item: any) => i18n.t("Yup.Mixed.Defined", item),
    notOneOf: (item: any) => i18n.t("Yup.Mixed.NotOneOf", item),
    oneOf: (item: any) => i18n.t("Yup.Mixed.OneOf", item),
    required: (item: any) => i18n.t("Yup.Mixed.Required", item),
  },
  number: {
    integer: (item: any) => i18n.t("Yup.Number.Integer", item),
    lessThan: (item: any) => i18n.t("Yup.Number.LessThan", item),
    max: (item: any) => i18n.t("Yup.Number.Max", item),
    min: (item: any) => i18n.t("Yup.Number.Min", item),
    moreThan: (item: any) => i18n.t("Yup.Number.MoreThan", item),
    negative: (item: any) => i18n.t("Yup.Number.Negative", item),
    notEqual: (item: any) => i18n.t("Yup.Number.NotEqual", item),
    positive: (item: any) => i18n.t("Yup.Number.Positive", item),
  },
  object: {
    noUnknown: (item: any) => i18n.t("Yup.Object.NoUnknown", item),
  },
  string: {
    email: (item: any) => i18n.t("Yup.String.Email", item),
    length: (item: any) => i18n.t("Yup.String.Length", item),
    lowercase: (item: any) => i18n.t("Yup.String.Lowercase", item),
    matches: (item: any) => i18n.t("Yup.String.Matches", item),
    max: (item: any) => i18n.t("Yup.String.Max", item),
    min: (item: any) => i18n.t("Yup.String.Min", item),
    trim: (item: any) => i18n.t("Yup.String.Trim", item),
    uppercase: (item: any) => i18n.t("Yup.String.Uppercase", item),
    url: (item: any) => i18n.t("Yup.String.Url", item),
  },
};

setLocale(yupVal);

export default i18n;