import MitekHeader from './MitekHeader';
import { AnimationSuccess } from '../../../AnimationViews/AnimationSuccess';
import { Segment, Button } from 'semantic-ui-react';
import { state } from '../../../Context';
import { useTranslation } from 'react-i18next';

type IProps = {
  onSuccess: () => void;
  props?: any;
  state: state | undefined;
}

const MitekSuccess = (props: IProps) => {

  const { t } = useTranslation();

  return (
    <>
      <MitekHeader />
      <Segment className="mitek" basic style={{ height: "calc(100% - 5em - 4em)", width: "100%", margin: 0, paddingBottom: 0 }}>
        <AnimationSuccess header={t("General_UI.Great")} text={`${t("Mitek.Loading.Success")}${props.props.match.params.handoff !== undefined ? t("Mitek.Loading.Check_Computer") : "."}`} key={`${props.state?.view}-success`} />
      </Segment>
      <Segment className="service-item-button-container mitek mt-0" style={{ height: "10%", margin: 0, width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
        <Button color="green" floated="right" onClick={() => props.onSuccess()} disabled={props.props.match.params.handoff !== undefined}>
          {t("General_UI.Next")}
        </Button>
      </Segment>
    </>
  )
};

export default MitekSuccess;
