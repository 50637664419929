import { Button, Container, Divider, Form, Grid, Header, Segment, Table } from "semantic-ui-react"
import { EIssuanceReference, IIssuanceReferenceEnum, IssuanceReferenceEnums } from "../../../Enums/EIssuanceReference";
import { Field, Formik } from "formik";
import { GetIssuanceReferenceFormValues, IIsuanceReference } from "../../../models/issuance/IIssuanceReference";
import { IssuanceReferenceValidationSchema } from "./IssuanceReference.Validation";
import { RootStoreContext } from "../../../stores/RootStore"
import { Spinner } from "../../../Components/Common/spinner/Spinner";
import { TextInput } from "../../../Components/Common/TextInput";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";

export const IssuanceReference = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { loadingIssuance, getErrorForIssuance } = rootStore.dtsManagerStore;
  const [formValues, setFormValues] = useState(new GetIssuanceReferenceFormValues())
  const [issuanceReference, setIssuanceReference] = useState<IIsuanceReference>();
  const [issuanceReferenceEnums, setIssuanceReferenceEnums] = useState<IIssuanceReferenceEnum[]>([]);

  useEffect(() => {
    setIssuanceReferenceEnums(IssuanceReferenceEnums());
  }, [])

  const handleSubmit = async (values: GetIssuanceReferenceFormValues) => {
    setFormValues(values);
    let response = await getErrorForIssuance(values)
    setIssuanceReference(response);
  }

  return (
    <>
      <Header as="h1">Issuance reference</Header>
      <Divider />
      <Container>
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={IssuanceReferenceValidationSchema}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Segment padded className='bg-lightest-grey'>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Field
                        component={TextInput}
                        name="issuanceReference"
                        label="Issuance reference"
                        required
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row textAlign="right">
                    <Grid.Column>
                      <Button type="submit" primary loading={loadingIssuance} disabled={loadingIssuance}>
                        {t("Button.Submit")}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Form>
          )}
        </Formik>
        <Divider />
      </Container>

      <Grid>
        <Grid.Row>
          <Grid.Column width={10}>
            {loadingIssuance
              ? <Spinner />
              : !issuanceReference || !issuanceReference.dataFound
                ? <h2>{t("Issuance_Errors.No_Data")}</h2>
                : (
                  <div>
                    <h2>CtxId: {issuanceReference.ctxId}</h2>
                    <h3>{issuanceReference.sendToAgent ? t("Issuance_Errors.Send_To_Agent") : t("Issuance_Errors.Send_To_Agent_Not")}</h3>
                    <h3>
                      {t("Issuance_Errors.Message")}: <strong>{t(`Issuance_Errors.Code.${EIssuanceReference[issuanceReference.errorType]}`)}</strong>
                    </h3>
                    <p>
                      <span>
                        <strong>{t("Issuance_Errors.Type")}:</strong> {issuanceReference.errorType}
                      </span>
                    </p>
                    {issuanceReference.agentReasons.length > 0 && (
                      <>
                        <p>Agent reasons:</p>
                        <ul>
                          {issuanceReference.agentReasons.map((reason) => (
                            <li>{reason}</li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                )
            }
            <Divider />
          </Grid.Column>
          <Grid.Column width={6}>
            <Segment>
              <Header as="h2">Error types</Header>
              <Table collapsing celled compact>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Error type</Table.HeaderCell>
                    <Table.HeaderCell>{t("Table.Description")}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {issuanceReferenceEnums.filter((issuanceRef) => issuanceRef.value != EIssuanceReference.None).map((issuanceRef) => {
                    return (
                      <Table.Row>
                        <Table.Cell>{issuanceRef.value}</Table.Cell>
                        <Table.Cell>{t(`Issuance_Errors.Code.${issuanceRef.name}`)}</Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
})