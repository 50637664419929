import "../App.css";
import "semantic-ui-css/semantic.min.css";
import DesktopLayout from "../Layouts/DesktopLayout";
import MobileLayout from "../Layouts/MobileLayout";
import logo from '../Assets/Logo.webp';
import { FormikErrors } from "formik";
import { IdentityServices } from "../IdentityServices";
import { Start } from "./Views/Start";
import { Step } from "semantic-ui-react";
import { Success } from "./Views/Success";
import { SuccessMobile } from "./Views/SuccessMobile";
import { useEffect, useReducer, useState } from "react";
import { deleteCookie, setCookie } from "../Services/ReadWriteCookie";
import { emptyCriiptoCookies, getFlowByctxId } from "../Services/Backend";
import { isDesktop, isIPad13, isMobile, isTablet } from "react-device-detect";
import { reducer, initState, StateDispatch, StateValue } from "./Context";
import { useLocation } from "react-router-dom";
import { useScreenSize } from "../Services/useScreenSize";
import { useTranslation } from "react-i18next";
import { EService } from "../Enums/EService";

type MainComponentProps = {
  match?: any;
  history?: any;
  isPortait?: boolean,
  isLandscape?: boolean
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const MainComponent = (props: MainComponentProps) => {
  const { t } = useTranslation();
  const windowSizeListener = useScreenSize();
  const [state, dispatch] = useReducer(reducer, initState);
  const [errors, setErrors] = useState<FormikErrors<any> | undefined>(undefined);
  let query = useQuery();

  useEffect(() => {
    const clearCookie = async () => {
      await emptyCriiptoCookies();
    }
    clearCookie();
  }, [state.current])

  useEffect(() => {
    setErrors(undefined);
    if (parseInt(localStorage.getItem("current") ?? "0") < state.current) {
      setTimeout(() => dispatch({ type: "setView", data: "form" }), 900);
      localStorage.setItem("current", state.current.toString());
    }
  }, [state.current]);

  useEffect(() => {
    if (props.match.params.ctxId === undefined || props.match.params.ctxId.toString().length !== 32) {

      // Delete all information from a previous (presumably uncompleted) session.
      localStorage.clear();
      deleteCookie("ctxId");
      deleteCookie("invitationCode");
      deleteCookie("X-Api-Key");

      // Set all the variables for the new session.
      document.cookie = `X-Api-Key=${query.get("apiKey")};path=/;SameSite=Lax;`

      // Check if we come from a redirect, and set the appropiate localstorage values.
      if (query.get("handoff") !== "" && query.get("handoff") !== null) {
        sessionStorage.setItem("handoff", "true");
      }

      // Go to proper route
      props.history.push(`/${query.get("ctxId")}/${query.get("lang")}`);

    } else {
      getFlowByctxId(props.match.params.ctxId).then((res) => {
        const data = res.data;
        var current = -1;
        const lastFinished = data.lastFinished;

        if (lastFinished !== null && lastFinished !== undefined && lastFinished >= current) {
          // Prohibits users from refreshing page after handoff and continuing on phone.
          if (props.match.params.handoff != undefined) {
            sessionStorage.setItem("handoff", "true");
            // Concordium Mitek always at current = 1
            current = lastFinished;
          } else {
            current = lastFinished + 1;
          }
        }

        if (sessionStorage.getItem("handoff") === "true") {
          // We are looking for Criipto here, since that service has a fallthrough to Mitek
          let order = data.flowOrders.find((v) => v.serviceId === EService.CRIIPTO);
          // In case no order was found, check if there is an order with Mitek enabled
          if (order === null || order === undefined) order = data.flowOrders.find((v) => v.serviceId === EService.MITEKCHECK);
          current = Math.max(order?.order!, Math.max(lastFinished ?? 0, current));
        }

        dispatch({ type: "setLoading", data: true })
        setCookie("ctxId", props.match.params.ctxId, 100);
        if (localStorage.getItem("current") == null) {
          localStorage.setItem("current", current.toString())
        }
        dispatch({
          type: "init", data: {
            ...state,
            org: {
              ...state.org,
              theme: {
                logo: logo,
                themeColor: res.data.themeColor ?? "#0699FD",
              },
              name: res.data.clientName,
            },
            targetUrl: res.data.targetUrl,
            flow: res.data.flowOrders.sort((a, b) => a.order - b.order).map(s => { return { ...s, component: IdentityServices.find(v => v.Id === s.serviceId)! } }),
            flowDescription: res.data.flowDescription,
            ctxId: props.match.params.ctxId,
            lastFinishedAccordingToBackend: lastFinished,
            view: "form",
            current: current,
            loading: false,
            isAgentHandoff: res.data.isAgentHandoff ?? false,
            agentHandoffCompleted: res.data.agentHandoffCompleted ?? false
          }
        })
      })
    }
  }, [props.match.params.ctxId]);

  const renderView = (service: EService) => {
    const currentComponent = state.flow[state.current].component;

    switch (service) {
      case EService.MITEKCHECK:
        return <currentComponent.Component isLandscape={props.isLandscape} isPortrait={props.isPortait} props={props} /> // Mitek
      case EService.COUNTRYEMAILCHECK:
        return <currentComponent.Component />; // Country & Email
      case EService.CRIIPTO:
        return <currentComponent.Component isLandscape={props.isLandscape} isPortrait={props.isPortait} props={props} />; // Criipto or mitek
      default:
        return <></>;
    }
  };

  return !state.loading ? (

    <StateValue.Provider value={state}>
      <StateDispatch.Provider value={dispatch}>
        {
          (isDesktop && !isIPad13) && (
            <DesktopLayout title={t(state.flow[state.current]?.component?.Title ?? (state.current === state.flow.length ? "General_UI.Success" : "General_UI.Start"))} smallScreen={windowSizeListener.smallScreen} useLayout={state.flow[state.current]?.component?.UseLayout ?? true} sidebar={(
              <Step.Group
                vertical={!isMobile}
                fluid
                unstackable={isMobile}
                size="small"
              >
                {
                  state.flow.map((service, index) =>
                    service?.component?.SideBarComponent ? <service.component.SideBarComponent clientClass={state.org.theme.clientClass} mediumScreen={windowSizeListener.mediumScreen} key={`${service.serviceId}-SB`} order={index} current={state.current} /> : null
                  )
                }
              </Step.Group>)}>
              {state.current === -1 && <Start />}
              {state.current === state.flow.length && <Success />}
              {state.current !== -1 && state.current !== state.flow.length && renderView(state.flow[state.current].serviceId)}
            </DesktopLayout>
          )
        }
        {
          (isMobile || isTablet || isIPad13) && (
            <MobileLayout
              useLayout={state.flow[state.current]?.component?.UseLayout ?? true}
              title={t(state.flow[state.current]?.component?.Title
                ?? (state.current === state.flow.length ? "General_UI.Success" : "General_UI.Start"))}
            >
              {state.current === -1 && <Start />}
              {state.current === state.flow.length && <SuccessMobile />}
              {state.current !== -1 && state.current !== state.flow.length && renderView(state.flow[state.current].serviceId)}
            </MobileLayout>
          )
        }
      </StateDispatch.Provider>
    </StateValue.Provider>
  ) : null;
};

