import { useEffect, useRef, useState } from "react";
import { ECriiptoType } from "../../../../Enums/ECriiptoType";
import { useContainerDimensions } from "../../../../Hooks/useContainerDimensions";
import { Button, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { renderToStaticMarkup } from "react-dom/server";
import { ReactComponent as CheckSvg } from '../../../../Assets/SVG/MitekCheck.svg';
import { CriiptoMitIDSvg } from "../../Criipto/Components/Buttons/CriiptoMitIDSvg";
import { CriiptoHeader } from "./Mobile/CriiptoHeader";
import "./ButtonStyles.css";

interface IProps {
  isMobile?: boolean;
  criiptoType: ECriiptoType;
  initCriiptoBackend: () => void;
}

export const CriiptoServicePage = ({ isMobile = false, criiptoType, initCriiptoBackend }: IProps) => {
  const { t } = useTranslation();
  const mainDiv = useRef(null);
  const { width } = useContainerDimensions(mainDiv);
  const [backgroundSize, setBackgroundSize] = useState<string>("clamp(20%, 40%, 250px");

  useEffect(() => {
    let size = "clamp(20%, 40%, 250px"
    if (width > 600 && width < 700) size = "clamp(20%, 40%, 230px";
    else if (width > 500 && width <= 600) size = "clamp(20%, 40%, 150px)";
    else if (width <= 500) size = "clamp(20%, 40%, 100px)";
    setBackgroundSize(size);
  }, [width]);

  const background = encodeURIComponent(renderToStaticMarkup(<CheckSvg />));

  const renderButton = (type: ECriiptoType) => {
    switch (type) {
      case ECriiptoType.SEBANKID:
        return <Button floated="right" onClick={initCriiptoBackend}>Sign with Swedish BankID</Button>
      case ECriiptoType.NOBANKID_OIDC:
        return <Button floated="right" onClick={initCriiptoBackend}>Sign with Norwegian BankID</Button>
      case ECriiptoType.NOVIPPSLOGIN:
        return <Button floated="right" onClick={initCriiptoBackend}>Sign with Norwegian Vipps</Button>
      case ECriiptoType.FITUPAS:
        return <Button floated="right" onClick={initCriiptoBackend}>Sign with Finnish Trust Network</Button>
      case ECriiptoType.BEEID:
        return <Button floated="right" onClick={initCriiptoBackend}>Sign with Belgian eID</Button>
      default:
        <Button floated="right" onClick={initCriiptoBackend}>Sign</Button>
    }
  }

  const renderContent = () => {
    switch (criiptoType) {
      case ECriiptoType.DKMITID:
        return (
          <>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90%" }}>
              <Button
                className="criipto-mitid"
                onClick={initCriiptoBackend}
                size="massive"
              >
                <CriiptoMitIDSvg fill="#ffffff" color="white" width="80px" />
                &nbsp;&nbsp;Sign with MitID
              </Button>
            </div>
          </>
        )
      case ECriiptoType.FITUPAS:
        return (
          <>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90%" }}>
              {renderButton(criiptoType)}
            </div>
          </>
        )
      case ECriiptoType.SEBANKID:
      case ECriiptoType.NOBANKID_OIDC:
      case ECriiptoType.NOVIPPSLOGIN:
      case ECriiptoType.BEEID:
      default:
        return (
          <>
            {isMobile ? (
              <>
                <div style={{ padding: "1em", marginBottom: "0", width: "100%", height: "100%", background: `url("data:image/svg+xml,${background}")`, backgroundSize: "clamp(20%, 60%, 400px", backgroundPosition: "right bottom", backgroundRepeat: "no-repeat" }}>
                  <h2>{t("General_UI.Hello")}</h2>
                  <h3>{t("Criipto.Start_Page.Explanation", { serviceName: t(`Enum.CriiptoType.${ECriiptoType[criiptoType]}`) })}</h3>
                  <h3>{t("Criipto.Start_Page.Explanation2")}</h3>
                  <h4>{t("Criipto.Start_Page.Explanation3", { serviceName: t(`Enum.CriiptoType.${ECriiptoType[criiptoType]}`) })}</h4>
                </div>
                <Segment className="criipto mt-0" attached="bottom" style={{ height: "10%", width: "100%", margin: 0 }} textAlign="center">
                  {renderButton(criiptoType)}
                </Segment>
              </>
            ) : (
              <>
                <div ref={mainDiv} style={{ padding: "0", marginBottom: "0", width: "100%", flex: 1, background: `url("data:image/svg+xml,${background}")`, backgroundSize: backgroundSize, backgroundPosition: "right bottom", backgroundRepeat: "no-repeat" }}>
                  <h2>{t("General_UI.Hello")}</h2>
                  <h3>
                    {t("Criipto.Start_Page.Explanation", { serviceName: t(`Enum.CriiptoType.${ECriiptoType[criiptoType]}`) })}
                    <br />
                    {t("Criipto.Start_Page.Explanation2")}
                  </h3>
                  <h4>{t("Criipto.Start_Page.Explanation3", { serviceName: t(`Enum.CriiptoType.${ECriiptoType[criiptoType]}`) })}</h4>
                </div>
                <Segment className="criipto mt-0" attached="bottom" style={{ width: "100%", margin: 0 }} textAlign="center">
                  {renderButton(criiptoType)}
                </Segment>
              </>
            )}
          </>
        )
    }
  }
  return (
    <>
      {isMobile && <CriiptoHeader />}
      {renderContent()}
    </>
  )
}