import { useTranslation } from "react-i18next";
import { Segment, Grid, Card, Icon } from "semantic-ui-react";
import { ECriiptoPage } from "../../../../../Enums/ECriiptoPage"
import { CriiptoCountryTypeGetDto } from "../../../../../Types";
import { ECriiptoType } from "../../../../../Enums/ECriiptoType";
import { CriiptoMitIDSvg } from "../../../Criipto/Components/Buttons/CriiptoMitIDSvg";
import { useState } from "react";
import { Spinner } from "../../../../Common/spinner/Spinner";

interface IProps {
  setCurrentPage: (page: ECriiptoPage) => void;
  criiptoTypes: CriiptoCountryTypeGetDto[];
  setChosenCriiptoType: (type: ECriiptoType) => void;
}

export const MitekCriiptoSelectionPage = ({ setCurrentPage, criiptoTypes, setChosenCriiptoType }: IProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  // This isn't performed in a useEffect, since that seems to prevent the if from executing correctly
  const hasForced = criiptoTypes.some((x) => x.forced);
  if (criiptoTypes.length === 1 && hasForced) {
    setChosenCriiptoType(criiptoTypes[0].criiptoType);
    setCurrentPage(ECriiptoPage.Start);
  } else if (isLoading) {
    setIsLoading(false)
  }

  const handleCriiptoChoice = (type: ECriiptoType) => {
    setChosenCriiptoType(type);
    setCurrentPage(ECriiptoPage.Start);
  }

  const renderSwitch = (type: ECriiptoType) => {
    switch (type) {
      case ECriiptoType.DKMITID:
        return <CriiptoMitIDSvg fill="#0060e6" color="blue" width="100px" />
      case ECriiptoType.SEBANKID:
        return <>Swedish BankID</>
      case ECriiptoType.NOBANKID_OIDC:
        return <>Norwegian BankID</>
      case ECriiptoType.NOVIPPSLOGIN:
        return <>Norwegian Vipps</>
      case ECriiptoType.FITUPAS:
        return <>Finnish Trust Network</>
      case ECriiptoType.BEEID:
        return <>Belgian eID</>
      default:
        return <>Unsupported</>
    }
  }

  const renderCriiptoOptions = () => {
    var supportedEIDs = criiptoTypes.filter((x) => x.criiptoType != ECriiptoType.NONE).map((x) => x.criiptoType);
    return (
      <>
        {supportedEIDs.map((x) => (
          <Grid.Column textAlign="center" stretched style={{ padding: "1rem 1rem" }} key={x}>
            <Card fluid onClick={() => handleCriiptoChoice(x)}>
              {/* Add logo for current eID */}
              <Card.Content>
                <Card.Header>
                  {renderSwitch(x)}
                </Card.Header>
              </Card.Content>
            </Card>
          </Grid.Column>
        ))}
      </>
    )
  }

  if (isLoading) return <Spinner />

  return (
    <>
      <Segment className="criipto" style={{ height: "90%", width: "100%" }} basic>
        <h2>{t("Criipto.Selection_Page.Explanation")}</h2>
        <h3>{t("Criipto.Selection_Page.Explanation2")}</h3>
        <Grid columns={2} stackable verticalAlign="middle" stretched style={{ width: "100%" }}>
          <Grid.Row verticalAlign="middle" divided textAlign="center">
            {renderCriiptoOptions()}
            {!hasForced && (
              <Grid.Column>
                <Card fluid onClick={() => setCurrentPage(ECriiptoPage.Mitek)}>
                  <Icon fitted size="huge" name="id card outline" color="black" />
                  <Card.Content>
                    <Card.Header>
                      {t("Criipto.Selection_Page.Option_Mitek")}
                    </Card.Header>
                  </Card.Content>
                </Card>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment className="criipto" attached="bottom" style={{ height: "10%", width: "100%" }} textAlign="center">
        <h3>{t("Criipto.Selection_Page.Choose_Validation_Type")}</h3>
      </Segment>
    </>
  )
}