import { Divider, Header } from "semantic-ui-react"
import { DtsManagerForm } from "../../../../../Components/secure/dtsManager/DtsManagerForm"
import { useTranslation } from "react-i18next"

export const CreateDtsManager = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header as="h1">{t("Dts_Manager.New")}</Header>
      <Divider />
      <DtsManagerForm />
    </>
  )
}