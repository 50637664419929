import { EDocumentType } from "../../Enums/EDocumentType"
import { EGender } from "../../Enums/EGender";

export interface IAnonimityRevocation {
  id: number;
  firstName: string;
  lastName: string;
  accountAddress: string;
  Sex: EGender;
  dateOfBirth: Date | null;
  countryOfResidence: string;
  countryOfNationality: string;
  documentNumber: string;
  documentType: EDocumentType;
  documentIssuer: string;
  idValidFrom: Date | null;
  idValidTo: Date | null;
  nationalIdNumber: string;
  taxIdNumber: string;
  submissionId: string;
  idCredPub: string;
  revocationRecord: string;
  identityObject: string;
  originalExtractedData: string;
  userEditedExtractedData: string;
}

export interface IGetAnonimityRevocation {
  firstName: string;
  lastName: string;
  accountAddress: string;
  documentNumber: string;
  idCredPub: string;
}

export class GetAnonimityRevocationFormValues {
  firstName: string = "";
  lastName: string = "";
  accountAddress: string = "";
  documentNumber: string = "";
  idCredPub: string = "";
  atLeastOne: boolean = false; // Validation object
  constructor(init?: IGetAnonimityRevocation) {
    Object.assign(this, init);
  }
}