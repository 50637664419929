import "./CriiptoCheck.css";
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react'
import { isCountrySupportedByCriipto } from '../../../Services/Backend'
import { Spinner } from '../../Common/spinner/Spinner';
import { StateValue } from '../../Context';
import { MitekCheckComponent, MitekCheckProps } from '../Mitek/MitekCheckComponent';
import { CriiptoComponent } from "./Components/CriiptoComponent";
import { CriiptoCountrySupportedGetDto } from "../../../Types";

export const MitekCriiptoCheckComponent = observer((props: MitekCheckProps) => {
  const [useCriipto, setUseCriipto] = useState(false);
  const [criiptoSupport, setCriiptoSupport] = useState<CriiptoCountrySupportedGetDto>();
  const [isLoading, setIsLoading] = useState(true);
  const state = useContext(StateValue);

  useEffect(() => {
    const loadCountry = async () => {
      await isCountrySupportedByCriipto(state.ctxId).then((res) => {
        setCriiptoSupport(res.data);
        setUseCriipto(res.data.isSupported);
      });
      setIsLoading(false);
    }
    loadCountry();
  }, [state.ctxId])

  if (isLoading) return <Spinner />

  if (useCriipto && criiptoSupport) return <CriiptoComponent criiptoAcr={criiptoSupport} props={props} />

  // If criipto isn't supported, go to MitekCheckComponent
  return <MitekCheckComponent isLandscape={props.isLandscape} isPortrait={props.isPortrait} props={props.props} />
})
