import { EAutoCoveredPages } from "../../Enums/EAutoCoveredPages";
import { EDocumentType } from "../../Enums/EDocumentType";
import { EMakeAChoice } from "../../Enums/EMakeAChoice";
import { format } from "date-fns";

export interface IMitekSupportedDocument {
  id: number;
  countryName: string;
  countryAlpha2: string;
  year: Date;
  documentType: EDocumentType;
  autoCoveredPages: EAutoCoveredPages;
  hasAutoCoverage: boolean
  hasAgentCoverage: boolean
}

export class MitekSupportedDocumentFormValues {
  // countryName: string = "";
  countryAlpha2: string = "";
  year: string | null = null;
  documentType: EDocumentType = EMakeAChoice.Value as number;
  autoCoveredPages: EDocumentType = EMakeAChoice.Value as number;
  hasAutoCoverage: boolean = false;
  hasAgentCoverage: boolean = false;

  constructor(init?: IMitekSupportedDocument) {
    Object.assign(this, init);
    if (init && init.year !== null) {
      this.year = format(new Date(init.year), "yyyy-MM-dd")
    }
  }
}