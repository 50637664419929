import { Divider, Header } from "semantic-ui-react";
import { ProfileForm } from "../../Components/account/ProfileForm";
import { useTranslation } from "react-i18next"

export const Profile = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header as="h1">Profile</Header>
      <Divider />
      <ProfileForm />
    </>
  )
}