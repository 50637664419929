import axios from "axios";
import { EDocumentType } from "../Enums/EDocumentType";
import { ELogType } from "../Enums/ELogType";
import { EMitekCheckStatus } from "../Enums/EMitekCheckStatus";
import { getFlowByctxIdDTO, CriiptoCountrySupportedGetDto, CriiptoTransactionStatus } from "../Types";

export const getCriiptoTransactionStatus = (ctxId: string) => {
  return axios.get<CriiptoTransactionStatus>(`/api/criipto/status?ctxId=${ctxId}&serviceId=10`);
}

export const emptyCriiptoCookies = () => {
  return axios.post(`/api/criipto/sign-out`, {}, { withCredentials: true });
}

export const isCountrySupportedByCriipto = (ctxId: string) => {
  return axios.get<CriiptoCountrySupportedGetDto>(`/api/criipto/isCountrySupported?ctxId=${ctxId}`);
}

export const getFlowByctxId = (ctxId: string) => {
  return axios.get<getFlowByctxIdDTO>(`/api/frontend/flow/${ctxId}`);
}

export const getDidCheckedIdFinish = (ctxId: string) => {
  return axios.get("/api/checkedId/didfinish/" + ctxId);
}

type mitekCheckDto = {
  type: "PASSPORT" | "ID" | "DRIVINGLICENSE",
  IdDocuments: string[],
  selfie: string,
  ctxId: string,
  wasFile: boolean,
}

export type didFinishDto = {
  validated: boolean,
  failed: boolean,
  agentHandoff: boolean,
  agentHandoffCompleted: boolean,
  error?: number,
}

export type supportedDocumentsDto = {
  documentType: EDocumentType
  year?: Date
  HasAutoCoverage: boolean
  HasAgentCoverage: boolean
}

export type AgentHandoffStatusDto = {
  status: EMitekCheckStatus
}

export const getDidMitekFinish = (ctxId: string) => {
  return axios.get<didFinishDto>("/api/mitekcheck/didfinish/" + ctxId);
}

export const postMitekCheck = (data: mitekCheckDto) => {
  return axios.post('/api/frontend/startservice/mitekcheck/', { ...data, serviceId: 8 });
}

export const getIsAgentHandoff = (ctxId: string, isIOS: boolean) => {
  return axios.post("/api/concordium/agent-handoff?ctxId=" + ctxId + "&isIOS=" + isIOS);
}

export const getAllowedDocuments = (ctxId: string) => {
  return axios.get<supportedDocumentsDto[]>("/api/frontend/allowed-documents?ctxId=" + ctxId);
}

export const getAgentHandoffStatus = (ctxId: string) => {
  return axios.get<AgentHandoffStatusDto>("/api/concordium/agent-handoff-status?ctxId=" + ctxId);
}

export type getFlow = {
  flow: number[],
}

export const postUserProgress = (dto: UserProgressLogDto) => {
  return axios.post('/api/userProgressLog/log', dto);
}

export type UserProgressLogDto = {
  ctxId: string,
  type: ELogType,
}