import { IPassword } from "./IPassword";

export interface IDtsManager {
  id: string;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  lastActivity: Date;
  password: IPassword;
}

export class DtsmanagerFormValues {
  id?: string | undefined;
  email: string = "";
  userName: string = "";
  firstName: string = "";
  lastName: string = "";

  constructor(init?: IDtsManager) {
    Object.assign(this, init);
  }
}