import { Container, Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header as="h1">{t("ErrorPage.Not_Found")}</Header>
    </Container>
  )
}