import { ConcordiumManagerFormValues, IConcordiumManager } from "../models/user/IConcordiumManager";
import { EToastType } from "../Enums/EToastType";
import { RootStore } from "./RootStore";
import { history } from "../ApplicationRouter";
import { makeAutoObservable, runInAction } from "mobx";

export default class ConcordiumManagerStore {
  rootStore: RootStore;
  concordiumManagers: IConcordiumManager[] = [];
  loadingConcordiumManagers: boolean = false;
  submitting: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getConcordiumManagers = async () => {
    runInAction(() => {
      this.loadingConcordiumManagers = true;
    });

    const { success, data }: { success: boolean, data: IConcordiumManager[] } = await this.rootStore.baseApiService.get("concordium-manager");
    if (success) {
      runInAction(() => {
        this.concordiumManagers = data;
        this.loadingConcordiumManagers = false;
      });
    } else {
      runInAction(() => {
        this.loadingConcordiumManagers = false;
      });
    }
  }

  getConcordiumManager = async (id: string) => {
    runInAction(() => {
      this.loadingConcordiumManagers = true;
    });
    const { success, data }: { success: boolean, data: IConcordiumManager } = await this.rootStore.baseApiService.get(`concordium-manager/${id}`);
    if (success) {
      runInAction(() => {
        this.loadingConcordiumManagers = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loadingConcordiumManagers = false;
      });
    }
  }

  addConcordiumManager = async (values: ConcordiumManagerFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });

    const { success } = await this.rootStore.baseApiService.post('concordium-manager', values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        history.push('/dashboard/concordium-managers');
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
        this.getConcordiumManagers();
      })
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      })
    }
  }

  editConcordiumManager = async (values: ConcordiumManagerFormValues) => {
    this.submitting = true;

    const success = await this.rootStore.baseApiService.put(`concordium-manager/${values.id}`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        history.push('/dashboard/concordium-managers');
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
        this.getConcordiumManagers();
      })
    }
    else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      })
    }
  }

  deleteConcordiumManager = async (id: string) => {
    this.submitting = true;

    const success = await this.rootStore.baseApiService.delete(`concordium-manager/${id}`);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.confirmModalStore.closeConfirmModal();
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Delete_Success");
        this.getConcordiumManagers();
      })
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      })
    }
  }
}