function ToDoAnimation(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-45 -49 914 771" width="80%" height="80%">
      <path
        fill="#F3F3F3"
        d="M708.917 720.055H42.558l-70.499-269.841a40.273 40.273 0 01.988-23.598l73.912-209.568a40.318 40.318 0 0135.209-26.81l534.729-37.379c17.194-1.203 33.253 8.661 39.952 24.545l107.65 255.125a40.349 40.349 0 012.231 24.324l-57.813 263.202z"
      ></path>
      <path fill="#B1B5BA" d="M31.742 622.545h-112.63 112.63z"></path>
      <path
        fill="#D9DCDE"
        d="M205.412 665.385l4.63 51.39c.36 1.87-1.07 3.65-2.94 3.65-1.25 0-2.4-.8-2.85-2.05l-13.45-52.99h14.61z"
      ></path>
      <path
        fill="#B1B5BA"
        d="M203.722 695.315l-5.61-1.34-7.21-28.5h14.61l4.63 51.39c.27 1.42-.53 2.85-1.78 3.38l-4.64-24.93z"
      ></path>
      <path
        fill="#FB9C9C"
        d="M346.812 109.796l-102.32 109.66c-7.61 8.16-21.21 4.27-23.46-6.63l-11.18-56.1 29.9-68.95 11.36 72.03 72.29-60.9 23.41 10.89z"
      ></path>
      <path
        fill={props.color}
        d="M610.022 721.255h-320.18c-7.94 0-14.27-6.43-14.27-14.271v-585.7c0-7.94 6.43-14.27 14.27-14.27h320.18c7.94 0 14.27 6.43 14.27 14.27v585.7c0 7.94-6.33 14.27-14.27 14.27z"
        className="change-color-in-svg"
      ></path>
      <path
        fill="#fff"
        d="M610.022 721.255h-320.18c-7.94 0-14.27-6.43-14.27-14.271v-585.7c0-7.94 6.43-14.27 14.27-14.27h320.18c7.94 0 14.27 6.43 14.27 14.27v585.7c0 7.94-6.33 14.27-14.27 14.27z"
        opacity="0.5"
      ></path>
      <path
        fill={props.color}
        d="M600.172 721.255h-320.17c-7.94 0-14.27-6.43-14.27-14.271v-585.7c0-7.94 6.43-14.27 14.27-14.27h320.18c7.94 0 14.27 6.43 14.27 14.27v585.7c-.01 7.94-6.34 14.27-14.28 14.27z"
        className="change-color-in-svg"
      ></path>
      <path
        fill="#fff"
        d="M600.172 721.255h-320.17c-7.94 0-14.27-6.43-14.27-14.271v-585.7c0-7.94 6.43-14.27 14.27-14.27 28.827 84.264 336.815 48.626 320.18 0 7.94 0 14.27 6.43 14.27 14.27v585.7c-.01 7.94-6.34 14.27-14.28 14.27z"
        opacity="0.7"
      ></path>
      <path
        fill={props.color}
        d="M554.902 308.906h-213.45c-2.36 0-4.21-1.91-4.21-4.21v-66.38c0-2.36 1.91-4.21 4.21-4.21h213.46c2.36 0 4.21 1.91 4.21 4.21v66.38c-.01 2.36-1.86 4.21-4.22 4.21z"
        className="change-color-in-svg"
      ></path>
      <path
        fill="#fff"
        d="M554.902 308.906h-213.45c-2.36 0-4.21-1.91-4.21-4.21v-66.38c0-2.36 1.91-4.21 4.21-4.21h213.46c2.36 0 4.21 1.91 4.21 4.21v66.38c-.01 2.36-1.86 4.21-4.22 4.21z"
        opacity="0.5"
      ></path>
      <path
        fill="#fff"
        d="M549.402 308.906h-213.46c-2.36 0-4.21-1.91-4.21-4.21v-66.38c0-2.36 1.91-4.21 4.21-4.21h213.46c2.36 0 4.21 1.91 4.21 4.21v66.38c.06 2.36-1.85 4.21-4.21 4.21z"
      ></path>
      <path
        fill="#61656D"
        d="M496.332 262.204h-62.76c-2.24 0-4-1.52-4-3.31 0-1.85 1.83-3.31 4-3.31h62.76c2.24 0 4 1.52 4 3.31 0 1.85-1.76 3.31-4 3.31z"
      ></path>
      <path
        fill="#B0B4B9"
        d="M527.102 275.176h-95.43c-1.15 0-2.1-.79-2.1-1.74s.95-1.74 2.1-1.74h95.43c1.15 0 2.1.79 2.1 1.74.07.96-.95 1.74-2.1 1.74zM488.542 285.965h-56.86c-1.15 0-2.1-.79-2.1-1.74s.95-1.74 2.1-1.74h56.86c1.15 0 2.1.79 2.1 1.74s-.95 1.74-2.1 1.74z"
      ></path>
      <path
        fill={props.color}
        d="M385.132 247.915h-4.49v.449c-10.45 2.08-18.36 11.34-18.36 22.41 0 11.06 7.86 20.33 18.36 22.41v.45h4.49c12.64 0 22.86-10.22 22.86-22.86-.01-12.64-10.23-22.86-22.86-22.86z"
        className="change-color-in-svg"
      ></path>
      <path
        fill={props.color}
        d="M380.632 293.576c12.62 0 22.86-10.24 22.86-22.86s-10.24-22.86-22.86-22.86-22.86 10.23-22.86 22.86c0 12.63 10.23 22.86 22.86 22.86z"
        className="change-color-in-svg"
      ></path>
      <path
        fill="#fff"
        d="M380.632 293.576c12.62 0 22.86-10.24 22.86-22.86s-10.24-22.86-22.86-22.86-22.86 10.23-22.86 22.86c0 12.63 10.23 22.86 22.86 22.86z"
        opacity="0.4"
      ></path>
      <path
        fill="#fff"
        d="M376.372 280.266l-7.69-7.69c-.39-.39-.39-1.07 0-1.52l2.02-2.02c.39-.39 1.07-.39 1.52 0l4.94 4.94 11.91-11.96c.39-.39 1.07-.39 1.52 0l2.02 2.02c.39.39.39 1.07 0 1.52l-14.71 14.71c-.41.44-1.08.44-1.53 0z"
      ></path>
      <path
        fill={props.color}
        d="M554.902 426.695h-213.45c-2.36 0-4.21-1.91-4.21-4.21v-66.38c0-2.36 1.91-4.21 4.21-4.21h213.46c2.36 0 4.21 1.91 4.21 4.21v66.38c-.01 2.36-1.86 4.21-4.22 4.21z"
        className="change-color-in-svg"
      ></path>
      <path
        fill="#fff"
        d="M554.902 426.695h-213.45c-2.36 0-4.21-1.91-4.21-4.21v-66.38c0-2.36 1.91-4.21 4.21-4.21h213.46c2.36 0 4.21 1.91 4.21 4.21v66.38c-.01 2.36-1.86 4.21-4.22 4.21z"
        opacity="0.5"
      ></path>
      <path
        fill="#fff"
        d="M549.402 426.695h-213.46c-2.36 0-4.21-1.91-4.21-4.21v-66.38c0-2.36 1.91-4.21 4.21-4.21h213.46c2.36 0 4.21 1.91 4.21 4.21v66.38c.06 2.36-1.85 4.21-4.21 4.21z"
      ></path>
      <path
        fill="#61656D"
        d="M496.332 379.995h-62.76c-2.24 0-4-1.52-4-3.31 0-1.85 1.83-3.31 4-3.31h62.76c2.24 0 4 1.52 4 3.31 0 1.85-1.76 3.31-4 3.31z"
      ></path>
      <path
        fill="#B0B4B9"
        d="M527.102 392.965h-95.43c-1.15 0-2.1-.79-2.1-1.74s.95-1.74 2.1-1.74h95.43c1.15 0 2.1.79 2.1 1.74.07.96-.95 1.74-2.1 1.74zM488.542 403.744h-56.86c-1.15 0-2.1-.79-2.1-1.74s.95-1.74 2.1-1.74h56.86c1.15 0 2.1.79 2.1 1.74 0 .96-.95 1.74-2.1 1.74z"
      ></path>
      <path
        fill={props.color}
        d="M385.132 365.706h-4.49v.45c-10.45 2.08-18.36 11.34-18.36 22.41s7.86 20.33 18.36 22.41v.45h4.49c12.64 0 22.86-10.22 22.86-22.86-.01-12.64-10.23-22.86-22.86-22.86z"
        className="change-color-in-svg"
      ></path>
      <path
        fill={props.color}
        d="M380.632 411.365c12.62 0 22.86-10.23 22.86-22.86 0-12.63-10.24-22.86-22.86-22.86s-22.86 10.23-22.86 22.86c0 12.63 10.23 22.86 22.86 22.86z"
        className="change-color-in-svg"
      ></path>
      <path
        fill="#fff"
        d="M380.632 411.365c12.62 0 22.86-10.23 22.86-22.86 0-12.63-10.24-22.86-22.86-22.86s-22.86 10.23-22.86 22.86c0 12.63 10.23 22.86 22.86 22.86z"
        opacity="0.4"
      ></path>
      <path
        fill="#fff"
        d="M376.372 398.045l-7.69-7.69c-.39-.39-.39-1.07 0-1.52l2.02-2.02c.39-.39 1.07-.39 1.52 0l4.94 4.94 11.91-11.96c.39-.39 1.07-.39 1.52 0l2.02 2.02c.39.39.39 1.07 0 1.52l-14.71 14.71c-.41.45-1.08.45-1.53 0z"
      ></path>
      <path
        fill={props.color}
        d="M554.902 544.484h-213.45c-2.36 0-4.21-1.91-4.21-4.21v-66.38c0-2.36 1.91-4.21 4.21-4.21h213.46c2.36 0 4.21 1.91 4.21 4.21v66.38c-.01 2.35-1.86 4.21-4.22 4.21z"
        className="change-color-in-svg"
      ></path>
      <path
        fill="#fff"
        d="M554.902 544.484h-213.45c-2.36 0-4.21-1.91-4.21-4.21v-66.38c0-2.36 1.91-4.21 4.21-4.21h213.46c2.36 0 4.21 1.91 4.21 4.21v66.38c-.01 2.35-1.86 4.21-4.22 4.21z"
        opacity="0.5"
      ></path>
      <path
        fill="#fff"
        d="M549.402 544.484h-213.46c-2.36 0-4.21-1.91-4.21-4.21v-66.38c0-2.36 1.91-4.21 4.21-4.21h213.46c2.36 0 4.21 1.91 4.21 4.21v66.38c.06 2.35-1.85 4.21-4.21 4.21z"
      ></path>
      <path
        fill="#61656D"
        d="M496.332 497.785h-62.76c-2.24 0-4-1.52-4-3.31 0-1.85 1.83-3.31 4-3.31h62.76c2.24 0 4 1.52 4 3.31 0 1.85-1.76 3.31-4 3.31z"
      ></path>
      <path
        fill="#B0B4B9"
        d="M527.102 510.756h-95.43c-1.15 0-2.1-.79-2.1-1.74s.95-1.74 2.1-1.74h95.43c1.15 0 2.1.79 2.1 1.74.07.96-.95 1.74-2.1 1.74zM488.542 521.535h-56.86c-1.15 0-2.1-.79-2.1-1.74s.95-1.74 2.1-1.74h56.86c1.15 0 2.1.79 2.1 1.74 0 .96-.95 1.74-2.1 1.74z"
      ></path>
      <path
        fill={props.color}
        d="M385.132 483.495h-4.49v.45c-10.45 2.08-18.36 11.34-18.36 22.41 0 11.06 7.86 20.33 18.36 22.41v.45h4.49c12.64 0 22.86-10.22 22.86-22.86-.01-12.64-10.23-22.86-22.86-22.86z"
        className="change-color-in-svg"
      ></path>
      <path
        fill={props.color}
        d="M380.632 529.154c12.62 0 22.86-10.23 22.86-22.86 0-12.63-10.24-22.86-22.86-22.86s-22.86 10.23-22.86 22.86c0 12.63 10.23 22.86 22.86 22.86z"
        className="change-color-in-svg"
      ></path>
      <path
        fill="#fff"
        d="M380.632 529.154c12.62 0 22.86-10.23 22.86-22.86 0-12.63-10.24-22.86-22.86-22.86s-22.86 10.23-22.86 22.86c0 12.63 10.23 22.86 22.86 22.86z"
        opacity="0.4"
      ></path>
      <path
        fill="#fff"
        d="M376.372 515.836l-7.69-7.69c-.39-.39-.39-1.07 0-1.52l2.02-2.02c.39-.39 1.07-.39 1.52 0l4.94 4.94 11.91-11.96c.39-.39 1.07-.39 1.52 0l2.02 2.02c.39.39.39 1.07 0 1.52l-14.71 14.71c-.41.45-1.08.45-1.53 0z"
      ></path>
      <path
        fill={props.color}
        d="M554.902 664.785h-213.45c-2.36 0-4.21-1.91-4.21-4.21v-66.38c0-2.36 1.91-4.21 4.21-4.21h213.46c2.36 0 4.21 1.91 4.21 4.21v66.38c-.01 2.36-1.86 4.21-4.22 4.21z"
        className="change-color-in-svg"
      ></path>
      <path
        fill="#fff"
        d="M554.902 664.785h-213.45c-2.36 0-4.21-1.91-4.21-4.21v-66.38c0-2.36 1.91-4.21 4.21-4.21h213.46c2.36 0 4.21 1.91 4.21 4.21v66.38c-.01 2.36-1.86 4.21-4.22 4.21z"
        opacity="0.5"
      ></path>
      <path
        fill="#fff"
        d="M549.402 664.785h-213.46c-2.36 0-4.21-1.91-4.21-4.21v-66.38c0-2.36 1.91-4.21 4.21-4.21h213.46c2.36 0 4.21 1.91 4.21 4.21v66.38c.06 2.36-1.85 4.21-4.21 4.21z"
      ></path>
      <path
        fill="#61656D"
        d="M496.332 618.085h-62.76c-2.24 0-4-1.52-4-3.31 0-1.85 1.83-3.31 4-3.31h62.76c2.24 0 4 1.52 4 3.31 0 1.85-1.76 3.31-4 3.31z"
      ></path>
      <path
        fill="#B0B4B9"
        d="M527.102 631.055h-95.43c-1.15 0-2.1-.79-2.1-1.74s.95-1.74 2.1-1.74h95.43c1.15 0 2.1.79 2.1 1.74.07.96-.95 1.74-2.1 1.74zM488.542 641.846h-56.86c-1.15 0-2.1-.79-2.1-1.74s.95-1.74 2.1-1.74h56.86c1.15 0 2.1.79 2.1 1.74s-.95 1.74-2.1 1.74z"
      ></path>
      <path
        fill={props.color}
        d="M385.132 603.795h-4.49v.45c-10.45 2.08-18.36 11.34-18.36 22.41s7.86 20.33 18.36 22.41v.45h4.49c12.64 0 22.86-10.22 22.86-22.86-.01-12.64-10.23-22.86-22.86-22.86z"
        className="change-color-in-svg"
      ></path>
      <path
        fill={props.color}
        d="M380.632 649.455c12.62 0 22.86-10.23 22.86-22.86 0-12.63-10.24-22.86-22.86-22.86s-22.86 10.24-22.86 22.86 10.23 22.86 22.86 22.86z"
        className="change-color-in-svg"
      ></path>
      <path
        fill="#fff"
        d="M380.632 649.455c12.62 0 22.86-10.23 22.86-22.86 0-12.63-10.24-22.86-22.86-22.86s-22.86 10.24-22.86 22.86 10.23 22.86 22.86 22.86z"
        opacity="0.4"
      ></path>
      <path
        fill="#fff"
        d="M376.372 636.145l-7.69-7.69c-.39-.39-.39-1.07 0-1.52l2.02-2.02c.39-.39 1.07-.39 1.52 0l4.94 4.94 11.91-11.96c.39-.39 1.07-.39 1.52 0l2.02 2.02c.39.39.39 1.07 0 1.52l-14.71 14.71c-.41.44-1.08.44-1.53 0z"
      ></path>
      <path
        fill="#3F4249"
        d="M155.992-32.265c-12.2-2.36-18.94 10.52-22.14 27.03-3.2 16.51-1.42 26.89 10.78 29.25 12.2 2.36 23.96-5.39 27.16-21.9 3.2-16.51-3.6-32.01-15.8-34.38z"
      ></path>
      <path
        fill="#F79898"
        d="M183.452 699.095l68.94 1.54-25.27-18.79c-2.43-1.75-4.1-4.19-5.12-6.94-1.12-2.99.05-5.09.36-6.75.84-4.48 3.13-19.02 3.13-19.02l-38.59.1-3.45 49.86z"
      ></path>
      <path
        d="M196.132 673.934v-.4c-.2-2.27 1.38-4.25 3.56-4.55l22.67-.83.84-4.48h-37.3l-.01.13-1.67 24.08 33.73 6.75-9.2-3.66c-6.42-2.56-12.02-10.21-12.62-17.04z"
        opacity="0.15"
      ></path>
      <path
        fill="#F79898"
        d="M110.512 694.945l31.54-4.16 1.86-27.1.68-14.78-37.16.25.48 14.53 2.6 31.26z"
      ></path>
      <path
        d="M116.482 689.706l.69-15.03c.1-1.98 1.58-3.56 3.56-3.86l22.89-3.01.28-4.11h-36l2.82 33.67 5.76-7.66z"
        opacity="0.15"
      ></path>
      <path
        fill={props.color}
        d="M88.582 264.185l19.32 399.5h36l14.8-325.71c.2-2.18 1.98-3.76 4.15-3.76 2.08 0 3.86 1.58 4.06 3.76l32.91 144.98-13.93 180.73h37.3l27.08-172.14c.87-5.55 1.05-11.18.51-16.77l-21.35-224.79-140.85 14.2z"
        className="change-color-in-svg"
      ></path>
      <path
        fill="#fff"
        d="M88.482 273.256l19.42 390.44h36l14.8-325.71c.2-2.18 1.98-3.76 4.15-3.76 2.08 0 3.86 1.58 4.06 3.76l32.91 144.98-13.93 180.73h37.3l27.37-173.99c.68-4.32.81-8.71.4-13.06l-19.15-201.62-143.24-10.82-.09 9.05z"
        opacity="0.2"
      ></path>
      <path
        fill="#3F4249"
        d="M113.262 680.706l.89-.89c6.73-6.73 18.3-6.63 24.83.3l.49.59c1.68 1.78 2.57 4.06 2.57 6.33v3.76c0 2.18.3 4.35.99 6.43l3.07 10.19a25 25 0 011.19 7.619v1.29c0 1.98-1.78 3.56-3.86 3.56h-35.51c-2.18 0-3.86-1.58-3.86-3.56v-.49c0-2.97.49-5.93 1.48-8.8l3.56-9.99c.79-2.27 1.29-4.65 1.29-7.12l.1-2.87c.1-2.3 1.09-4.57 2.77-6.35z"
      ></path>
      <path
        d="M104.062 715.815v.49c0 1.98 1.78 3.56 3.86 3.56h35.51c2.18 0 3.86-1.58 3.86-3.56v-1.29c0-.49 0-.99-.1-1.48h-43.13v2.28z"
        opacity="0.5"
      ></path>
      <path
        fill="#3F4249"
        d="M181.852 687.725v25.02c0 4.06 3.26 7.32 7.32 7.32h85.06c1.68 0 3.07-1.38 3.07-3.07 0-5.93-3.86-11.28-9.5-13.16l-19.19-6.33-24.33-18.891c-2.47-1.98-6.03-1.88-8.51.2l-10.78 9.2c-1.58 1.38-3.76 1.78-5.74 1.19l-13.65-4.25c-1.97-.5-3.75.89-3.75 2.77z"
      ></path>
      <path d="M189.172 720.065h85.06c1.68 0 3.07-1.38 3.07-3.07 0-1.09-.1-2.08-.4-3.07h-94.95c.59 3.47 3.56 6.14 7.22 6.14z"></path>
      <path
        fill="#fff"
        d="M223.792 688.614c-.2 0-.4-.1-.49-.2-.3-.3-.3-.79 0-1.09l5.14-5.54c.3-.3.79-.3 1.09 0 .3.3.3.79 0 1.09l-5.14 5.54c-.2.1-.4.2-.6.2zM227.852 691.774c-.2 0-.4-.1-.49-.2-.3-.3-.3-.79 0-1.09l5.14-5.54c.3-.3.79-.3 1.09 0 .3.3.3.79 0 1.09l-5.14 5.54c-.21.1-.41.2-.6.2zM231.902 694.944c-.2 0-.4-.1-.49-.2-.3-.3-.3-.79 0-1.09l5.14-5.54c.3-.3.79-.3 1.09 0 .3.3.3.79 0 1.09l-5.14 5.54c-.1.1-.3.2-.6.2zM133.052 689.104c-.1 0-.3 0-.4-.1-6.73-3.36-13.06 0-13.06 0-.4.2-.99.1-1.19-.4-.2-.4-.1-.99.4-1.19.3-.2 7.12-3.76 14.74 0 .4.2.59.79.4 1.19-.2.31-.5.5-.89.5zM133.052 693.655c-.1 0-.3 0-.4-.1-6.82-3.36-13.06 0-13.06 0-.4.2-.99.1-1.19-.4-.2-.4-.1-.99.4-1.19.3-.2 7.12-3.76 14.74 0 .4.2.59.79.4 1.19-.2.31-.5.5-.89.5zM133.052 698.104c-.1 0-.3 0-.4-.1-6.73-3.36-13.06 0-13.06 0-.4.2-.99.1-1.19-.4-.2-.4-.1-.99.4-1.19.3-.2 7.12-3.76 14.74 0 .4.2.59.79.4 1.19-.2.31-.5.5-.89.5z"
      ></path>
      <path
        fill="#F79898"
        d="M189.322 12.315s-34.8-32.54-42.24-2.44l-2.11 7.75-11.6 45.34c-.23 1.17 45.04 12.31 45.13 10.82l.78-8.1 2.05-24.09c.88-1.95 1.62-4.22 2.13-6.84 2.7-11.87 5.86-22.44 5.86-22.44z"
      ></path>
      <path
        fill="#3F4249"
        d="M195.052 15.086c7.54 0 13.65-6.11 13.65-13.65s-6.11-13.65-13.65-13.65-13.65 6.11-13.65 13.65 6.11 13.65 13.65 13.65z"
      ></path>
      <path
        fill="#F79898"
        d="M197.942-8.275s2.64 61.77-28.43 59.88c-26.9-1.59-21.89-43.03-21.89-43.03l6.4-26.26 43.92 9.41z"
      ></path>
      <path
        fill="#3F4249"
        d="M153.552 2.464c-3.07 14.21.99 23.41.99 23.41 33.31 1.9 37.19 8.84 37.19 8.84s-5.11 15.81-19.17 16.72c-14.06.91-32.66-11.27-29.04-29.56.89-4.52 13.04-27.53 13.04-27.53l-3.01 8.12z"
      ></path>
      <path
        fill="#fff"
        d="M172.442 36.545c-7.26-.38-9.16-7.95-8.69-8.17.46-.12 13.41 5.63 13.53 6.08.02.43-2.8 2.35-4.84 2.09z"
      ></path>
      <path
        fill="#3F4249"
        d="M206.062-15.934c1.83-9.42-6.32-18.92-18.19-21.22-11.87-2.3-22.98 3.47-24.81 12.88-1.82 9.42 6.32 18.92 18.19 21.22 11.88 2.31 22.98-3.46 24.81-12.88z"
      ></path>
      <path
        fill="#3F4249"
        d="M157.822-31.615c-7.44-1.44-14.94 5-16.76 14.38-1.82 9.38 2.34 20.19 9.78 21.63 7.44 1.44 15.33-7.03 17.15-16.41 1.83-9.38-2.73-18.16-10.17-19.6z"
      ></path>
      <path
        d="M144.592 19.566s2.49.68 4.05-.12l-5.55 5.77 1.5-5.65z"
        opacity="0.15"
      ></path>
      <path
        fill="#F79898"
        d="M151.392 4.414s-3.16-6.21-9.48-4.81c-7.17 1.63-8.71 16.85 2.29 19.89 5.73 1.61 5.23-2.01 5.14-6.76.95-5.48 2.05-8.32 2.05-8.32z"
      ></path>
      <path
        d="M140.872 3.425c1.32-.55 2.44-.64 3.37-.25.93.38 1.68 1.23 2.13 2.53.45 1.3.58 2.73.28 4.29l-.15 1.28a.68.68 0 01-.81.55.68.68 0 01-.55-.81l.15-1.28c.08-.39.26-2.37-.22-3.57-.33-.87-.82-1.47-1.37-1.78-.56-.21-1.38-.17-2.25.17-.33.14-.8-.05-.93-.38-.17-.14 0-.51.35-.75z"
        opacity="0.3"
      ></path>
      <path
        fill="#F79898"
        d="M197.222-3.485c-1.34.65-4.13 1.33-4.13 1.33-.29.05-.72-.08-.78-.47-.07-.39.08-.72.47-.78 0 0 3.93-1.41 4.31-1.57.73-.42 1.88-.96 3.22-2 .26-.24.67-.21.91.05s.21.67-.05.91c-1.3 1.22-3.49 2.31-3.95 2.53z"
      ></path>
      <path
        d="M179.832 58.054l.9-9.22c-3.85 2.48-9.67 3.86-17.85 1.37 0 0-5.58-1.51-10.99-5.94-.38-.36-.64-.44-.96-.27-.26.24-.22.53-.02.79.99 1.24 5.07 4.37 11.96 6.77 8.65 8.26 16.96 6.5 16.96 6.5z"
        opacity="0.15"
      ></path>
      <path
        fill="#21252B"
        d="M198.172 13.924l-10.58-2.05-1.46-.28-9.22-1.79-.1-.02-11.94-2.31a3.54 3.54 0 00-4.17 2.82l-.53 2.72c-1.02 5.24 2.43 10.34 7.67 11.36 5.24 1.02 10.34-2.43 11.36-7.67l.53-2.72c.15-.78.01-1.61-.36-2.29l3.69.72c-.6.49-.94 1.23-1.09 2l-.53 2.72c-1.02 5.24 2.43 10.34 7.67 11.36 5.24 1.02 10.34-2.43 11.36-7.67l.53-2.72a3.57 3.57 0 00-2.83-4.18zm-19.8-.21l-.53 2.72c-.87 4.47-5.17 7.46-9.64 6.59-4.47-.87-7.46-5.17-6.59-9.64l.53-2.72c.23-1.17 1.39-1.95 2.55-1.72l11.94 2.31.1.02c1.06.22 1.86 1.28 1.64 2.44zm20.73 6.84c-.87 4.47-5.17 7.46-9.64 6.59-4.47-.87-7.46-5.17-6.59-9.64l.53-2.72c.23-1.17 1.39-1.95 2.55-1.72l1.46.28 10.58 2.05c1.17.23 1.95 1.39 1.72 2.55l-.61 2.61z"
      ></path>
      <path
        d="M814.74 565.689h-83.97c-.49 0-.79-.4-.79-.79s.4-.79.79-.79h83.97c.49 0 .79.4.79.79s-.3.79-.79.79z"
        opacity="0.15"
        transform="translate(-600.038 -291.605)"
      ></path>
      <path
        fill="#FB9C9C"
        d="M309.782 101.505c-.08 2.99 2.3 5.38 5.18 5.46l7.31.19 1.32 17.77c.08 1.33 1.27 2.47 2.6 2.51 1.44.04 2.58-1.04 2.72-2.48l1.54-16.92c.01-.22.23-.44.57-.43.22.01.44.23.54.46l.68 16.76c.07 1.55 1.26 2.8 2.81 2.84 1.55.04 2.91-1.14 2.95-2.81l.99-16.94-.98 16.5c-.16 1.77 1.36 3.36 3.13 3.41 1.66.04 3.02-1.14 3.29-2.8l1.98-16.8-1.43 12.49c-.15 1.55 1.03 3.02 2.58 3.06 1.33.03 2.46-.82 2.72-2.15l2.38-10.58c.63-2.76-.3-5.66-2.36-7.49-8.34-7.64-20.49-9.4-30.6-4.22l-9.47 1.64c-.21-.13-.44.2-.45.53z"
      ></path>
      <path
        d="M938.4 399.5l-.92 15.6 1.55.09.92-15.6-1.55-.09zM945.53 400.68l-1.37 12.57 1.54.17 1.37-12.57-1.54-.17z"
        opacity="0.15"
        transform="translate(-600.038 -291.605)"
      ></path>
      <path
        d="M322.272 107.254l-7.31-.19 4.54-4.1c.91-.86 2.45-.27 2.52 1.06l.25 3.23z"
        opacity="0.2"
      ></path>
      <path
        fill="#21252B"
        d="M232.292 283.044h-142.4l-16.62-116.06 27.58-68.94 19.32-34.2 12.83.58s-1.83 13.52 24.73 17.18c22.63 3.13 21.55-15.93 21.55-15.93l40.52 2.69 12.49 214.68z"
      ></path>
      <path
        fill="#F79898"
        d="M178.742 72.975c1.37-7.05-7.75-14.74-20.36-17.19-12.61-2.45-23.94 1.29-25.31 8.33-1.37 7.05 7.75 14.74 20.36 17.19 12.61 2.45 23.94-1.29 25.31-8.33zM67.162 81.895l-18.51 101.84a25.734 25.734 0 002.71 16.89l51.1 93.89s-4.59 13.38 6.25 24.13c0 0 13.46-12.3 19.29-24.77l-39.41-100.62 12.87-44.06-34.3-67.3z"
      ></path>
      <path
        fill="#21252B"
        d="M208.392 145.354l40.13-1.93-8.66-55.38c-1.72-11.03-10.9-19.37-22.04-20.04l-38.73-2.33 29.3 79.68zM116.742 98.325l-16.41 54.63h-46.5l13.32-71.05c2.18-11.64 12.6-19.89 24.42-19.35l41.42 1.88-16.25 33.89z"
      ></path>
      <path
        d="M215.052 116.895a84.15 84.15 0 018.99 32.97l7.77 125.14 19.01 200.2c.5 5.3.34 10.65-.49 15.91l-27.15 172.58h-19.09l29.33-180.44-21.33-200.21-4.35-180.48 7.31 14.33zM115.172 103.565l-26.59 89.7 31.49 80.37h14.13l-25.42-85.04 6.39-85.03zM156.142 336.405l-.03.02a28.13 28.13 0 00-14.4 23.15l-15.38 304.11h17.57l14.92-325.37a4.39 4.39 0 013.05-3.99c-2 .39-3.94 1.09-5.73 2.08zM134.202 273.635l5.13 15.81s-15.36 20.29-30.61 29.2c0 0 13.83-12.75 19.29-24.77l-7.93-20.24h14.12z"
        opacity="0.15"
      ></path>
      <path
        fill="#3F4249"
        d="M-232.138 318.616l36.16 19.77-36.16-19.77z"
      ></path>
      <path
        fill="#B2B6BB"
        d="M766.112 721.345h-1132.42c-.35 0-.59-.24-.59-.59s.24-.59.59-.59h1132.54c.35 0 .59.24.59.59s-.35.59-.71.59z"
      ></path>
      <path
        fill="#21252B"
        d="M170.902 18.896a2.469 2.469 0 100-4.94 2.469 2.469 0 100 4.94z"
      ></path>
      <path
        fill="#fff"
        d="M172.122 16.346a.49.49 0 10-.001-.982.49.49 0 00.001.982z"
      ></path>
      <path
        fill="#21252B"
        d="M190.112 22.716c1.36 0 2.47-1.11 2.47-2.47s-1.11-2.47-2.47-2.47-2.47 1.11-2.47 2.47 1.11 2.47 2.47 2.47z"
      ></path>
      <path
        fill="#fff"
        d="M191.332 20.164a.49.49 0 10-.001-.982.49.49 0 00.001.982z"
      ></path>
      <path
        d="M777.88 319.44l-3.05-.84c-.36-.12-.54-.49-.51-.88.12-.36.49-.54.88-.51l3.05.84c.75.16 1.6-.26 1.76-1.01.16-.75-.26-1.6-1.01-1.76-.36-.12-.54-.49-.51-.88.03-.39.49-.54.88-.51 1.48.41 2.37 1.93 2.05 3.43-.55 1.63-2.07 2.52-3.54 2.12z"
        opacity="0.15"
        transform="translate(-600.038 -291.605)"
      ></path>
    </svg>
  );
}

export default ToDoAnimation;
