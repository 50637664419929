import "../../App.css";
import "../../Layouts/DesktopLayout.css";
import "./View.css";
import { Button, Grid, Message, Segment, Tab } from "semantic-ui-react";
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { FunctionComponent, useContext, useState } from "react";
import { StateDispatch, StateValue } from "../Context";
import { isDesktop, isIOS, isIPad13 } from "react-device-detect";
import { useTranslation } from "react-i18next";

export const Start: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useContext(StateDispatch);
  const state = useContext(StateValue);
  const [activeIndex, setActiveIndex] = useState(0);
  const panes = [
    {
      menuItem: t("Start.Introduction"),
      render: () => (
        <Tab.Pane attached={false} className="pl-1 pr-0 pt-0" style={{ width: "100%", height: "100%", border: "none", borderRadius: 0, boxShadow: "none" }}>
          <h1 className="service-item-header">{t("Start.Header")}</h1>
          <h2 className="service-item-subheader">{t("Start.Subheader_1")}</h2>
          <p>{t("Start.DTS_General_Explanation")}</p>
          <h2 className="service-item-subheader">{t("Start.Subheader_2")}</h2>
          <ol>
            <li>
              {t("Start.How_It_Works_Country")}
              <ul>
                <li>{t("Start.How_It_Works_Country_Criipto")}</li>
                <li>{t("Start.How_It_Works_Country_Document_Scan")}</li>
              </ul>
            </li>
          </ol>
          {/* <p>Depending on the country an electronic identification (eID) is available. Alternatively a document scan is required.</p> */}
          <Grid>
            <Grid.Row columns={(isDesktop && !isIPad13) ? 2 : 1} className="bottom-padding" style={{ padding: "0!important" }}>
              <Grid.Column>
                <h3>{t("Start.How_It_Works_Criipto_Heading")}</h3>
                <ol start={2}>
                  <li>{t("Start.How_It_Works_Criipto_1")}</li>
                  <li>{t("Start.How_It_Works_General_1")}</li>
                  <li>{t("Start.How_It_Works_General_2")}</li>
                </ol>
              </Grid.Column>
              <Grid.Column>
                <h3>{t("Start.How_It_Works_Document_Heading")}</h3>
                <ol start={2}>
                  <li>{t("Start.How_It_Works_Document_1")}</li>
                  <li>{t("Start.How_It_Works_Document_2")}</li>
                  <li>{t("Start.How_It_Works_General_1")}</li>
                  <li>{t("Start.How_It_Works_General_2")}</li>
                </ol>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <h2 className="service-item-subheader">{t("Start.Subheader_4")}</h2>
          <p>{t("Start.Registration_Duration")}</p>
          <Message warning className="mt-5" color="blue" size="small" style={{ backgroundColor: "transparent" }}>
            <Message.Header>{t("Start.Please_Note")}</Message.Header>
            <p>{t("Start.Explanation_Box_1")}</p>
          </Message>

          {!isDesktop && (
            <Message positive size="tiny" style={{ minHeight: "79px" }} className="mb-4">
              <Message.Header>
                {t("Start.Please_Note")}
              </Message.Header>
              <p>
                {t("Start.By_Continuing")} <a href="https://3092d62c-0e21-420a-af63-f4de54b0e502.filesusr.com/ugd/7ac32a_474e580432944b7f89dfd1a6c420b19d.pdf" target="_blank">{t("Start.Terms")}</a> {t("Start.And_The")} <a href="https://3092d62c-0e21-420a-af63-f4de54b0e502.filesusr.com/ugd/7ac32a_2ed44c436a354647a30107c8b8a326ae.pdf" target="_blank">{t("Start.Privacy")}</a> {t("Start.Policy")}{`${isIOS ? ` ${t("Start.Press_And_Hold")}` : ""}`}.
              </p>
            </Message>
          )}
        </Tab.Pane>),
    },
    {
      menuItem: t("Start.Data_safety"),
      render: () => (
        <Tab.Pane attached={false} className="pl-1 pr-0 pt-0" style={{ border: "none", borderRadius: 0, boxShadow: "none" }}>
          <h1 className="service-item-header">{t("Start.Subheader_3")}</h1>
          <p>{t("Start.Data_Safety")}</p>
          <h2 className="service-item-subheader">
            {t("Start.Third_Party")}
          </h2>
          <p>{t("Start.Third_Party_General")}</p>
          <h3>{t("Start.Third_Party_Mitek_Heading")}</h3>
          <p>{t("Start.Third_Party_Mitek_Text")} <a target="_blank" href="https://www.miteksystems.com/privacy-policy">{t(isIOS ? "Start.Here_IOS" : "Start.Here")}</a>.</p>
          <h3>{t("Start.Third_Party_Criipto_Heading")}</h3>
          <p>{t("Start.Third_Party_Criipto_Text")} <a target="_blank" href="https://www.criipto.com/legal/privacy">{t(isIOS ? "Start.Here_IOS" : "Start.Here")}</a>.</p>

          {!isDesktop && (
            <Message positive size="tiny" style={{ minHeight: "79px" }} className="mb-4">
              <Message.Header>
                {t("Start.Please_Note")}
              </Message.Header>
              <p>
                {t("Start.By_Continuing")} <a href="https://3092d62c-0e21-420a-af63-f4de54b0e502.filesusr.com/ugd/7ac32a_474e580432944b7f89dfd1a6c420b19d.pdf" target="_blank">{t("Start.Terms")}</a> {t("Start.And_The")} <a href="https://3092d62c-0e21-420a-af63-f4de54b0e502.filesusr.com/ugd/7ac32a_2ed44c436a354647a30107c8b8a326ae.pdf" target="_blank">{t("Start.Privacy")}</a> {t("Start.Policy")}{`${isIOS ? ` ${t("Start.Press_And_Hold")}` : ""}`}.
              </p>
            </Message>
          )}
        </Tab.Pane>),
    }
  ]



  const renderView = () => {
    switch (state.view) {
      default:
        return (
          <>
            <span style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "calc(100% - 5em)", paddingBottom: "1em", marginBottom: "5em", overflowY: "auto", overflowX: "hidden" }}>
              <Tab activeIndex={activeIndex} onTabChange={(e, d) => setActiveIndex(parseInt(d.activeIndex?.toString() ?? "0"))} id="tab-menu" menu={{ secondary: true }} panes={panes} />
            </span>
          </>
        )
    }
  }

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${activeIndex}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="transition-container" style={{ backgroundSize: "clamp(20%, 60%, 400px", backgroundPosition: "right bottom", backgroundRepeat: "no-repeat", maxHeight: isDesktop && !isIPad13 ? '50vh' : '', overflowY: "auto", height: "calc(90% - 5em)", overflowX: "hidden" }}>
            {renderView()}
          </div>
        </CSSTransition>
      </SwitchTransition>

      <Segment basic className={`service-item-button-container mt-0`} style={{ width: "calc(100% - 2em)" }}>
        <Button color="green" floated="right" type="submit" onClick={() => { dispatch({ type: "setCurrent", data: state.current + 1 }); }} >
          {t("General_UI.Start")}
        </Button>
      </Segment>
    </>
  );
};
