import * as Yup from "yup";

export const CriiptoAcrFormValidation = () => Yup.object().shape({
  countryAlpha2: Yup.string()
    .required()
    .max(2),
  eidName: Yup.string()
    .required(),
  acrValue: Yup.string()
    .required(),
  comment: Yup.string(),
  enabled: Yup.boolean(),
})