import { useTranslation } from "react-i18next";
import { Divider, Header } from "semantic-ui-react";
import { CriiptoAcrForm } from "../../../../Components/secure/criiptoAcr/CriiptoAcrForm";

export const CreateCriiptoAcr = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header as="h1">{t("Criipto.Acr.New")}</Header>
      <Divider />
      <CriiptoAcrForm />
    </>
  )
}