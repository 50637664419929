import axios from "axios";
import { Button, Segment } from "semantic-ui-react"
import { FunctionComponent, useContext, useState } from "react"
import { ReactComponent as SuccessSvg } from "../../Assets/SVG/Success.svg";
import { StateValue } from "../Context";
import { deleteCookie } from "../../Services/ReadWriteCookie";
import { renderToStaticMarkup } from "react-dom/server";
import { useTranslation } from "react-i18next";

export const SuccessMobile: FunctionComponent = () => {
  const { t } = useTranslation();
  const state = useContext(StateValue);
  const [didError, setDidError] = useState(false);
  const [didRedirect, setDidRedirect] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const background = encodeURIComponent(renderToStaticMarkup(<SuccessSvg fill={state.org.theme.themeColor} />));

  const complete = () => {
    setHasSubmitted(true);
    if (didRedirect) {
      window.location.href = state.targetUrl;
    } else {
      setDidError(false);
      axios.post(`/api/frontend/completed/${state.ctxId}`).then((res) => {
        if (res.status == 200) {
          var ctxId = state.ctxId;
          localStorage.clear();
          deleteCookie("X-Api-Key");
          deleteCookie("ctxId");
          deleteCookie("invitationCode");
          if (state.targetUrl.length < 1) {
            window.close();
          } else {
            setDidRedirect(true);
            window.location.href = state.targetUrl;
          }
        } else {
          setDidError(true);
        }
      });
    }
  };

  return (
    <>
      <span style={{ background: `url("data:image/svg+xml,${background}")`, backgroundSize: "clamp(20%, 60%, 400px", backgroundPosition: "right bottom", backgroundRepeat: "no-repeat", height: "calc(100% - 5em)" }}>
        <h1 className="service-item-header"> {t("Success.Title")} </h1>
        <h2 className="service-item-subheader" style={{ fontSize: "large" }}>
          {t("Success.Subtitle")}
        </h2>
        <h3 className="service-item-explanation mt-5">
          {t("Success.Submit", { submit_button: t("Success.Submit_Button") })}
        </h3>
      </span>

      <Segment basic className={`service-item-button-container${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""} mt-0`}>
        <Button
          color={didError ? "red" : "green"}
          onClick={() => complete()}
          floated="right"
          type="button"
          disabled={hasSubmitted}
        >
          {didError ? t("General_UI.Something_Went_Wrong") : t("Success.Submit_Button")}
        </Button>
      </Segment>
    </>
  )
}