import { EIssuanceReference } from "../../Enums/EIssuanceReference";
import { addMonths, format } from "date-fns";

export interface IBreakdownErrorStatistics {
  errorBreakdowns: IBreakdownErrors[]
}

export interface IBreakdownErrors {
  issuanceReference: EIssuanceReference;
  issuanceErrors: IErrorStatsIssuanceErrors[];
}

export interface IErrorStatsIssuanceErrors {
  weekNumber: number;
  errorCount: number;
}

export interface IIssuanceReferenceColor {
  issuanceReference: EIssuanceReference;
  color: string
}

const issuanceItem = (issuanceReference: EIssuanceReference, color: string): IIssuanceReferenceColor => ({ issuanceReference, color });


export const IssuanceReferenceColor = (): IIssuanceReferenceColor[] => {
  let list: IIssuanceReferenceColor[] = [];
  list.push(issuanceItem(EIssuanceReference.None, "#000000"));
  list.push(issuanceItem(EIssuanceReference.InvalidDocument, "#842a62"));
  list.push(issuanceItem(EIssuanceReference.InvalidSelfie, "#6373ed"));
  list.push(issuanceItem(EIssuanceReference.IsExpired, "#de6f07"));
  list.push(issuanceItem(EIssuanceReference.MissingData, "#f3a6a7"));
  list.push(issuanceItem(EIssuanceReference.NotAllowedCountry, "#454b3a"));
  list.push(issuanceItem(EIssuanceReference.NotRetrievedFromAgent, "#d2ad43"));
  list.push(issuanceItem(EIssuanceReference.AgentHandoffPending, "#4003b5"));
  list.push(issuanceItem(EIssuanceReference.MaxAddToChainAttemtps, "#7671b1"));
  list.push(issuanceItem(EIssuanceReference.Other, "#bdcc8a"));
  list.push(issuanceItem(EIssuanceReference.AgentResponseFaulty, "#4f17b4"));
  list.push(issuanceItem(EIssuanceReference.AgentTimeout, "#8e4b01"));
  list.push(issuanceItem(EIssuanceReference.AgentResponseInvalid, "#239e05"));
  list.push(issuanceItem(EIssuanceReference.CreateIdentityObject, "#b83c6a"));
  list.push(issuanceItem(EIssuanceReference.SubmissionToChain, "#af3a0c"));
  list.push(issuanceItem(EIssuanceReference.RetrieveSubmission, "#cc6cb2"));
  return list;
}

export interface IGetBreakdownErrorStatistics {
  start: string;
  end: string;
}

export class GetBreakdownErrorStatisticsFormValues {
  start: string | null = format(addMonths(new Date(), -2), "yyyy-MM-dd'T'00:00");
  end: string | null = format(new Date(), "yyyy-MM-dd'T'00:00");

  constructor(init?: IGetBreakdownErrorStatistics) {
    Object.assign(this, init);
    if (init) {
      this.start = init.start === null ? format(addMonths(new Date(), -2), "yyyy-MM-dd'T'00:00") : format(new Date(init.start), "yyyy-MM-dd'T'00:00");
      this.end = init.end === null ? format(new Date(), "yyyy-MM-dd'T'00:00") : format(new Date(init.end), "yyyy-MM-dd'T'00:00");
    }
  }
}