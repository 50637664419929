import { ECriiptoType } from "../../../../../Enums/ECriiptoType";
import { CriiptoServicePage } from "../CriiptoSerivcePage";

interface IProps {
  initCriiptoBackend: () => void;
  chosenCriiptoType: ECriiptoType;
}

export const CriiptoStartPage = ({ initCriiptoBackend, chosenCriiptoType }: IProps) => {
  return <CriiptoServicePage isMobile={true} criiptoType={chosenCriiptoType} initCriiptoBackend={initCriiptoBackend} />
}