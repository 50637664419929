import { GetBreakdownStatisticsFormValues, IBreakdownStatistics } from "../models/statistics/IBreakdownStatistics";
import { IGeneralStatistics } from "../models/statistics/IGeneralStatistics";
import { IUserScanStatistics } from "../models/statistics/IUserScanStatistics";
import { RootStore } from "./RootStore";
import { makeAutoObservable, runInAction } from "mobx"

export default class StatisticsStore {
  rootStore: RootStore
  loading: boolean = false;
  loadingBreakdownStatistics: boolean = false;
  loadingScanTimeStatistics: boolean = false;
  loadingErrorStatistics: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this)
  }

  GetGeneralStatistics = async () => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: IGeneralStatistics } = await this.rootStore.baseApiService.get(`statistics/general`);
    if (success) {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  GetBreakdownStatistics = async (values: GetBreakdownStatisticsFormValues) => {
    runInAction(() => {
      this.loadingBreakdownStatistics = true;
    });

    const { success, data }: { success: boolean; data: IBreakdownStatistics; } = await this.rootStore.baseApiService.get(`statistics/breakdown-stats?start=${values.start}&end=${values.end}`);
    if (success) {
      runInAction(() => {
        this.loadingBreakdownStatistics = false;
      })
      return data;
    }

    runInAction(() => {
      this.loadingBreakdownStatistics = false;
    })
  }

  GetScanTimeStatistics = async () => {
    runInAction(() => {
      this.loadingScanTimeStatistics = true;
    });
    const { success, data }: { success: boolean; data: IUserScanStatistics } = await this.rootStore.baseApiService.get('statistics/scan-time');
    if (success) {
      runInAction(() => {
        this.loadingScanTimeStatistics = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loadingScanTimeStatistics = false;
      })
    }
  }
}