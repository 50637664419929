import { Button, Container, Divider, Form, Grid, Header, Icon, Popup, Segment, Table } from "semantic-ui-react";
import { DatePicker } from "../../Common/form/DatePicker";
import { ECriiptoType } from "../../../Enums/ECriiptoType";
import { Field, Formik } from "formik";
import { GetBreakdownStatisticsFormValues, IBreakdownStatistics } from "../../../models/statistics/IBreakdownStatistics";
import { RootStoreContext } from "../../../stores/RootStore";
import { Spinner } from "../../Common/spinner/Spinner";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


export const AnalysisBreakdown = observer(() => {
  const { t } = useTranslation();
  const rootStore = useContext(RootStoreContext);
  const { GetBreakdownStatistics, loadingBreakdownStatistics } = rootStore.statisticsStore;
  const [stats, setStats] = useState<IBreakdownStatistics>();
  const [formValues, setFormValues] = useState(new GetBreakdownStatisticsFormValues());

  const loadStats = async (values: GetBreakdownStatisticsFormValues) => {
    setStats(await GetBreakdownStatistics(values))
  };

  useEffect(() => {
    loadStats(formValues);
  }, [])

  const handleSubmit = (values: GetBreakdownStatisticsFormValues) => {
    loadStats(values)
  }

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Segment padded className='bg-lightest-grey'>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Header as="h3">The retrieved data will be between the selected startdate, at 00:00, and the selected enddate, at 23:59</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Field
                      component={DatePicker}
                      required
                      name="start"
                      label="StartDate"
                      activationDate={formik.values.start}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Field
                      component={DatePicker}
                      required
                      name="end"
                      label="EndDate"
                      activationDate={formik.values.end}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row textAlign="right">
                  <Grid.Column>
                    <Button type="submit" primary>
                      {t("Button.Submit")}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Form>
        )}
      </Formik>
      <Divider />
      {loadingBreakdownStatistics
        ? <Spinner />
        : !stats
          ? <h2>No data found</h2>
          : (
            <>
              <Table basic='very' collapsing>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                    <Table.HeaderCell>Conversion measured from wallet</Table.HeaderCell>
                    <Table.HeaderCell>Conversion measured within CCP</Table.HeaderCell>
                    <Table.HeaderCell>Conversion of unique users</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Number of calls</Table.Cell>
                    <Table.Cell>{stats.totalTransactions}</Table.Cell>
                    <Table.Cell textAlign="center">{(stats.totalTransactions / stats.totalTransactions * 100).toFixed(0)}%</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Filled in country</Table.Cell>
                    <Table.Cell>{stats.completedCountry}</Table.Cell>
                    <Table.Cell textAlign="center">{(stats.completedCountry / stats.totalTransactions * 100).toFixed(0)}%</Table.Cell>
                    <Table.Cell textAlign="center">{(stats.completedCountry / stats.completedCountry * 100).toFixed(0)}%</Table.Cell>
                  </Table.Row>
                  {/* <Table.Row>
                  <Table.Cell>Took selfie</Table.Cell>
                  <Table.Cell>{stats.scannedDocuments}</Table.Cell>
                  <Table.Cell textAlign="center">{stats.scannedDocuments / stats.totalTransactions * 100}%</Table.Cell>
                  <Table.Cell textAlign="center">{stats.scannedDocuments / stats.completedCountry * 100}%</Table.Cell>
                  <Table.Cell textAlign="center">{stats.scannedDocuments / stats.startedMitekCall * 100}%</Table.Cell>
                </Table.Row> */}
                  <Table.Row>
                    <Table.Cell>Started call to Mitek</Table.Cell>
                    <Table.Cell>{stats.startedMitekCall}</Table.Cell>
                    <Table.Cell textAlign="center">{(stats.startedMitekCall / stats.totalTransactions * 100).toFixed(0)}%</Table.Cell>
                    <Table.Cell textAlign="center">{(stats.startedMitekCall / stats.completedCountry * 100).toFixed(0)}%</Table.Cell>
                    <Table.Cell textAlign="center">{(stats.startedMitekCall / stats.startedMitekCall * 100).toFixed(0)}%</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Total calls to Mitek</Table.Cell>
                    <Table.Cell>{stats.totalMitekCalls}</Table.Cell>
                    <Table.Cell textAlign="center">na</Table.Cell>
                    <Table.Cell textAlign="center">na</Table.Cell>
                    <Table.Cell textAlign="center">na</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Total calls to Mitek agent</Table.Cell>
                    <Table.Cell>{stats.totalMitekAgentCalls}</Table.Cell>
                    <Table.Cell textAlign="center">na</Table.Cell>
                    <Table.Cell textAlign="center">na</Table.Cell>
                    <Table.Cell textAlign="center">na</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Succesfully completed Mitek</Table.Cell>
                    <Table.Cell>{stats.succesfullMitekChecks}</Table.Cell>
                    <Table.Cell textAlign="center">{(stats.succesfullMitekChecks / stats.totalTransactions * 100).toFixed(0)}%</Table.Cell>
                    <Table.Cell textAlign="center">{(stats.succesfullMitekChecks / stats.completedCountry * 100).toFixed(0)}%</Table.Cell>
                    <Table.Cell textAlign="center">{(stats.succesfullMitekChecks / stats.startedMitekCall * 100).toFixed(0)}%</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Header.Content>Not (yet) accepted by the Concordium chain,</Header.Content>
                      <Header.Subheader>or not pulled by the wallet</Header.Subheader>
                    </Table.Cell>
                    <Table.Cell>{stats.accountsNotAcceptedByChain}</Table.Cell>
                    <Table.Cell textAlign="center">{(stats.accountsNotAcceptedByChain / stats.totalTransactions * 100).toFixed(0)}%</Table.Cell>
                    <Table.Cell textAlign="center">{(stats.accountsNotAcceptedByChain / stats.completedCountry * 100).toFixed(0)}%</Table.Cell>
                    <Table.Cell textAlign="center">{(stats.accountsNotAcceptedByChain / stats.startedMitekCall * 100).toFixed(0)}%</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Amount of created accounts</Table.Cell>
                    <Table.Cell>{stats.accountsCreated}</Table.Cell>
                    <Table.Cell textAlign="center">{(stats.accountsCreated / stats.totalTransactions * 100).toFixed(0)}%</Table.Cell>
                    <Table.Cell textAlign="center">{(stats.accountsCreated / stats.completedCountry * 100).toFixed(0)}%</Table.Cell>
                    <Table.Cell textAlign="center">{(stats.accountsCreated / stats.startedMitekCall * 100).toFixed(0)}%</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <Divider />
              <Header as="h2">Criipto statistics</Header>
              <Grid columns={2} style={{ paddingBottom: "3rem" }}>
                <Grid.Column width={6}>
                  <h3>
                    Total
                    <Popup trigger={<Icon name="info circle" />} wide>
                      This is a sum of all Criipto Service attempts
                      <br />
                      Where the difference between (Success + Failed) &amp; Started are users which have stopped while at the Criipto page.
                    </Popup>
                  </h3>
                  <Table basic='very' collapsing>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Started</Table.HeaderCell>
                        <Table.HeaderCell>Success</Table.HeaderCell>
                        <Table.HeaderCell>Failed</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>{stats.criiptoStats.total}</Table.Cell>
                        <Table.Cell>{stats.criiptoStats.success}</Table.Cell>
                        <Table.Cell>{stats.criiptoStats.failed}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
                <Grid.Column>
                  <h3>
                    Categorized
                    <Popup trigger={<Icon name="info circle" />} wide>
                      These are the amount of times a user has ended with the specific Criipto Service, both success &amp; failed.
                      <br />
                      Multiple attempts by the same user cannot be shown here.
                    </Popup>
                  </h3>
                  <Table basic='very' collapsing>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Criipto Service</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {stats.criiptoStats.criiptoStats.map((criipto) => (
                        <Table.Row>
                          <Table.Cell>{t(`Enum.CriiptoType.${ECriiptoType[criipto.criiptoType]}`)}</Table.Cell>
                          <Table.Cell>{criipto.amount}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid>
            </>
          )}

    </Container>
  )
})