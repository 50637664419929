import { observer } from "mobx-react-lite";
import { useContext, useState, useEffect } from "react";
import { isDesktop, isIPad13 } from "react-device-detect";
import { ECriiptoPage } from "../../../../Enums/ECriiptoPage";
import { getCriiptoTransactionStatus } from "../../../../Services/Backend";
import { isLocalhost } from "../../../../utils/General";
import { StateDispatch, StateValue } from "../../../Context";
import { MitekCheckProps } from "../../Mitek/MitekCheckComponent";
import { CriiptoCheckDesktop } from "./Desktop/CriiptoCheckDesktop";
import { CriiptoCheckMobile } from "./Mobile/CriiptoCheckMobile";
import { CriiptoCountrySupportedGetDto } from "../../../../Types";
import { Spinner } from "../../../Common/spinner/Spinner";
import { ECriiptoType } from "../../../../Enums/ECriiptoType";

interface IProps {
  props: MitekCheckProps;
  criiptoAcr: CriiptoCountrySupportedGetDto;
}

export const CriiptoComponent = observer(({ props, criiptoAcr }: IProps) => {
  const dispatch = useContext(StateDispatch);
  const state = useContext(StateValue);
  const [currentPage, setCurrentPage] = useState<ECriiptoPage>(ECriiptoPage.Loading);
  const [chosenCriiptoType, setChosenCriiptoType] = useState<ECriiptoType>(ECriiptoType.NONE)
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadCriiptoStatus = async () => {
      await getCriiptoTransactionStatus(state.ctxId).then((res) => {
        if (res.data === null || !res.data.callPerformed) setCurrentPage(ECriiptoPage.TypeSelection);
        else if (res.data.callPerformed && res.data.callSuccess) setCurrentPage(ECriiptoPage.Completed);
        else if (res.data.callPerformed && !res.data.callSuccess) setCurrentPage(ECriiptoPage.Error);
        else setCurrentPage(ECriiptoPage.TypeSelection);
        setIsLoading(false);
      });
    }
    setIsLoading(true);
    loadCriiptoStatus();
  }, [state.ctxId])

  const onSuccess = () => {
    dispatch({ type: "setCurrent", data: state.current + 1 });
  }

  const initCriiptoBackend = () => {
    var target = ""
    if (isLocalhost()) {
      target = "https://localhost:5001"
    } else {
      target = window.location.origin;
    }
    // Has to be done like this, in order to ensure the user is redirected to the Criipto environment.
    window.location.href = (`${target}/api/criipto?ctxId=${state.ctxId}&serviceId=10`);
  }

  if (isLoading) return <Spinner />

  return (isDesktop && !isIPad13) ? (
    <CriiptoCheckDesktop
      mitekProps={props}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      onSuccess={onSuccess}
      initCriiptoBackend={initCriiptoBackend}
      criiptoTypes={criiptoAcr.criiptoTypes}
      chosenCriiptoType={chosenCriiptoType}
      setChosenCriiptoType={setChosenCriiptoType}
    />
  ) : (
    <CriiptoCheckMobile
      mitekProps={props}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      onSuccess={onSuccess}
      initCriiptoBackend={initCriiptoBackend}
      criiptoTypes={criiptoAcr.criiptoTypes}
      chosenCriiptoType={chosenCriiptoType}
      setChosenCriiptoType={setChosenCriiptoType}
    />
  )
})