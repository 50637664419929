import * as Yup from "yup";
import { PasswordValidation } from "../../../utils/Validations"

export const AdminValidation = (isCreate: boolean) => Yup.object().shape({
  email: Yup.string()
    .email()
    .required(),
  firstName: Yup.string()
    .required(),
  lastName: Yup.string()
    .required(),
  lastNamePrefix: Yup.string()
    .nullable(),
  password: Yup.object().shape({
    password: Yup.string()
      .concat(isCreate
        ? Yup.string().required()
        : Yup.string())
      .matches(PasswordValidation, "Password.Rules"),
    confirmPassword: Yup.string()
      .when("password", (password: string, schema: any) => {
        if (password || isCreate) return schema.required()
      }).oneOf([Yup.ref('password'), null], "Password.Confirm_Invalid")
  })
})