import MitekCheckSubmitExplanation from './MitekCheckSubmitExplanation';
import MitekHeader from './MitekHeader';
import { Button } from 'semantic-ui-react';
import { MitekRequestBuilder } from '../../../../Types';
import { state } from '../../../Context';
import { useTranslation } from 'react-i18next';

type IProps = {
  postBackend: () => void;
  setCurrentPage: (page: number) => void;
  props: any;
  request: MitekRequestBuilder | any;
  state: state;
  type: "PASSPORT" | "ID" | "DRIVERSLICENSE" | undefined;
}

const MitekSubmit = (props: IProps) => {

  const { t } = useTranslation();

  return (
    <>
      <MitekHeader />
      <MitekCheckSubmitExplanation postButton={<Button color="green" floated="right" onClick={() => {
        if (props.props.match.params.handoff !== undefined) {
          props.postBackend();
          props.setCurrentPage(7)
        } else {
          props.postBackend();
          props.setCurrentPage(6)
        }
      }}>
        {t("General_UI.Submit")}
      </Button>} frontImage={props.request.front.response.imageData.substring(props.request.front.response.imageData.indexOf(",") + 1)} backImage={props.type !== "PASSPORT" ? props.request.back.response.imageData.substring(props.request.back.response.imageData.indexOf(",") + 1) : undefined} props={props.props} themeColor={props.state.org.theme.themeColor} />
    </>
  )
};

export default MitekSubmit;
