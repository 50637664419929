import MitekHeader from './MitekHeader';
import { Segment, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const MitekDocumentExpired = () => {

  const { t } = useTranslation();

  return (
    <>
      <MitekHeader />
      <Segment className="mitek" basic style={{ height: "calc(100% - 5em - 4em)", width: "100%", margin: 0 }}>
        <h3>{t("Mitek.Error.Something_Went_Wrong_Checking_Documents")}
        </h3>
        <br />
        <h4>
          {t("Mitek.Error.Document_Expired")}
        </h4>
        <Button color="red" basic floated='left' onClick={() => window.location.reload()}>{t("General_UI.Retry")}</Button>
      </Segment>
      <Segment className="service-item-button-container mitek" style={{ height: "10%", margin: 0, width: "100vw", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
        <Button loading color="green" floated="right" disabled>
          {t("General_UI.Next")}
        </Button>
      </Segment>
    </>
  )
};

export default MitekDocumentExpired;
