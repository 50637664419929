import Roles from "../../../Enums/Roles"
import { AdminHome } from "../admin/AdminHome"
import { ConcordiumManagerHome } from "../concordiumManager/ConcordiumManagerHome"
import { Divider, Header } from "semantic-ui-react"
import { DtsManagerHome } from "../dtsManager/DtsManagerHome"
import { RenderGuard } from "../../../Components/Common/guards/RenderGuard"

export const DashboardHome = () => {
  return (
    <>
      <Header as='h1'>Dashboard</Header>
      <Divider />
      <RenderGuard roles={Roles.ADMIN}>
        <AdminHome />
      </RenderGuard>
      <RenderGuard roles={Roles.DTSMANAGER}>
        <DtsManagerHome />
      </RenderGuard>
      <RenderGuard roles={Roles.CONCORDIUMMANAGER}>
        <ConcordiumManagerHome />
      </RenderGuard>
    </>
  )
}