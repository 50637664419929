import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react';

interface IProps {
  callback: () => void;
  timeOut?: number;
}

export const TimerButton: React.FC<IProps> = ({ callback, timeOut = 5 }) => {
  const [countDown, setCountDown] = useState(timeOut);

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => setCountDown(countDown - 1), 1000);
    }
    if (countDown === 0) {
      callback();
    }

  }, [countDown])

  return (
    <Button color="green" floated="right" type="submit">
      {countDown}
    </Button>
  )
}
