import { Divider, Header } from "semantic-ui-react";
import { MitekDocumentForm } from "../../../../Components/secure/mitek/documents/MitekDocumentForm";
import { RouteComponentProps, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface RouteParams {
  id: string;
}

export const EditMitekDocument: React.FC<RouteComponentProps<RouteParams>> = () => {
  const { id } = useParams<RouteParams>();
  const { t } = useTranslation();
  return (
    <>
      <Header as="h1">{t("Mitek.Document.Edit")}</Header>
      <Divider />
      <MitekDocumentForm id={id} />
    </>
  )
}