export enum EDocumentType {
  NotAvailable = 0,
  Passport = 1,
  NationalIdCard = 2,
  DrivingLicense = 3,
  ImmigrationCard = 4
}

export interface IDocumentEnum {
  value: EDocumentType;
  name: string;
}

const documentItem = (value: EDocumentType, name: string): IDocumentEnum => ({
  value,
  name,
});

export const DocumentEnums = (): IDocumentEnum[] => {
  let list: IDocumentEnum[] = [];
  list.push(documentItem(EDocumentType.NotAvailable, EDocumentType[EDocumentType.NotAvailable]));
  list.push(documentItem(EDocumentType.Passport, EDocumentType[EDocumentType.Passport]));
  list.push(documentItem(EDocumentType.NationalIdCard, EDocumentType[EDocumentType.NationalIdCard]));
  list.push(documentItem(EDocumentType.DrivingLicense, EDocumentType[EDocumentType.DrivingLicense]));
  list.push(documentItem(EDocumentType.ImmigrationCard, EDocumentType[EDocumentType.ImmigrationCard]));

  return list;
}