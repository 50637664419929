import { Button, Form, Grid, Segment } from 'semantic-ui-react';
import { CancelButton } from '../../Common/Button/CancelButton';
import { DtsManagerValidation } from './DtsManager.Validation';
import { DtsmanagerFormValues, IDtsManager } from '../../../models/user/IDtsManager';
import { Field, Formik } from 'formik';
import { PasswordInput } from '../../Common/form/PasswordInput';
import { RootStoreContext } from '../../../stores/RootStore';
import { Spinner } from '../../Common/spinner/Spinner';
import { TextInput } from '../../Common/TextInput';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  id?: string;
}

export const DtsManagerForm: React.FC<IProps> = observer(({ id }) => {
  const { t } = useTranslation();
  const rootStore = useContext(RootStoreContext);
  const { addDtsManager, editDtsManager, getDtsManager, loadingDtsManagers, submitting } = rootStore.dtsManagerStore;
  const [formValues, setFormValues] = useState(new DtsmanagerFormValues());
  const [lastActivity, setLastActivity] = useState<Date>();

  useEffect(() => {
    const fetchDtsManager = async (id: string) => {
      const dtsManager: IDtsManager | undefined = await getDtsManager(id);
      setFormValues(new DtsmanagerFormValues(dtsManager));
      setLastActivity(dtsManager?.lastActivity);
    }

    if (id) {
      fetchDtsManager(id);
    }
  }, [id])

  const handleSubmit = async (values: DtsmanagerFormValues) => {
    if (id) {
      // TODO: password kunnen aanpassen
      await editDtsManager(values);
    } else {
      await addDtsManager(values);
    }
  };

  if (loadingDtsManagers) return <Spinner />

  return (
    <Grid stackable>
      {id && <Grid.Row>
        <Grid.Column width={3}>
          <span><strong>Last logged in</strong></span>
        </Grid.Column>
        <Grid.Column width={5}>
          <span>{lastActivity !== undefined ? t("I18n.Formatted.DateTime", { date: new Date(lastActivity) }) : "-"}</span>
        </Grid.Column>
      </Grid.Row>}
      <Grid.Column width={8}>
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={DtsManagerValidation(id === undefined)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Segment padded className='bg-lightest-grey'>
                {/* <Field
                  component={TextInput}
                  disabled={id !== undefined}
                  label="Username"
                  name="userName"
                  required={!id}
                /> */}
                <Field
                  component={TextInput}
                  disabled={id !== undefined}
                  label={t("Label.Email")}
                  name="email"
                  required={!id}
                />
                <Field
                  component={TextInput}
                  label={t("Label.Name.First")}
                  name="firstName"
                  required
                />
                <Field
                  component={TextInput}
                  label={t("Label.Name.Last_Prefix")}
                  name="lastNamePrefix"
                />
                <Field
                  component={TextInput}
                  label={t("Label.Name.Last")}
                  name="lastName"
                  required
                />
              </Segment>

              <Segment padded className='bg-lightest-grey'>
                {id && <p style={{ color: "#ca005d" }}>{t("Password.Only_Fill_When_Change")}</p>}
                <Field
                  component={PasswordInput}
                  label={t("Password.Password")}
                  name="password.password"
                  required={!id}
                />
                <Field
                  component={PasswordInput}
                  label={t("Password.Repeat")}
                  name="password.confirmPassword"
                  required={!id}
                />
              </Segment>

              <Button disabled={submitting} floated='right' loading={submitting} primary type="submit">
                {t("Button.Save")}
              </Button>
              <CancelButton floated='left' />
            </Form>
          )}
        </Formik>
      </Grid.Column>
    </Grid>
  )
})