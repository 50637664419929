import * as Yup from 'yup';
import axios from 'axios';
import { Field, Formik } from 'formik';
import { Segment, Header, Image, Form as SemanticForm, Button } from 'semantic-ui-react';
import { StateValue } from '../../../Context';
import { StyledProgressBar } from '../../../StyledComponents/StyledProgressbar';
import { TextInput } from '../../../Common/TextInput';
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';

type IProps = {
  callback: () => void;
}

const MitekCheckAgentAssist = (props: IProps) => {
  const { t } = useTranslation();
  const state = useContext(StateValue);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (data: any) => {
    setIsSubmitting(true);
    const res = await axios.post(
      '/api/mitekCheck/startAgentAssist',
      {
        email: data.email,
        ctxId: state.ctxId,
      }
    );
    console.log(res);
    if (res.status !== 200) {
      setIsError(true);
    } else {
      setIsSubmitting(false);
      props.callback();
    }
    setIsSubmitting(false);
  };

  const validationSchema = Yup.object().shape({
    personalGovID: Yup.string()
      .email("Mitek.Agent_Assist.Email_Invalid")
      .label('Mitek.Agent_Assist.Email')
      .required(`${t('Mitek.Agent_Assist.Email')} ${t('General_UI.Is_Required')}`),
  });

  return (
    <div className={`mobile-content-container`}>
      <Segment className={`mobile-content-header-container`}>
        <StyledProgressBar percent={(state.current / (state.flow.length)) * 100} attached="top" color={state.org.theme.themeColor} className="" />
        <Header as="h3" className="mt-0">
          <Image src={state.org.theme.logo} style={{ width: "100px" }} /> {t("Mitek.Title")}
        </Header>
      </Segment>
      <div style={{ flex: 0.93 }} className={`mobile-service-item-container`}>
        <h1 className={`service-item-header${state.org.theme.clientClass ? '-' + state.org.theme.clientClass : ''} mb-1`}>
          {t('Mitek.Agent_Assist.Header')}
        </h1>
        <h3 className={`service-item-subheader${state.org.theme.clientClass ? '-' + state.org.theme.clientClass : ''} mt-2`}>
          {t('Mitek.Agent_Assist.SubHeader')}
        </h3>
        <h3 className={`service-item-explanation mt-2 mb-5`}>
          {t('Mitek.Agent_Assist.Explanation')}
        </h3>

        <Formik
          initialValues={{
            email: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          style={{ overFlowY: 'auto' }}
        >
          {(formProps) => (
            <SemanticForm
              onSubmit={formProps.handleSubmit}
              style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: "space-between" }}
            >
              <span style={{ flex: 0.8 }}>
                <Field
                  component={TextInput}
                  id="email"
                  label={t('Mitek.Agent_Assist.Email')}
                  name="email"
                  type="text"
                  placeholder={t('Mitek.Agent_Assist.Email')}
                  required
                />

              </span>
              <Segment basic className={`service-item-button-container${state.org.theme.clientClass ? "-" + state.org.theme.clientClass : ""} mt-0`}>
                <Button
                  floated="right"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  primary
                  color={isError ? 'red' : 'green'}
                  type="submit"
                >
                  {isError
                    ? t('General_UI.Try_Again')
                    : t('General_UI.Submit')}
                </Button>
              </Segment>

            </SemanticForm>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default MitekCheckAgentAssist
