import MitekHandoff from "../Components/Services/Mitek/Components/MitekHandoff";
import { Fragment } from "react"
import { MainComponent } from "../Components/MainComponent";
import { Route, Switch } from "react-router-dom"
import { withOrientationChange } from "react-device-detect";

const PublicRoutes = withOrientationChange((props: any) => {
  const { isLandscape, isPortrait } = props;
  return (
    <Fragment>
      <Switch>
        <Route exact path="/:ctxId/:lang" render={
          (props) => <MainComponent match={props.match} history={props.history} isLandscape={isLandscape} isPortait={isPortrait} />
        } />
        <Route exact path="/:ctxId/:lang/:handoff" render={
          (props) => <MainComponent match={props.match} history={props.history} isLandscape={isLandscape} isPortait={isPortrait} />} />
        <Route exact path="/handoff" component={MitekHandoff} />
        <Route exact path="/" component={MainComponent} />
      </Switch>
    </Fragment>
  );
})

export default PublicRoutes;