import AdminStore from "./AdminStore";
import ApplicationStore from "./ApplicationStore";
import AuthenticationStore from "./AuthenticationStore";
import AuthorizationStore from "./AuthorizationStore";
import ConcordiumManagerStore from "./ConcordiumManagerStore";
import ConfirmModalStore from "./ConfirmModalStore";
import DtsManagerStore from "./DtsManagerStore";
import ErrorStatisticsStore from "./ErrorStatisticsStore";
import ModalStore from "./ModalStore";
import NotificationStore from "./NotificationStore";
import ProfileStore from "./ProfileStore";
import StatisticsStore from "./StatisticsStore";
import { BaseApiService } from "../Services/BaseApiService";
import { createContext } from "react";
import CriiptoStore from "./CriiptoStore";

export class RootStore {
  adminStore: AdminStore;
  applicationStore: ApplicationStore;
  authenticationStore: AuthenticationStore;
  authorizationStore: AuthorizationStore;
  baseApiService: BaseApiService;
  concordiumManagerStore: ConcordiumManagerStore;
  confirmModalStore: ConfirmModalStore;
  criiptoStore: CriiptoStore;
  dtsManagerStore: DtsManagerStore;
  errorStatisticsStore: ErrorStatisticsStore;
  modalStore: ModalStore;
  notificationStore: NotificationStore;
  profileStore: ProfileStore;
  statisticsStore: StatisticsStore;

  constructor() {
    this.adminStore = new AdminStore(this);
    this.applicationStore = new ApplicationStore(this);
    this.authenticationStore = new AuthenticationStore(this);
    this.authorizationStore = new AuthorizationStore(this);
    this.baseApiService = new BaseApiService(this);
    this.concordiumManagerStore = new ConcordiumManagerStore(this);
    this.confirmModalStore = new ConfirmModalStore(this);
    this.criiptoStore = new CriiptoStore(this);
    this.dtsManagerStore = new DtsManagerStore(this);
    this.errorStatisticsStore = new ErrorStatisticsStore(this);
    this.modalStore = new ModalStore(this);
    this.notificationStore = new NotificationStore(this);
    this.profileStore = new ProfileStore(this);
    this.statisticsStore = new StatisticsStore(this);
  }
}

export const RootStoreContext = createContext(new RootStore());