import * as Yup from "yup";

export const AnonimityRevocationValidation = () => Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  documentNumber: Yup.string(),
  accountAddress: Yup.string(),
  idCredPub: Yup.string(),
  atLeastOne: Yup.bool()
    .test("at-leat-one", "At_Least_One", // Function for validating at least one field is filled
      function () {
        const firstName = this.parent.firstName;
        const lastName = this.parent.lastName;
        const documentNumber = this.parent.documentNumber;
        const accountAddress = this.parent.accountAddress;
        const idCredPub = this.parent.idCredPub;
        if ((!firstName && !lastName && !documentNumber && !accountAddress && !idCredPub) || (!!firstName && !!lastName && !!documentNumber && !!accountAddress && !!idCredPub)) {
          this.createError(); // Create the error
          return false; // Return false in order to tell Yup that there is an error
        }
        return true;
      })
})
