import "./Guard.css"
import { Button, Dimmer, Header, Icon } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/RootStore";
import { ServiceEvents } from "../../../Services/ServiceEvents";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"

export const SessionGuard = () => {
  const { t } = useTranslation();
  const rootStore = useContext(RootStoreContext);
  const [showWarning, setShowWarning] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [logoutCausedByExpire, setLogoutCausedByExpire] = useState(false);

  useEffect(() => {
    window.addEventListener(ServiceEvents.sessionLogout, sessionLogout)
    window.addEventListener(ServiceEvents.sessionStarted, sessionStarted);
    window.addEventListener(ServiceEvents.sessionWarning, sessionWarning);
  }, []);

  const extendSession = async () => {
    await rootStore.applicationStore.refreshSessionTimeout(); //<- If this succeeds, it will result in a 'sessionStarted' event that will then clear the dialog.
    console.log("Extended session");
  }

  const sessionStarted = () => {
    setShowWarning(false);
    setShowLogout(false);
  }

  const sessionWarning = () => {
    setShowWarning(true);
    setShowLogout(false);
  }

  const sessionLogout = (detail: any) => {
    setShowWarning(false);
    setShowLogout(true);
    setLogoutCausedByExpire(detail.expires > 0);
  }

  const getHeader = () => {
    if (showWarning) return t("Session.Header.Expiring");
    return t("Session.Header.Expired");
  }

  const getMessage = () => {
    if (showWarning) return t("Session.Message.Timeout_Warning");
    if (logoutCausedByExpire) return t("Session.Message.Timeout_Expired");
    return t("Session.Message.Logged_Out");
  }

  return (showLogout || (showWarning && rootStore.authorizationStore.isAuthenticated())) ?
    <Dimmer active={true} page>
      <div className="session-cnt">
        <Icon size="huge" name="clock outline" />
        <Header as="h1">{getHeader()}</Header>
        <p>{getMessage()}</p>
        <div>
          {showWarning ?
            <div>
              <Button onClick={() => rootStore.authenticationStore.signOut()} content="Logout" />
              <Button primary onClick={async () => await extendSession()} content="Extend" />
            </div>
            : <div><Button onClick={() => rootStore.authenticationStore.signOut()} content="Back to login" /></div>}
        </div>
      </div>
    </Dimmer>
    : <></>

}