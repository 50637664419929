import { Field, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { CriiptoAcrFormValues, ICriiptoAcr } from "../../../models/admin/ICriiptoAcr";
import { RootStoreContext } from "../../../stores/RootStore";
import { CancelButton } from "../../Common/Button/CancelButton";
import { CheckBox } from "../../Common/form/CheckBox";
import { DropDown } from "../../Common/form/DropDown";
import { Spinner } from "../../Common/spinner/Spinner";
import { TextInput } from "../../Common/TextInput";
import { CriiptoAcrFormValidation } from "./CriiptoAcrForm.Validation";
import { CriiptoTypeEnums, ICriiptoTypeEnum } from "../../../Enums/ECriiptoType";
const { getData } = require('country-list');

interface IProps {
  id?: string;
}

export const CriiptoAcrForm: React.FC<IProps> = observer(({ id }) => {
  const { t } = useTranslation();
  const rootStore = useContext(RootStoreContext);
  const { submitting, loading, getCriiptoAcrValue, addCriiptoAcrValue, editCriiptoAcrValue } = rootStore.criiptoStore;
  const [formValues, setFormValues] = useState(new CriiptoAcrFormValues());
  const [criiptoTypes, setCriiptoTypes] = useState<ICriiptoTypeEnum[]>([]);

  const countryData = useMemo(() => getData(), []);
  const countryOptions = useMemo(() => countryData.sort((country: { code: any }) => country.code).map(
    (country: { code: any; name: any; }) => ({
      key: country.code,
      value: country.code,
      text: country.name,
      flag: country.code.toLowerCase()
    })
  ), [countryData]);

  useEffect(() => {
    const fetchCriiptoAcr = async (id: string) => {
      const criiptoAcr: ICriiptoAcr | undefined = await getCriiptoAcrValue(id);
      setFormValues(new CriiptoAcrFormValues(criiptoAcr));
    }
    setCriiptoTypes(CriiptoTypeEnums());
    if (id) {
      fetchCriiptoAcr(id);
    }
  }, [])

  const handleSubmit = async (values: CriiptoAcrFormValues) => {
    if (id) {
      await editCriiptoAcrValue(id, values);
    } else {
      await addCriiptoAcrValue(values);
    }
  }

  if (loading) return <Spinner />

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={8}>
          <Formik
            enableReinitialize
            initialValues={formValues}
            onSubmit={handleSubmit}
            validationSchema={CriiptoAcrFormValidation}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Segment padded className="bg-lightest-grey">
                  <Field
                    component={CheckBox}
                    name="enabled"
                    id="enabled"
                    label={t("Criipto.Acr.Enabled")}
                    type="checkbox"
                    checked={formik.values.enabled}
                  />
                  <Field
                    component={DropDown}
                    required={!id}
                    name="countryAlpha2"
                    label={t("Criipto.Acr.Country")}
                    value={formik.values.countryAlpha2}
                    disabled={id !== undefined}
                    placeholder={t("Enum.Make_A_Choice")}
                    options={countryOptions}
                  />
                  <Field
                    component={TextInput}
                    required
                    name="eidName"
                    label={t("Criipto.Acr.Name")}
                  />
                  <Field
                    component={TextInput}
                    required
                    name="acrValue"
                    label={t("Criipto.Acr.Acr_Value")}
                  />
                  <Field
                    component={TextInput}
                    name="comment"
                    label={t("Criipto.Acr.Comment")}
                  />
                  <Field
                    component={DropDown}
                    required
                    name="criiptoType"
                    label={t("Criipto.Acr.Criipto_Type")}
                    value={formik.values.criiptoType}
                    placeholder={t("Enum.Make_A_Choice")}
                    options={criiptoTypes.map((x) => {
                      return {
                        key: x.value,
                        value: x.value,
                        text: t(`Enum.CriiptoType.${x.name}`)
                      }
                    })}
                  />
                  <Field
                    component={CheckBox}
                    name="forced"
                    id="forced"
                    label={t("Criipto.Acr.Forced")}
                    type="checkbox"
                    checked={formik.values.forced}
                  />
                </Segment>
                <Button disabled={submitting} floated='right' loading={submitting} primary type="submit">
                  {t("Button.Save")}
                </Button>
                <CancelButton floated="left" />
              </Form>
            )}
          </Formik>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
})