const startsWithProtocolRegex = /^[^/:]*:/;
// Matches any string that starts with a protocol, e.g. http: https: ftp: mailto:
// There are many many more, all should be matched by the pattern above. For a list see https://en.wikipedia.org/wiki/List_of_URI_schemes

export const sanitizeUrl = function (input: string | null) {
  let value = input;

  while (value) {
    const lengthBefore = value.length;
    value = decodeURIComponent(value);
    if (lengthBefore === value.length) break;
  }

  while (value && startsWithProtocolRegex.test(value)) {
    value = value.startsWith(window.location.origin)
      ? value.substring(window.location.origin.length)
      : "";
  }

  if (window.location.hostname === "localhost")
    console.log("sanitizeUrl: '" + input + "' -> '" + value + "'");

  return value;
}