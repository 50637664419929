import { Divider, Header, Tab } from "semantic-ui-react"
import { ErrorStatisticsBreakdown } from "../../../Components/secure/errorStatistics/ErrorStatisticsBreakdown"
import { GeneralErrorStatistics } from "../../../Components/secure/errorStatistics/GeneralErrorStatistics"
import { observer } from "mobx-react-lite"

export const ErrorStatisticsPage = observer(() => {

  const panes = [
    { menuItem: 'General', render: () => <GeneralErrorStatistics /> },
    { menuItem: 'Breakdown', render: () => <ErrorStatisticsBreakdown /> },
  ]
  return (
    <>
      <Header as="h1">Error statistics</Header>
      <Divider />
      <Tab menu={{ pointing: true }} panes={panes} />
    </>
  )
})