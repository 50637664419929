import MitekHeader from './MitekHeader';
import { EDocumentType } from '../../../../Enums/EDocumentType';
import { Segment, Grid, Card, Icon } from 'semantic-ui-react';
import { supportedDocumentsDto } from '../../../../Services/Backend';
import { useTranslation } from 'react-i18next';

type IProps = {
  documents: supportedDocumentsDto[];
  setType: (type: "PASSPORT" | "ID" | "DRIVERSLICENSE" | undefined) => void;
  setCurrentPage: (page: number) => void;
}

const MitekDocumentSelection = (props: IProps) => {

  const { t } = useTranslation();

  const renderChoices = (type: EDocumentType) => {
    switch (type) {
      case EDocumentType.Passport:
        return (
          <Grid.Column textAlign="center" stretched key={type} style={{ padding: "1rem 1rem" }}>
            <Card fluid onClick={() => { props.setType("PASSPORT"); props.setCurrentPage(4); }}>
              <Icon fitted size="huge" name="id card outline" color="black" />
              <Card.Content>
                <Card.Header>{t("Mitek.Passport")}</Card.Header>
                <Card.Meta>{t("Mitek.Front")}</Card.Meta>
              </Card.Content>
            </Card>
          </Grid.Column>
        );

      case EDocumentType.NationalIdCard:
        return (
          <Grid.Column textAlign="center" stretched key={type} style={{ padding: "1rem 1rem" }}>
            <Card fluid onClick={() => { props.setType("ID"); props.setCurrentPage(4); }} >
              <Icon fitted size="huge" name="vcard" color="black" />
              <Card.Content>
                <Card.Header>{t("Mitek.Identity_Document")}</Card.Header>
                <Card.Meta>{t("Mitek.Front")} {t("General_UI.and")} {t("Mitek.Back")}</Card.Meta>
              </Card.Content>
            </Card>
          </Grid.Column>
        );

      case EDocumentType.DrivingLicense:
        return (
          <Grid.Column textAlign="center" stretched key={type} style={{ padding: "1rem 1rem" }}>
            <Card fluid onClick={() => { props.setType("DRIVERSLICENSE"); props.setCurrentPage(4); }} >
              <Icon fitted size="huge" name="drivers license" color="black" />
              <Card.Content>
                <Card.Header>{t("Mitek.Driving_License")}</Card.Header>
                <Card.Meta>{t("Mitek.Front")} {t("General_UI.and")} {t("Mitek.Back")}</Card.Meta>
              </Card.Content>
            </Card>
          </Grid.Column>
        );

      default:
        break;
    }
  };

  return (
    <>
      <MitekHeader />
      <Segment className="mitek" style={{ height: "90%", width: "100%" }} basic>
        <Grid columns={1} stackable verticalAlign="middle" stretched style={{ width: "100%" }}>
          <Grid.Row verticalAlign="middle" divided textAlign="center">
            {props.documents.map((val) => renderChoices(val.documentType))}
          </Grid.Row>
        </Grid>
      </Segment>

      <Segment className="mitek" attached="bottom" style={{ height: "10%", width: "100%" }} textAlign="center">
        <h3>{t("Mitek.Choose_Document_Type")}</h3>
      </Segment>
    </>
  )
};

export default MitekDocumentSelection;
