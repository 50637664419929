import { Divider, Header } from "semantic-ui-react";
import { MitekDocumentForm } from "../../../../Components/secure/mitek/documents/MitekDocumentForm";
import { useTranslation } from "react-i18next"

export const CreateMitekDocument = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header as="h1">{t("Mitek.Document.New")}</Header>
      <Divider />
      <MitekDocumentForm />
    </>
  )
}