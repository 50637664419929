export enum Roles {
  ADMIN = 'admin',
  DTSMANAGER = "dtsmanager",
  CONCORDIUMMANAGER = "concordiummanager",
}

export const RolesAdmins = [
  Roles.ADMIN,
  Roles.DTSMANAGER,
  Roles.CONCORDIUMMANAGER
]

export default Roles;