import { Grid, Header, Table } from "semantic-ui-react";
import { IGeneralStatistics } from "../../../models/statistics/IGeneralStatistics";
import { IUserScanStatistics } from "../../../models/statistics/IUserScanStatistics";
import { RootStoreContext } from "../../../stores/RootStore";
import { Spinner } from "../../Common/spinner/Spinner";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"

export const GeneralStatistics = observer(() => {
  const { t } = useTranslation();
  const rootStore = useContext(RootStoreContext);
  const { GetGeneralStatistics, loading, GetScanTimeStatistics, loadingScanTimeStatistics } = rootStore.statisticsStore;
  const [stats, setStats] = useState<IGeneralStatistics>();
  const [scanStats, setScanStats] = useState<IUserScanStatistics>();

  useEffect(() => {
    const fetchGeneralStatistics = async () => {
      setStats(await GetGeneralStatistics());
    }
    const fetchScanStatistics = async () => {
      setScanStats(await GetScanTimeStatistics());
    }
    fetchGeneralStatistics();
    fetchScanStatistics();
  }, []);

  return (
    <>
      <Grid>
        <Grid.Row>
          {loading ? <Spinner /> : (
            <Grid.Column width={8}>
              <Header as="h3">{t("Statistics.Cumulative")}</Header>
              <Table collapsing celled padded>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{t("Table.Description")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("Table.Amount")}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>{t("Statistics.Total_Calls")}</Table.Cell>
                    <Table.Cell textAlign="center">{stats?.totalTransactions}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>{t("Statistics.Total_Country_Calls")}</Table.Cell>
                    <Table.Cell textAlign="center">{stats?.totalCountryCalls}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>{t("Statistics.Total_Mitek_Calls_Unique")}</Table.Cell>
                    <Table.Cell textAlign="center">{stats?.totalUniqueMitekCalls}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>{t("Statistics.Total_Mitek_Calls")}</Table.Cell>
                    <Table.Cell textAlign="center">{stats?.totalMitekCalls}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>{t("Statistics.Total_Mitek_Agent_Calls")}</Table.Cell>
                    <Table.Cell textAlign="center">{stats?.totalAgentCalls}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>{t("Statistics.Total_Created_Accounts")}</Table.Cell>
                    <Table.Cell textAlign="center">{stats?.totalCreatedAccounts}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          )}
          {loadingScanTimeStatistics ? <Spinner /> : (
            <Grid.Column width={8}>
              <Header as="h3">Average times it takes users to scan documents, over the past month</Header>
              <Table collapsing celled padded>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{t("Table.Description")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("Table.Seconds")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("Table.Minutes")}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Average total time</Table.Cell>
                    <Table.Cell textAlign="center">{scanStats?.averageTime}</Table.Cell>
                    <Table.Cell textAlign="center">{((scanStats?.averageTime ?? 0) / 60).toFixed(2)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Average time for first picture</Table.Cell>
                    <Table.Cell textAlign="center">{scanStats?.averageFirstPictureTime}</Table.Cell>
                    <Table.Cell textAlign="center">{((scanStats?.averageFirstPictureTime ?? 0) / 60).toFixed(2)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Average time for second picture</Table.Cell>
                    <Table.Cell textAlign="center">{scanStats?.averageSecondPictureTime}</Table.Cell>
                    <Table.Cell textAlign="center">{((scanStats?.averageSecondPictureTime ?? 0) / 60).toFixed(2)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Average time for selfie</Table.Cell>
                    <Table.Cell textAlign="center">{scanStats?.averageSelfieTime}</Table.Cell>
                    <Table.Cell textAlign="center">{((scanStats?.averageSelfieTime ?? 0) / 60).toFixed(2)}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Row />
      </Grid>
    </>
  )
})