import { useTranslation } from "react-i18next";
import { Button, Segment } from "semantic-ui-react";

interface IProps {
  onSuccess: () => void;
}

export const CriiptoSuccessPage = ({ onSuccess }: IProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Segment className="service-item-header mt-0" style={{ height: "90%", width: "100%", margin: 0 }} textAlign="center" basic>
        <h2>
          {t("Criipto.Success_Page.Success")}
        </h2>
      </Segment>
      <Segment className="service-item-button-container" style={{ height: "10%", width: "calc(100% - 2em)" }} textAlign="center" basic>
        <Button color="green" floated="right" onClick={onSuccess}>
          {t("General_UI.Next")}
        </Button>
      </Segment>
    </>
  )
}