import { useTranslation } from "react-i18next";
import { Button, Segment } from "semantic-ui-react";
import { ECriiptoPage } from "../../../../../Enums/ECriiptoPage";

interface IProps {
  setCurrentPage: (page: ECriiptoPage) => void;
}

export const CriiptoErrorPage = (props: IProps) => {
  const { setCurrentPage } = props;
  const { t } = useTranslation();
  return (
    <>
      <Segment className="service-item-header mt-0" style={{ height: "90%", width: "100%", margin: 0 }} textAlign="center" basic>
        <h2>
          {t("Criipto.Error_Page.Explanation")}
        </h2>
        <h2>
          {t("Criipto.Error_Page.Explanation2")}
        </h2>
      </Segment>
      <Segment className="service-item-button-container" style={{ height: "10%", width: "calc(100% - 2em)" }} textAlign="center" basic>
        <Button color="green" floated="right" onClick={() => setCurrentPage(ECriiptoPage.TypeSelection)}>
          {t("Criipto.Error_Page.Retry")}
        </Button>
      </Segment>
    </>
  )
}