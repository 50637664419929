import { AdminFormValues, IAdmin } from "../models/user/IAdmin";
import { EToastType } from "../Enums/EToastType";
import { GetAnonimityRevocationFormValues, IAnonimityRevocation } from "../models/admin/IAnonimityRevocation";
import { IMitekSupportedDocument, MitekSupportedDocumentFormValues } from "../models/admin/IMitekSupportedDocument";
import { RootStore } from "./RootStore";
import { history } from "../ApplicationRouter";
import { makeAutoObservable, runInAction } from "mobx"

export default class AdminStore {
  rootStore: RootStore;
  loadingAdmins: boolean = false;
  loadingDocuments: boolean = false;
  loadingRevocations: boolean = false;
  submitting: boolean = false;
  admins: IAdmin[] = [];
  anonimityRevocations: IAnonimityRevocation[] = [];
  mitekSupportedDocuments: IMitekSupportedDocument[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this)
  }

  GetAnonimityRevocation = async (values: GetAnonimityRevocationFormValues) => {
    runInAction(() => {
      this.loadingRevocations = true;
      this.anonimityRevocations = [];
    });

    const { success, data }: { success: boolean; data: IAnonimityRevocation[]; } = await this.rootStore.baseApiService.get(`admin/anonimity-revocation?firstname=${values.firstName}&lastName=${values.lastName}&idCredPub=${values.idCredPub}&documentNumber=${values.documentNumber}&accountAddress=${values.accountAddress}`);
    if (success) {
      runInAction(() => {
        this.anonimityRevocations = data;
        this.loadingRevocations = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loadingRevocations = false;
      });
      return [];
    }
  }

  getMitekSupportedDocuments = async () => {
    runInAction(() => {
      this.loadingDocuments = true;
    });

    const { success, data }: { success: boolean; data: IMitekSupportedDocument[] } = await this.rootStore.baseApiService.get('admin/mitek-documents');
    if (success) {
      runInAction(() => {
        this.mitekSupportedDocuments = data;
        this.loadingDocuments = false;
      });
    } else {
      runInAction(() => {
        this.loadingDocuments = false;
      });
    }
  }

  getMitekSupportedDocument = async (id: string) => {
    runInAction(() => {
      this.loadingDocuments = true;
    });

    const { success, data }: { success: boolean; data: IMitekSupportedDocument } = await this.rootStore.baseApiService.get(`admin/mitek-document?id=${id}`);
    if (success) {
      runInAction(() => {
        this.loadingDocuments = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loadingDocuments = false;
      });
    }
  }

  addMitekSupportedDocument = async (values: MitekSupportedDocumentFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });

    const { success } = await this.rootStore.baseApiService.post('admin/mitek-document', values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        history.push('/dashboard/mitek-supported-documents');
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
      });
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      });
    }
  }

  editMitekSupportedDocument = async (id: string, values: MitekSupportedDocumentFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });

    const { success } = await this.rootStore.baseApiService.put(`admin/mitek-document?id=${id}`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        history.push('/dashboard/mitek-supported-documents');
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
      });
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      });
    }
  }

  deleteMitekSupportedDocument = async (id: number) => {
    this.submitting = true;

    const success = await this.rootStore.baseApiService.delete(`admin/mitek-document?id=${id}`);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.confirmModalStore.closeConfirmModal();
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Delete_Success");
        this.getMitekSupportedDocuments();
      });
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      });
    }
  }

  getAdmins = async () => {
    runInAction(() => {
      this.loadingAdmins = true;
    });

    const { success, data }: { success: boolean, data: IAdmin[] } = await this.rootStore.baseApiService.get('admin');
    if (success) {
      runInAction(() => {
        this.admins = data;
        this.loadingAdmins = false;
      });
    } else {
      runInAction(() => {
        this.loadingAdmins = false;
      });
    }
  }

  getAdmin = async (id: string) => {
    runInAction(() => {
      this.loadingAdmins = true;
    });

    const { success, data }: { success: boolean, data: IAdmin } = await this.rootStore.baseApiService.get(`admin/${id}`);
    if (success) {
      runInAction(() => {
        this.loadingAdmins = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loadingAdmins = false;
      });
    }
  }

  addAdmin = async (values: AdminFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });

    const { success } = await this.rootStore.baseApiService.post('admin', values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        history.push('/dashboard/admins');
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
        this.getAdmins();
      });
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      });
    }
  }

  editAdmin = async (values: AdminFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });

    const success = await this.rootStore.baseApiService.put(`admin/${values.id}`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        history.push('/dashboard/admins');
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
        this.getAdmins();
      });
    }
    else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      });
    }
  }

  deleteAdmin = async (id: string) => {
    this.submitting = true;

    const success = await this.rootStore.baseApiService.delete(`admin/${id}`);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.confirmModalStore.closeConfirmModal();
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Delete_Success");
        this.getAdmins();
      });
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      });
    }
  }
}