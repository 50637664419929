import { observer } from "mobx-react-lite";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { ECriiptoPage } from "../../../../../Enums/ECriiptoPage";
import { Spinner } from "../../../../Common/spinner/Spinner";
import { MitekCheckComponent, MitekCheckProps } from "../../../Mitek/MitekCheckComponent";
import { CriiptoErrorPage } from "./CriiptoErrorPage";
import { CriiptoStartPage } from "./CriiptoStartPage";
import { CriiptoSuccessPage } from "./CriiptoSuccessPage";
import { MitekCriiptoSelectionPage } from "./MitekCriiptoSelectionPage";
import { CriiptoCountryTypeGetDto } from "../../../../../Types";
import { ECriiptoType } from "../../../../../Enums/ECriiptoType";

interface IProps {
  currentPage: ECriiptoPage;
  setCurrentPage: (page: ECriiptoPage) => void;
  onSuccess: () => void;
  mitekProps: MitekCheckProps;
  initCriiptoBackend: () => void;
  criiptoTypes: CriiptoCountryTypeGetDto[];
  chosenCriiptoType: ECriiptoType;
  setChosenCriiptoType: (type: ECriiptoType) => void;
}

export const CriiptoCheckDesktop = observer(({ currentPage, mitekProps, setCurrentPage, onSuccess, initCriiptoBackend, criiptoTypes, chosenCriiptoType, setChosenCriiptoType }: IProps) => {

  const renderPage = () => {
    switch (currentPage as number) {
      case ECriiptoPage.TypeSelection:
        return <MitekCriiptoSelectionPage setCurrentPage={setCurrentPage} criiptoTypes={criiptoTypes} setChosenCriiptoType={setChosenCriiptoType} />
      case ECriiptoPage.Start:
        return <CriiptoStartPage initCriiptoBackend={initCriiptoBackend} chosenCriiptoType={chosenCriiptoType} />
      case ECriiptoPage.Loading:
        return <Spinner />
      case ECriiptoPage.Completed: // Placeholder since it is skipped by the mainComponent
        return <CriiptoSuccessPage onSuccess={onSuccess} />
      case ECriiptoPage.Error:
        return <CriiptoErrorPage setCurrentPage={setCurrentPage} />
      case ECriiptoPage.Mitek:
        return <MitekCheckComponent isLandscape={mitekProps.isLandscape} isPortrait={mitekProps.isPortrait} props={mitekProps.props} />
      default:
        return <CriiptoErrorPage setCurrentPage={setCurrentPage} />
    }
  }

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${currentPage}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false)
          }}
          classNames="fade">
          <div className="transition-container">
            {renderPage()}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </>
  )
})