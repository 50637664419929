import { AdminForm } from "../../../../../Components/secure/admin/AdminForm";
import { Divider, Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export const CreateAdmin = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header as="h1">{t("Admin.New")}</Header>
      <Divider />
      <AdminForm />
    </>
  )
}