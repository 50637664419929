import { Roles } from "../Enums/Roles";
import { RootStore } from "./RootStore";
import { ServiceEvents } from "../Services/ServiceEvents";
import { base64UrlDecode } from "../utils/Base64UrlDecode";
import { isRoleMatch } from "../utils/Roles";
import { sendCustomEvent } from "../utils/Events";

enum UpdateReason {
  "apiCall",
  "logOut",
  "storageEvent"
}

export const authTokenKey = "ttscc-authtoken";
const timeDiffKey = "ttscc-timediff";

export default class AuthorizationStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.storageEventHandlerForAuthTokenKey = this.storageEventHandlerForAuthTokenKey.bind(this);
    this.ensureStorageTokenEventHandler = this.ensureStorageTokenEventHandler.bind(this);
  }

  ensureStorageTokenEventHandler() {
    if ((window as any).hasStorageEventHandlerForAuthTokenKey)
      return;

    // We could do (and have tried) this, and it works for most cases:
    //   window.addEventListener('storage', this.storageEventHandlerForAuthTokenKey);
    // ...except that no Event occurs for storage changes done by the tab/window itself <- which is official and by design.
    // This ALSO applies if the user does "Dev Tools -> Clear Data": the very tab/window from which it happens does NOT receive an
    // Event, only OTHER tabs/windows get informed.
    // We use the following workaround: we create a dummy iframe on the page. An iframe has its own separate window object, which
    // will receive Events for storage changes done by its parent window. And then we link the iframe window to our Event Handler.
    const iframe = document.createElement("iframe");
    iframe.className = "storage-iframe";
    document.body.appendChild(iframe);
    iframe.contentWindow!.addEventListener('storage', this.storageEventHandlerForAuthTokenKey);
    (window as any).hasStorageEventHandlerForAuthTokenKey = true;
  }

  storageEventHandlerForAuthTokenKey(event: any) {
    if ( /* authtoken was modified */ (event.key === authTokenKey)
      || /* storage was cleared    */ (event.key === null && !event.storageArea[authTokenKey])
    ) {
      // Wait a little before resetting the timers, and possibly showing a popup as a result. The storage event may have happened
      // because of a Logout, and that is usually followed by a Redirect to somewhere else. If that is the case && we don't wait,
      // then the LogOut popup will flash by, which is unneeded and messy.
      setTimeout(() => this.resetSessionTimers(UpdateReason.storageEvent), 50);
    }
  }

  get timeDiff() {
    const value = sessionStorage.getItem(timeDiffKey);
    return value ? parseInt(value) : 0;
  }

  set timeDiff(value: number) {
    sessionStorage.setItem(timeDiffKey, String(value));
  }

  get token() {
    this.ensureStorageTokenEventHandler();
    return sessionStorage.getItem(authTokenKey) || "";
  }

  set token(token) {
    const payload = this.tokenPayload(token);
    if (payload.iat)
      this.timeDiff = Math.ceil(Date.now() / 1000) - payload.iat; // Difference in seconds between client time (now) vs server time (when it created the token). Probably ~seconds for visitor in same timezone, +hours for timezones to the East, and -hours for timezones to the West.

    this.ensureStorageTokenEventHandler();
    sessionStorage.setItem(authTokenKey, token);
  }

  resetSessionTimers(reason: UpdateReason) {
    // To see if this function gets triggered + if so, what triggered it:
    // console.log("resetSessionTimers(" + UpdateReason[reason] + ")");
    clearTimeout((window as any).warningTimeout);
    clearTimeout((window as any).logoutTimeout);

    const { iat: issuedAt, exp: expires } = this.tokenPayload(this.token);  // Get token values (and store into readable names).

    if (expires > 0) {
      const timeoutSeconds = expires - issuedAt - 1; // Values are in seconds. The "- 1" is there because the token has aged a little bit already when we process it.
      const warningSeconds = timeoutSeconds >= 180 ? 120 : timeoutSeconds / 2; // If token is valid for >= 180 sec then warn 120 sec before token expires (normal behaviour). For debugging we support shorter times, e.g. 20 sec validity -> 10 sec warning timeout.
      (window as any).warningTimeout = setTimeout(() => sendCustomEvent(ServiceEvents.sessionWarning), (timeoutSeconds - warningSeconds) * 1000); // "Warning" dialog timer. If you change the 120 value, you also need to change the "2 minutes" in the warning text.
      (window as any).logoutTimeout = setTimeout(() => sendCustomEvent(ServiceEvents.sessionLogout, { expires }), timeoutSeconds * 1000); // "Expired" dialog timer.
      sendCustomEvent(ServiceEvents.sessionStarted);
    }
    else if (expires === 0 && reason === UpdateReason.storageEvent) {
      sendCustomEvent(ServiceEvents.sessionLogout, { expires });
    }
  }

  tokenParts(token: string) {
    return (token || this.token).split(".");
  }

  tokenPayload(token: string) {
    const parts = this.tokenParts(token);
    return parts.length === 3
      ? JSON.parse(base64UrlDecode(parts[1]))
      : { exp: 0 };
  }

  isDateValid(payload: any) {
    return Math.ceil(Date.now() / 1000) - this.timeDiff <= payload.exp;
  }

  isAuthenticated() {
    return this.isDateValid(this.tokenPayload(this.token));
  }

  get email() {
    const payload = this.tokenPayload(this.token);
    return this.isDateValid(payload)
      ? payload["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"]
      : "";
  }

  get roleId() {
    const payload = this.tokenPayload(this.token);
    return this.isDateValid(payload)
      ? payload["ImpersonationList"].split(",")[0]
      : "";
  }

  get role() {
    const payload = this.tokenPayload(this.token);
    return this.isDateValid(payload)
      ? payload["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"].toLowerCase()
      : "";
  }

  isInRole(role: any) {
    return role && this.role === role.toLowerCase();
  }

  isInRoles(roles: Roles[]) {
    return roles && roles.some((r: Roles) => isRoleMatch(this.role, r.toLowerCase()));
  }

  hasRole() {
    return this.role.length > 0;
  }

  roleNamesFrom(payload: any, startIndex: number) {
    const roleNames = payload["ImpersonationRoleNames"].toLowerCase().split(",");
    return startIndex > 0
      ? roleNames.slice(startIndex)
      : roleNames;
  }

  removeAuthToken() {
    sessionStorage.removeItem(authTokenKey);
  }
}