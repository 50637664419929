import "./Login.css"
import { Divider, Grid, Header, Segment } from "semantic-ui-react";
import { LoginForm } from "./LoginForm";

export const Login = () => {
  return (
    <Segment basic className="login-container">
      <Header as="h1">Digital Trust Solutions </Header>
      <Divider />
      <Segment basic>
        <Grid>
          <Grid.Row>
            <Grid.Column >
              <LoginForm />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Segment>
  )
}