import { makeAutoObservable, runInAction } from "mobx";
import { history } from "../ApplicationRouter";
import { EToastType } from "../Enums/EToastType";
import { CriiptoAcrFormValues, ICriiptoAcr } from "../models/admin/ICriiptoAcr";
import { RootStore } from "./RootStore";

export default class CriiptoStore {
  rootStore: RootStore;
  loading: boolean = false;
  submitting: boolean = false
  criiptoAcrValues: ICriiptoAcr[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getCriiptoAcrValues = async () => {
    this.loading = true;

    const { success, data }: { success: boolean; data: ICriiptoAcr[] } = await this.rootStore.baseApiService.get('criipto/acrs');
    if (success) {
      runInAction(() => {
        this.criiptoAcrValues = data;
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      })
    }
  }

  getCriiptoAcrValue = async (id: string) => {
    this.loading = true;

    const { success, data }: { success: boolean; data: ICriiptoAcr } = await this.rootStore.baseApiService.get(`criipto/acr?id=${id}`);
    if (success) {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  addCriiptoAcrValue = async (values: CriiptoAcrFormValues) => {
    this.submitting = true;

    const { success } = await this.rootStore.baseApiService.post('criipto/acr', values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        history.push('/dashboard/criipto-acr');
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
      });
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      })
    }
  }

  editCriiptoAcrValue = async (id: string, values: CriiptoAcrFormValues) => {
    this.submitting = true;

    const { success } = await this.rootStore.baseApiService.put(`criipto/acr?id=${id}`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        history.push('/dashboard/criipto-acr');
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
      });
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      })
    }
  }

  deleteCriiptoAcrValue = async (id: number) => {
    this.submitting = true;

    const success = await this.rootStore.baseApiService.delete(`criipto/acr?id=${id}`);
    if (success) {
      this.rootStore.confirmModalStore.closeConfirmModal();
      this.getCriiptoAcrValues();

      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Delete_Success");
      });
    }
    else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      });
    }
  }
}