import { EIssuanceReference } from "../../Enums/EIssuanceReference";

export interface IIsuanceReference {
  dataFound: boolean;
  ctxId: string;
  errorType: EIssuanceReference;
  sendToAgent: boolean;
  agentReasons: string[];
}


export interface IGetIssuanceReference {
  issuanceReference: string;
}

export class GetIssuanceReferenceFormValues {
  issuanceReference: string = "";

  constructor(init?: IGetIssuanceReference) {
    Object.assign(this, init);
  }
}