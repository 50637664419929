import { Button, SemanticFLOATS } from "semantic-ui-react";
import { history } from "../../../ApplicationRouter";

interface IProps {
  floated?: SemanticFLOATS;
}

export const CancelButton = (props: IProps) => {

  const handleBack = () => {
    history.goBack();
  }

  return (
    <Button type="button" floated={props.floated} secondary onClick={() => handleBack()}>
      Cancel
    </Button>
  );
}