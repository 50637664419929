export enum EMitekErrorType {
  None = 0,
  Img = 1,
  MissingData = 2,
  AgentAssist = 3,
  Expired = 4,
  Country = 5,
  ImgSelfie = 6,
  HardFailure = 7,
  Id = 8,
  AgentFaulty = 9,
  AgentInvalid = 10,
}

export interface IMitekErrorEnum {
  value: EMitekErrorType;
  name: string;
}

const mitekErrorItem = (value: EMitekErrorType, name: string): IMitekErrorEnum => ({ value, name });

export const MitekErrorEnums = (): IMitekErrorEnum[] => {
  let list: IMitekErrorEnum[] = [];
  list.push(mitekErrorItem(EMitekErrorType.None, EMitekErrorType[EMitekErrorType.None]));
  list.push(mitekErrorItem(EMitekErrorType.Img, EMitekErrorType[EMitekErrorType.Img]));
  list.push(mitekErrorItem(EMitekErrorType.MissingData, EMitekErrorType[EMitekErrorType.MissingData]));
  list.push(mitekErrorItem(EMitekErrorType.AgentAssist, EMitekErrorType[EMitekErrorType.AgentAssist]));
  list.push(mitekErrorItem(EMitekErrorType.Expired, EMitekErrorType[EMitekErrorType.Expired]));
  list.push(mitekErrorItem(EMitekErrorType.Country, EMitekErrorType[EMitekErrorType.Country]));
  list.push(mitekErrorItem(EMitekErrorType.ImgSelfie, EMitekErrorType[EMitekErrorType.ImgSelfie]));
  list.push(mitekErrorItem(EMitekErrorType.HardFailure, EMitekErrorType[EMitekErrorType.HardFailure]));
  list.push(mitekErrorItem(EMitekErrorType.Id, EMitekErrorType[EMitekErrorType.Id]));
  list.push(mitekErrorItem(EMitekErrorType.AgentFaulty, EMitekErrorType[EMitekErrorType.AgentFaulty]));
  list.push(mitekErrorItem(EMitekErrorType.AgentInvalid, EMitekErrorType[EMitekErrorType.AgentInvalid]));
  return list;
}